import { CLIENT_1, CLIENT_10, CLIENT_11, CLIENT_12, CLIENT_13, CLIENT_14, CLIENT_15, CLIENT_16, CLIENT_17, CLIENT_18, CLIENT_19, CLIENT_2, CLIENT_20, CLIENT_21, CLIENT_22, CLIENT_23, CLIENT_24, CLIENT_25, CLIENT_26, CLIENT_27, CLIENT_28, CLIENT_29, CLIENT_3, CLIENT_30, CLIENT_31, CLIENT_32, CLIENT_4, CLIENT_5, CLIENT_6, CLIENT_7, CLIENT_8, CLIENT_9, CONTENT_1, CONTENT_2, CONTENT_3, CONTENT_4, CONTENT_5, PARTNER_1, PARTNER_2, PARTNER_3, PARTNER_4, PARTNER_5, PARTNER_6 } from "../images/imageData";

export const clientList = [CLIENT_1, CLIENT_2, CLIENT_3, CLIENT_4, CLIENT_5, CLIENT_6, CLIENT_7, CLIENT_8, CLIENT_9, CLIENT_10, CLIENT_11, CLIENT_12, CLIENT_13, CLIENT_14, CLIENT_15, CLIENT_16, CLIENT_17, CLIENT_18, CLIENT_19, CLIENT_20, CLIENT_21, CLIENT_22, CLIENT_23, CLIENT_24, CLIENT_25, CLIENT_26, CLIENT_27, CLIENT_28, CLIENT_29, CLIENT_30, CLIENT_31, CLIENT_32];

export const partnerList = [
    PARTNER_1, PARTNER_2, PARTNER_3, PARTNER_4, PARTNER_5, PARTNER_6
];

export const workData = [
    {
        title: 'Who We Are?',
        subTitle: "We are dedicated to building long-term and successful bonds with clients. We understand the companies goals and the challenges they are looking to overcome. By considering our client's products and services as our own, we create the best campaigns that deliver the most of the information. Our process is efficient and our services are customer-driven."
    },
    {
        title: 'What We Do?',
        subTitle: 'Are you trying to grow your sales conversations or traffics? We try to solve your problem & make the most out of it with our expert support system to help you! Our company is a combination of digital marketing service & SEO service! We have a bunch of creative thinkers who knows how to deal with your business issues and ensure growth! After all, we believe in good STRATEGY with CREATIVITY can make GROWTH.',
    },
    {
        title: 'Our Mission',
        subTitle: 'We are working towards building a digital marketing solution eco-system. We will assist you and put your goals to action so that your business may flourish. We will give you the opportunity to showcase your products and services in front of thousands of market audiences!',
    }
];

export const contentData = [
    {
        title: 'Stay Top & Increase Sales',
        description: 'In order to increase the visitors and sales of a ebsite, it is important to stay on top. If you want, you can bring free traffic through Organic SEO, or you can increase sales through paid traffic through PPC Campaigns.',
        bg: 'bg-primary1',
        image: CONTENT_1,
    },
    {
        title: 'Engage and Entertain',
        description: 'Marketing in social media is all about connecting with audiences. More than 3 billion people worldwide use social media regularly. This is a great opportunity to grow your brand. WeTech will make your brand unique on social platform.',
        bg: 'bg-primary2',
        image: CONTENT_2,
    },
    {
        title: 'Get the best ROI possible',
        description: "There is a need for data to stay ahead of the competition in business in today's world. The more information and data you have, the more you will survive in the market. We will help you get the highest return on investment by doing research on your brand and your competitors.",
        bg: 'bg-primary1',
        image: CONTENT_3,
    },
    {
        title: 'For a better virtual experience',
        description: "The needs of the audience are changing with the times. To be in the best position here, you have to compete to win the visitors' hearts with a good quality website. Continue with WeTech Digital to get an experience of the best design and fastest website ever.",
        bg: 'bg-primary2',
        image: CONTENT_4,
    },
    {
        title: 'Differentiate the Brand from others',
        description: 'Branding plays a vital role in new or old companies. To give you the best from your competitors, easily solve unique marketing strategies and other important issues and make a different brand face with WeTech Digital.',
        bg: 'bg-primary1',
        image: CONTENT_5,
    },
]