import React from 'react'
import { Link } from 'react-router-dom';

const JobDescriptionModal = ({ isOpen, setIsOpen, data }: any) => {
    return (
        <div className={`${isOpen ? "flex" : "hidden"} justify-center items-center h-[100vh] w-[100vw] fixed cursor-default top-0 left-0 z-[100000000000] bg-black/10 backdrop-blur`}>
            <div className='h-[95%] md:h-[90%] w-[95%] md:w-[80%] lg:w-[70%] xl:w-[60%] bg-white rounded-lg'>
                <div className="flex items-center justify-between text-2xl font-bold p-4">
                    <div>{data?.title}</div>
                    <div
                        className="text-2xl font-bold text-gray-500 hover:text-red-400 p-1 rounded-md hover:border-1 hover:border-red-400 cursor-pointer"
                        onClick={() => setIsOpen(false)}
                    >X</div>
                </div>
                <div className='w-full h-[1px] bg-gray-300 mb-3' />
                <div className="h-[80%] overflow-y-scroll px-5">
                    <div className='text-xl font-semibold mb-3'>Company Introduction</div>
                    <p className="text-justify">
                        <b>WeTech Digital</b> is one of the fastest-growing digital
                        marketing and software solution agencies that handles various
                        projects for many reputed organisations. By considering our
                        clients' products and services as our own, our main focus is to
                        deliver high-quality and scalable custom applications and IT
                        services with online advertising and marketing facilities. Our
                        process is efficient, and our services are customer-driven.
                    </p>
                    <h4>Job Description</h4>
                    <p className="text-justify">
                        We are thrilled to announce multiple exciting internship
                        opportunities within our organisation. Our{" "}
                        <b>“Career at Digital Agency - Internship 2.0”</b> offers a range
                        of positions in various fields, providing invaluable hands-on
                        experience and the chance to work alongside our talented team.
                        {data?.description}
                    </p>
                    <h4 className="mb-4">What are you going to do?</h4>
                    {data?.toDo?.map((value: any, index: number) => {
                        if (typeof value === "string") {
                            return (
                                <p key={index}>
                                    <b>{index + 1}.</b> {value}
                                </p>
                            );
                        } else {
                            return (
                                <div key={index} className="mb-4">
                                    <p>
                                        <b>
                                            {index + 1}. {value?.title}
                                        </b>
                                    </p>
                                    {value?.description?.map((desc: any, index: number) => {
                                        return (
                                            <div key={index} className="ml-4 ">
                                                ● {desc}
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        }
                    })}
                    <h4 className="mb-4">The qualities we seek in you</h4>
                    {data?.qualities?.map((quality: any, index: number) => {
                        return (
                            <p key={index}>
                                <b>{index + 1}.</b>
                                {" " + quality}
                            </p>
                        );
                    })}
                    <p>
                        Note: Graduation in any relevant field is preferred for all
                        internship positions.
                    </p>
                    <div className='text-xl font-semibold my-3'>Code of Conduct</div>
                    <p className="text-justify mb-3">
                        At <b>WeTech Digital</b>, we are deeply committed to maintaining a
                        safe, respectful, and ethical work environment for all employees.
                        We have a zero-tolerance policy towards any form of unwanted
                        behavior, including misconduct, exploitation, abuse, lack of
                        integrity, and financial misconduct. We firmly believe that every
                        individual deserves to work in an environment free from
                        harassment, discrimination, or any other form of inappropriate
                        conduct.
                    </p>
                    <p>
                        <b>Job Type:</b> Full-time, In House, Intern.
                    </p>
                    <p>
                        <b>Duration: </b> Six (06) months.
                    </p>
                    <p>
                        <b>Job Location:</b> (44 F/7(Rongon Tower), West
                        Panthapath,Dhaka).
                    </p>
                    <p>
                        <b>Working Hours: </b> 09:00 am - 06:00 pm.
                    </p>
                    <p>
                        <b>Working Days: </b> Saturday – Thursday.
                    </p>
                    <div className='text-xl font-semibold my-3'>What you will have</div>
                    <p>
                        We believe, "If we take care of our people, they will take care of
                        our customers." And if we have satisfied customers, they will take
                        care of our profits."
                    </p>
                    <p>So, we will give you</p>
                    <p>● The friendliest working environment</p>
                    <p>● Opportunity to work with local and foreign clients.</p>
                    <p>
                        ● The best employee of the month receives a bonus every month.
                    </p>
                    <p>● Remuneration:</p>
                    <p className="ml-8">1. First 3 months: 5,000 BDT.</p>
                    <p className="ml-8">2. Last 3 months: 8,000 BDT.</p>
                    <p className="text-justify">
                        Welcome to our Internship Program “Career at Digital Agency -
                        Internship 2.0”. Each position offers a unique learning experience
                        and an opportunity to contribute to our team. Join us and unleash
                        your potential, learn from industry experts, and gain valuable
                        skills for your future career.
                    </p>
                </div>
                <div className="flex justify-end gap-5 px-5 items-center mt-4">
                    <div
                        className="bg-gray-400 hover:bg-red-400 text-white py-2 px-3 shadow-md cursor-pointer h-fit rounded-lg"
                        onClick={() => setIsOpen(false)}>
                        Close
                    </div>

                    <div className='flex justify-center'>
                        <Link to="https://forms.gle/1f1g2u2zxmWMaWR6A" target='_blank' className={`w-fit select-none rounded-lg shadow-md py-2 px-3 truncate cursor-pointer bg-gradient-to-r from-primary1 to-[#5CE0FF] hover:from-primary2 hover:to-[#fffd98] text-center text-white hover:text-black`}>Apply Now</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default JobDescriptionModal