import React, { useEffect, useState } from "react";
import { galleryData, gellaryHeaderData } from "../assets/data/galleryData";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

const GallerySection = () => {
    const [selectedGallery, setSelectedGallery] = useState("");
    const [galleryImage, setGalleryImage] = useState(galleryData);
    const [index, setIndex] = useState(-1);

    useEffect(() => {
        if (selectedGallery) {
            setGalleryImage(galleryData?.filter(item => item?.category === selectedGallery));
        } else {
            setGalleryImage(galleryData);
        }
    }, [selectedGallery]);

    return (
        <div className="w-full 2xl:px-[240px] xl:px-[40px] px-[10px]">
            <div className="flex items-center justify-center gap-5">
                {gellaryHeaderData?.map((header, index) => {
                    return (
                        <div onClick={() => {
                            if (selectedGallery === header?.title) {
                                setSelectedGallery("");
                            } else {
                                setSelectedGallery(header?.title)
                            }
                        }} key={index} className={`h-[100px] w-[100px] md:h-[140px] md:w-[140px] bg-[#222222] rounded-full overflow-hidden relative flex items-center justify-center cursor-pointer group`}>
                            <div
                                className="h-[100px] w-[100px] md:h-[140px] md:w-[140px] transition-all animate-spin-more-slow absolute top-0 bottom-0 left-0 right-0"
                                style={{
                                    backgroundImage: `url(${header?.image})`, backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'cover'
                                }}
                            />
                            <div className={`p-3 rounded-lg select-none ${selectedGallery === header?.title ? "bg-primary1" : "bg-white/20 group-hover:text-primary2"} group-hover:scale-125 backdrop-blur-[1px] text-white w-[100px] text-center transition-all duration-500`}>
                                {header?.title}
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className="mt-10">
                <div className="flex flex-wrap gap-4">
                    {galleryImage.map((image, index) => (
                        <div
                            key={index}
                            onClick={() => setIndex(index)}
                            className="group overflow-hidden rounded-lg cursor-pointer"
                        >
                            <img
                                src={image?.src}
                                alt={`gallery ${index}`}
                                className="w-auto h-[200px] rounded-lg overflow-hidden shadow-md group-hover:scale-150 duration-1000 transition-all"
                            />
                        </div>
                    ))}
                </div>
            </div>
            <Lightbox
                slides={galleryImage}
                open={index >= 0}
                index={index}
                close={() => setIndex(-1)}
                className="cursor-default"
            />
        </div>
    );
};

export default GallerySection;
