import React, { useEffect, useState } from 'react'
import Loading from '../../components/Loading'
import WeTechVideoSection from '../../sections/WeTechVideoSection'
import { IJob } from './CareerScreen';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

const JobDetailsScreen = () => {
    const baseUrl = process.env.REACT_APP_API_ENDPOINT;
    const [jobDetails, setJobDetails] = useState<IJob | null>(null);
    const [loading, setLoading] = useState(true);
    const params = useParams();

    useEffect(() => {
        if (baseUrl && params?.id) {
            fetch(`${baseUrl}/career/list`)
                .then((response) => {
                    if (!response.ok) {
                        toast.error('Network response was not ok!')
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then((data) => {
                    const job = data?.filter((job: IJob) => job?._id === params?.id);
                    if (job?.length > 0) {
                        setJobDetails(job[0]);
                    }
                    setLoading(false);
                })
                .catch((error) => {
                    toast.error('Something went wrong!')
                    console.error('Error fetching data:', error);
                    setLoading(false);
                });
        }
    }, [baseUrl, params?.id]);

    return (
        <div>
            {loading && <Loading />}
            <div className="flex flex-col justify-center items-center my-10">
                <div className="text-center text-h2 font-semibold mb-5">Career</div>
                <div className="text-center w-full lg:w-[60%]">
                    WeTech Digital is an all-in-one digital marketing services agency that
                    is dedicated to developing your business's outstanding success with
                    online advertising and marketing facilities.
                </div>
            </div>
            <div>
                <WeTechVideoSection videoUrl="https://youtu.be/IxJj5OzH8bM" />
            </div>
            <div className='w-full 2xl:px-[240px] xl:px-[40px] px-[10px] py-[80px]'>
                <div className='bg-white rounded-lg p-4'>
                    {jobDetails !== null ?
                        <div className='my-10' dangerouslySetInnerHTML={{ __html: jobDetails?.description }} />
                        : <div className="text-center h-[50vh] flex justify-center items-center text-red-400">There is no job details available!</div>
                    }
                </div>
            </div>
        </div>
    )
}

export default JobDetailsScreen