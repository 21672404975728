import React from 'react'
import { teamMembersInfo } from '../assets/data/teamMemberData';
import { TEAM_CIRCLE_BG } from '../assets/images/imageData';

const AboutUsTeamSection = () => {
    return (
        <div className='w-full 2xl:px-[240px] xl:px-[40px] px-[10px] py-[120px]'>
            <div className='flex flex-col justify-center items-center'>
                <div className='text-center text-h2 font-semibold mb-5'>Our Team: Meet the Faces Behind Our Success</div>
                <div className='text-center w-full lg:w-[60%]'>Discover the experts, innovators, and dedicated professionals driving our organization's success. Explore their skills, experience, and passion for our shared mission. Get to know the force behind our achievements.</div>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-24 mt-20'>
                {teamMembersInfo?.map((member, index) => {
                    return (
                        <div key={index} className='bg-white rounded-lg p-[40px] flex flex-col items-center gap-10 relative group overflow-hidden'>
                            <div className='rounded-full overflow-hidden w-[210px] h-[210px] border-[4px] border-primary1'>
                                <img src={member?.image} alt={member?.name} className='w-full h-full object-cover rounded-full p-2 group-hover:scale-125 duration-500' />
                            </div>
                            <div className='flex flex-col text-center gap-5 z-10'>
                                <div className='text-h4 text-[#4F4F4F] font-semibold'>{member?.name}</div>
                                <div className='text-primary1'>{member?.designation}</div>
                                <div className='text-sm text-[#4F4F4F]'>{member?.description}</div>
                            </div>
                            <div className='absolute -bottom-5 -left-5 z-0'>
                                <img src={TEAM_CIRCLE_BG} alt="teamCircleBg" className='w-full h-full' />
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default AboutUsTeamSection