import React from 'react'
import ServicesSection from '../../sections/ServicesSection'
import { Link } from 'react-router-dom';
import { service1List } from '../../assets/data/serviceData';
import { PORTFOLIO_BG_1, STAR } from '../../assets/images/imageData';

const ServicesScreen = () => {

    return (
        <div className='mt-10'>
            <div className="w-full 2xl:px-[240px] xl:px-[40px] px-[10px] overflow-hidden my-10">
                <div className="w-full relative py-10">
                    <div className="text-h4 sm:text-h3 lg:text-h2 2xl:text-h1 text-center font-kanit">
                        Your{" "}
                        <span
                            className="text-gray-400 typed-text"
                            datatype="Success"
                        >
                            Success
                        </span>{", "}
                        <span
                            className=""
                        >
                            Our Passion: Digital
                        </span>{" "}
                        <span
                            className="text-gray-400 typed-text"
                            datatype="Marketing"
                        >
                            Marketing
                        </span>{" "}
                        <span>Excellence</span>
                    </div>
                    <div className="absolute top-0 right-0 animate-spin-slow">
                        <img src={STAR} alt="star" />
                    </div>
                    <div className="absolute bottom-0 left-0 animate-spin-slow">
                        <img src={STAR} alt="star" />
                    </div>
                </div>
            </div>
            <ServicesSection data={service1List} title='Services that Connect You to Your Users' />
            <div className='w-full 2xl:px-[240px] xl:px-[40px] px-[10px] overflow-hidden my-20'>
                <div className='w-full flex justify-center items-center relative min-h-[350px]'>
                    <div className='w-full rounded-2xl overflow-hidden h-full object-cover'>
                        <img src={PORTFOLIO_BG_1} alt='portfolio_bg' className='w-full min-h-[350px] object-cover' />
                    </div>
                    <div className='absolute w-[350px] lg:w-[400px] xl:w-[450px] flex flex-col items-center gap-5'>
                        <div className='text-h3'>Portfolio Highlights</div>
                        <div>Discover our curated selection of projects that exemplify our dedication to innovation and quality craftsmanship.</div>
                        <Link to="/our-portfolio" className={`w-fit select-none rounded-lg py-2 px-5 truncate cursor-pointer bg-gradient-to-r from-primary1 to-[#5CE0FF] hover:from-primary2 hover:to-[#fffd98] text-center text-white hover:text-black`}>Explore</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServicesScreen