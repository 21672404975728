import React from 'react'
import { SERVICE_TOP_BG, WORK_HELP_1, WORK_HELP_2, WORK_HELP_3 } from '../assets/images/imageData';
import { workData } from '../assets/data/clientData';
import SECTION_BG from '../assets/images/extra/section-bg.svg';

const HowHelpSection = () => {
    return (
        <div className="w-full 2xl:px-[240px] xl:px-[40px] px-[10px] py-[80px] relative text-white" style={{
            backgroundImage: `url(${SECTION_BG})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
        }}>
            <div className='absolute bg-white/20 h-60 w-60 rounded-full bottom-[-65px] right-[-45px]' />
            <div className='absolute bg-gradient-to-r from-white/30 transition-all animate-spin h-32 w-32 rounded-full bottom-20 right-20' />
            <div className="absolute h-[300px] top-0 right-0">
                <img src={SERVICE_TOP_BG} alt="servicesTopRightBG" className="w-full h-full" />
            </div>
            <div>
                <div className="text-center flex flex-col gap-2 mb-20 px-4">
                    <div className="text-h3 md:text-h2 lg:text-h1">How we help you through our work!</div>
                    <div className="">
                        Our Expert Team is Committed to Safeguarding Your Trust and Interests with Unparalleled Precision and Integrity.
                    </div>
                </div>
                <div className='flex gap-10 w-full'>
                    <div className='w-full hidden lg:flex relative'>
                        <div className='absolute top-0 left-[35%] hover:scale-110 duration-500 z-[5] hover:z-10'>
                            <img src={WORK_HELP_1} alt="workHelp1" className='w-full h-full' />
                        </div>
                        <div className='absolute top-36 left-0 hover:scale-110 duration-500 z-[5] hover:z-10'>
                            <img src={WORK_HELP_2} alt="workHelp2" className='w-full h-full' />
                        </div>
                        <div className='absolute bottom-10 right-[10%] hover:scale-110 duration-500 z-[5] hover:z-10'>
                            <img src={WORK_HELP_3} alt="workHelp2" className='w-full h-full' />
                        </div>
                        <div className='absolute top-0 left-0 bg-gradient-to-r from-primary2 to-[#f5ee92] transition-all animate-spin h-16 w-16 rounded-full hover:scale-125 duration-1000 hover:z-10' />
                        <div className='absolute top-24 left-[30%] bg-gradient-to-r from-primary1 to-[#bde8fa] transition-all animate-spin h-16 w-16 rounded-full hover:scale-125 duration-1000 hover:z-10' />
                        <div className='absolute top-[30%] right-[15%] bg-gradient-to-r from-primary2 to-[#f5ee92] transition-all animate-spin h-20 w-20 rounded-full hover:scale-125 duration-1000 hover:z-10' />
                        <div className='absolute bottom-0 right-[30%] bg-gradient-to-r from-primary1 to-[#bde8fa] transition-all animate-spin h-24 w-24 rounded-full hover:scale-125 duration-1000 hover:z-10' />
                    </div>
                    <div className='w-full flex flex-col gap-10 px-4'>
                        {workData?.map((dt, index) => {
                            return (
                                <div key={index} className='text-white flex flex-col gap-3'>
                                    <div className='flex gap-4 items-center text-h4 font-semibold'>
                                        <div className='bg-gradient-to-r from-primary1 to-[#9ce0fd] transition-all animate-spin h-5 w-5 rounded-full' />{dt?.title}
                                    </div>
                                    <div className='text-justify'>{dt?.subTitle}</div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HowHelpSection