import React from 'react'
import { Link } from 'react-router-dom';
import { socilaIcons, tabs } from '../assets/data/navigationData';
import { WHITE_LOGO, FOOTER_BG } from '../assets/images/imageData';
import { reachOutData, service1List } from '../assets/data/serviceData';
import { SISTER_CONCERN } from '../assets/data/wetechData';

const Footer = () => {
    return (
        <div className='2xl:px-[240px] xl:px-[40px] px-[10px] py-10 bg-black w-full relative'>
            <div className='grid grid-cols-1 md:grid-cols-3 gap-y-10 md:gap-x-5 text-white'>
                <div className='w-full flex justify-center'>
                    <div className='w-[270px] p-2 flex flex-col items-center sm:items-start gap-5'>
                        <div className='flex justify-center md:justify-normal w-full'>
                            <Link to="/">
                                <img src={WHITE_LOGO} alt='logo' className='w-[200px] cursor-pointer' />
                            </Link>
                        </div>
                        <div className='flex flex-col gap-7 mt-5'>
                            {reachOutData?.map((tab, index) => {
                                return (
                                    <Link to={tab?.link} target='_blank' key={index} className='flex gap-5 items-center cursor-pointer'>
                                        <img src={tab?.icon} alt={tab?.title} className='w-[20px] h-[20px] rounded-full' />
                                        <p className='text-white cursor-pointer'>{tab?.title}</p>
                                    </Link>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className='w-full flex justify-center'>
                    <div className='w-[270px] p-2 flex flex-col items-center sm:items-start gap-5'>
                        <div className='font-semibold text-h4'>Quick Links</div>
                        <div className='flex flex-col items-center sm:items-start gap-4'>
                            {tabs?.map((tab, index) => {
                                if (tab?.title === 'Services')
                                    return null;
                                return (
                                    <Link to={tab?.path} key={index} className='text-white hover:text-primary1 cursor-pointer'>{tab?.title?.toUpperCase()}</Link>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className='w-full flex justify-center'>
                    <div className='w-[270px] p-2 flex flex-col items-center sm:items-start gap-5'>
                        <div className='font-semibold text-h4'>Services</div>
                        <div className='flex flex-col items-center sm:items-start gap-4'>
                            {service1List?.map((tab, index) => {
                                return (
                                    <Link to={tab?.path} key={index} className='text-white hover:text-primary1 cursor-pointer'>{tab?.title}</Link>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex w-full justify-center text-white text-center'>
                <div className='w-fit'>
                    <div className='flex flex-wrap justify-between mb-2 mt-10 gap-5'>
                        {SISTER_CONCERN?.map((item, index) => {
                            return (
                                <Link to={item?.link} target='_blank' key={index}>
                                    <img src={item?.image} alt={index + "sister concern"} className='w-full h-[25px] cursor-pointer' />
                                </Link>
                            )
                        })}
                    </div>
                    <div className='bg-gray-500 h-[1px] w-full mb-4' />
                    <div>Our Sister Concern.</div>
                </div>
            </div>
            <div className='w-full h-[0.1px] bg-white/20 my-10' />
            <div className='my-7 flex w-full justify-between items-center flex-col-reverse md:flex-row text-white gap-10'>
                <div className='w-full md:w-fit text-center md:text-left'>© 2024 WeTech Digital</div>
                <div className='flex flex-wrap justify-center gap-5 w-full md:w-fit z-[100]'>
                    {socilaIcons?.map((icon, index) => {
                        return (
                            <Link to={icon?.url} target='_blank' key={index} className='h-[40px] w-[40px] rounded-full bg-white flex justify-center items-center p-1 group hover:scale-150 duration-1000 cursor-pointer'>
                                <img src={icon?.name} alt={`social icon ${index}`} />
                            </Link>
                        )
                    })}
                </div>
            </div>
            <div className='h-full absolute right-0 top-0 bottom-0 opacity-20'>
                <img src={FOOTER_BG} alt="Footer BG" className='h-full w-full' />
            </div>
        </div>
    )
}

export default Footer