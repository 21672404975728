import { GALLERY_BG } from "../images/imageData";

export const gellaryHeaderData = [
    {
        title: "Event",
        image: GALLERY_BG,
    },
    {
        title: "Team",
        image: GALLERY_BG,
    },
    {
        title: "Meeting",
        image: GALLERY_BG,
    },
];

export const galleryData = [
    {
        src: require("../images/gallery/event/1.JPG"),
        category: "Event",
    },
    {
        src: require("../images/gallery/event/2.JPG"),
        category: "Event",
    },
    {
        src: require("../images/gallery/event/3.JPG"),
        category: "Event",
    },
    {
        src: require("../images/gallery/event/4.JPG"),
        category: "Event",
    },
    {
        src: require("../images/gallery/event/5.JPG"),
        category: "Event",
    },
    {
        src: require("../images/gallery/event/6.JPG"),
        category: "Event",
    },
    {
        src: require("../images/gallery/event/7.JPG"),
        category: "Event",
    },
    {
        src: require("../images/gallery/event/8.JPG"),
        category: "Event",
    },
    {
        src: require("../images/gallery/event/9.JPG"),
        category: "Event",
    },
    {
        src: require("../images/gallery/event/10.JPG"),
        category: "Event",
    },
    {
        src: require("../images/gallery/event/11.JPG"),
        category: "Event",
    },
    {
        src: require("../images/gallery/event/12.JPG"),
        category: "Event",
    },
    {
        src: require("../images/gallery/event/13.JPG"),
        category: "Event",
    },
    {
        src: require("../images/gallery/event/14.JPG"),
        category: "Event",
    },
    {
        src: require("../images/gallery/event/15.JPG"),
        category: "Event",
    },
    {
        src: require("../images/gallery/event/16.JPG"),
        category: "Event",
    },
    {
        src: require("../images/gallery/team/1.JPG"),
        category: "Team",
    },
    {
        src: require("../images/gallery/team/2.JPG"),
        category: "Team",
    },
    {
        src: require("../images/gallery/team/3.JPG"),
        category: "Team",
    },
    {
        src: require("../images/gallery/team/4.JPG"),
        category: "Team",
    },
    {
        src: require("../images/gallery/team/5.JPG"),
        category: "Team",
    },
    {
        src: require("../images/gallery/team/6.JPG"),
        category: "Team",
    },
    {
        src: require("../images/gallery/team/7.JPG"),
        category: "Team",
    },
    {
        src: require("../images/gallery/team/8.JPG"),
        category: "Team",
    },
    {
        src: require("../images/gallery/team/9.JPG"),
        category: "Team",
    },
    {
        src: require("../images/gallery/team/10.JPG"),
        category: "Team",
    },
    {
        src: require("../images/gallery/team/11.JPG"),
        category: "Team",
    },
    {
        src: require("../images/gallery/team/12.JPG"),
        category: "Team",
    },
    {
        src: require("../images/gallery/team/13.JPG"),
        category: "Team",
    },
    {
        src: require("../images/gallery/team/14.JPG"),
        category: "Team",
    },
    {
        src: require("../images/gallery/team/15.JPG"),
        category: "Team",
    },
    {
        src: require("../images/gallery/meeting/1.JPG"),
        category: "Meeting",
    },
    {
        src: require("../images/gallery/meeting/2.JPG"),
        category: "Meeting",
    },
    {
        src: require("../images/gallery/meeting/3.JPG"),
        category: "Meeting",
    },
    {
        src: require("../images/gallery/meeting/4.JPG"),
        category: "Meeting",
    },
    {
        src: require("../images/gallery/meeting/5.JPG"),
        category: "Meeting",
    },
    {
        src: require("../images/gallery/meeting/6.JPG"),
        category: "Meeting",
    },
    {
        src: require("../images/gallery/meeting/7.JPG"),
        category: "Meeting",
    },
    {
        src: require("../images/gallery/meeting/8.JPG"),
        category: "Meeting",
    },
    {
        src: require("../images/gallery/meeting/9.JPG"),
        category: "Meeting",
    },
    {
        src: require("../images/gallery/meeting/10.JPG"),
        category: "Meeting",
    },
    {
        src: require("../images/gallery/meeting/11.JPG"),
        category: "Meeting",
    },
    {
        src: require("../images/gallery/meeting/12.JPG"),
        category: "Meeting",
    },
    {
        src: require("../images/gallery/meeting/13.JPG"),
        category: "Meeting",
    },
    {
        src: require("../images/gallery/meeting/14.JPG"),
        category: "Meeting",
    },
    {
        src: require("../images/gallery/meeting/15.JPG"),
        category: "Meeting",
    },
    {
        src: require("../images/gallery/meeting/16.JPG"),
        category: "Meeting",
    },
    {
        src: require("../images/gallery/meeting/17.JPG"),
        category: "Meeting",
    },
    {
        src: require("../images/gallery/meeting/18.JPG"),
        category: "Meeting",
    },
];