import React, { useEffect, useState } from 'react'
import Lottie from 'react-lottie'
import PageNotFound from '../../assets/json/404.json';
import { Link, useLocation } from 'react-router-dom';
import { BsArrowLeft } from 'react-icons/bs';
import BG from '../../assets/images/extra/bg.gif';

const NotFound = () => {
    const location = useLocation();
    const [navigationPath, setNavigationPath] = useState('/');


    useEffect(() => {
        if (location?.pathname) {
            const parts = location?.pathname.split('/');
            parts.pop();
            const newPathname = parts.join('/');
            setNavigationPath(newPathname);
        }
    }, [location]);

    return (
        <div className='h-[100vh] w-[100vw] fixed top-0 bottom-0 left-0 right-0 z-[10000] flex justify-center items-center' style={{
            backgroundImage: `url(${BG})`,
            backgroundRepeat: 'repeat',
            backgroundSize: 'cover'
        }}>
            <div className='h-[100vh] w-[100vw] fixed top-0 bottom-0 left-0 right-0 z-[10000] flex justify-center items-center bg-white/10 backdrop-blur-sm'>
                <div className='h-full w-full flex flex-col items-center'>
                    <Lottie options={{
                        loop: true,
                        animationData: PageNotFound
                    }} />
                    <Link to={navigationPath} className="w-fit absolute top-[80vh] flex items-center gap-5 mt-10 select-none rounded-lg py-3 px-4 cursor-pointer bg-gradient-to-r from-primary1 to-[#5CE0FF] hover:from-primary2 hover:to-[#fffd98] text-center text-white hover:text-black"><BsArrowLeft />Go Back</Link>
                </div>
            </div>
        </div>
    )
}

export default NotFound