import React, { useState } from "react";
import { BsTelephoneForwardFill } from "react-icons/bs";
import { GrMail } from "react-icons/gr";
import { FaLocationDot } from "react-icons/fa6";
import { toast } from 'react-toastify';
import Loading from "../components/Loading";

const ContactSection = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const baseUrl = process.env.REACT_APP_API_ENDPOINT;
    const [loading, setLoading] = useState(false);

    const handleSendMessage = async () => {
        const data = {
            name: firstName + ' ' + lastName,
            email,
            phone,
            message,
        }

        if (!firstName) {
            toast.info('First name is missing!');
            return;
        }
        if (!lastName) {
            toast.info('Last name is missing!');
            return;
        }
        if (!email) {
            toast.info('Email is missing!');
            return;
        }
        if (!phone) {
            toast.info('Phone is missing!');
            return;
        }
        if (!message) {
            toast.info('message is missing!');
            return;
        }
        setLoading(true);
        fetch(`${baseUrl}/contact/add`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        }).then((response) => {
            if (!response.ok) {
                setLoading(false);
                toast.error("Network response was not ok");
                throw new Error('Network response was not ok');
            }
            return response.json(); // Parse the response JSON if needed
        })
            .then(() => {
                setLoading(false);
                setFirstName("");
                setLastName("");
                setEmail("");
                setPhone("");
                setMessage("");
                toast.success("Message sent successfully!")
            })
            .catch((error) => {
                // Handle any errors
                setLoading(false);
                toast.error("Something went wrong!");
                console.error('There was a problem with the fetch operation:', error);
            });
    }

    return (
        <div className="w-full 2xl:px-[240px] xl:px-[40px] px-[10px]">
            {loading && <Loading />}
            <div className="text-black text-center flex flex-col gap-2 mb-20">
                <div className="text-h3 md:text-h2 lg:text-h1">Contact Us</div>
                <div className="">
                    Any question or remarks? Just write us a message!
                </div>
            </div>
            <div className="w-full flex flex-col lg:flex-row gap-10">
                <div className="w-full bg-primary1/70 backdrop-blur-sm p-[40px] rounded-lg flex flex-col gap-10 relative">
                    <div className='absolute bg-white/20 h-40 w-40 rounded-full bottom-[-60px] right-[-40px]' />
                    <div className='absolute bg-gradient-to-r from-white/30 transition-all animate-spin h-20 w-20 rounded-full bottom-10 right-10' />
                    <div className="flex flex-col gap-2 text-center">
                        <div className="text-h4 text-white">Contact Information</div>
                        <div className="text-[#f3f3f3]">
                            Say something to start a live chat!
                        </div>
                    </div>
                    <div className="w-full rounded-lg overflow-hidden">
                        <iframe
                            title="WeTech Digital"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3070.848105189031!2d90.38432228149868!3d23.75185736019039!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755b9bece9995b1%3A0xb019b8000bb2cfbd!2sWeTech%20Digital!5e0!3m2!1sen!2sbd!4v1695182721295!5m2!1sen!2sbd"
                            width="100%"
                            height="200px"
                            style={{ border: "0" }}
                            allowFullScreen
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>
                    </div>
                    <div className="text-white flex flex-col gap-4 text-h5">
                        <div className="flex gap-4 items-center">
                            <BsTelephoneForwardFill /> +8801817698776
                        </div>
                        <div className="flex gap-4 items-center">
                            <GrMail /> contact@wetechdigital.com
                        </div>
                        <div className="flex gap-4 items-center">
                            <FaLocationDot /> {`44 F/7(Rongon Tower),
                            West Panthapath,Dhaka`}
                        </div>
                        <div className="flex gap-4 items-center">
                            <FaLocationDot /> {`54 Wordsworth Road, Small heath,
                             Birmingham. B10 0EE, UK.`}
                        </div>
                    </div>
                </div>
                <div className="w-full p-[40px] rounded-lg flex flex-col gap-8">
                    <div className="flex flex-col md:flex-row gap-5 w-full">
                        <div className="flex flex-col gap-2 w-full">
                            <div className="text-black text-lg">First Name</div>
                            <input type="text" value={firstName} onChange={e => setFirstName(e.target.value)} className='px-3 py-4 rounded-lg focus:outline-primary1 truncate' placeholder="Write your first name" />
                        </div>
                        <div className="flex flex-col gap-2 w-full">
                            <div className="text-black text-lg">Last Name</div>
                            <input type="text" value={lastName} onChange={e => setLastName(e.target.value)} className='px-3 py-4 rounded-lg focus:outline-primary1 truncate' placeholder="Write your last name" />
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row gap-5 w-full">
                        <div className="flex flex-col gap-2 w-full">
                            <div className="text-black text-lg">Email</div>
                            <input type="email" value={email} onChange={e => setEmail(e.target.value)} className='px-3 py-4 rounded-lg focus:outline-primary1 truncate' placeholder="Write your email" />
                        </div>
                        <div className="flex flex-col gap-2 w-full">
                            <div className="text-black text-lg">Phone</div>
                            <input type="number" value={phone} onChange={e => setPhone(e.target.value)} className='px-3 py-4 rounded-lg focus:outline-primary1 truncate' placeholder="Write your number" />
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row gap-5 w-full">
                        <div className="flex flex-col gap-2 w-full">
                            <div className="text-black text-lg">Message</div>
                            <textarea value={message} onChange={e => setMessage(e.target.value)} className='px-3 py-4 rounded-lg focus:outline-primary1 truncate' placeholder="Write your message" rows={5} />
                        </div>
                    </div>
                    <div className="w-full flex justify-end">
                        <div onClick={handleSendMessage} className={`w-fit select-none rounded-lg py-3 px-4 truncate cursor-pointer bg-gradient-to-r from-primary1 to-[#5CE0FF] hover:from-primary2 hover:to-[#fffd98] text-center text-white hover:text-black`}>Send Message</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactSection;
