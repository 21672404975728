export const BRANDING_1 = require("../images/branding/1.svg").default;
export const BRANDING_2 = require("../images/branding/2.svg").default;
export const BRANDING_3 = require("../images/branding/3.svg").default;
export const BRANDING_4 = require("../images/branding/4.svg").default;
export const BRANDING_5 = require("../images/branding/5.svg").default;
export const BRANDING_6 = require("../images/branding/6.svg").default;
export const BRANDING_7 = require("../images/branding/7.svg").default;
export const BRANDING_8 = require("../images/branding/8.svg").default;
export const BRANDING_9 = require("../images/branding/9.svg").default;
export const BRANDING_10 = require("../images/branding/10.svg").default;
export const BRANDING_11 = require("../images/branding/11.svg").default;
export const BRANDING_12 = require("../images/branding/12.svg").default;
export const BRANDING_13 = require("../images/branding/13.svg").default;

export const DESIGN_1 = require("../images/design/1.svg").default;
export const DESIGN_2 = require("../images/design/2.svg").default;
export const DESIGN_3 = require("../images/design/3.svg").default;
export const DESIGN_4 = require("../images/design/4.svg").default;
export const DESIGN_5 = require("../images/design/5.svg").default;
export const DESIGN_6 = require("../images/design/6.svg").default;
export const DESIGN_7 = require("../images/design/7.svg").default;
export const DESIGN_8 = require("../images/design/8.svg").default;
export const DESIGN_9 = require("../images/design/9.svg").default;
export const DESIGN_10 = require("../images/design/10.svg").default;

export const RESEARCH_1 = require("../images/research/1.svg").default;
export const RESEARCH_2 = require("../images/research/2.svg").default;
export const RESEARCH_3 = require("../images/research/3.svg").default;
export const RESEARCH_4 = require("../images/research/4.svg").default;
export const RESEARCH_5 = require("../images/research/5.svg").default;

export const SEO_1 = require("../images/seo/1.svg").default;
export const SEO_2 = require("../images/seo/2.svg").default;
export const SEO_3 = require("../images/seo/3.svg").default;
export const SEO_4 = require("../images/seo/4.svg").default;
export const SEO_5 = require("../images/seo/5.svg").default;
export const SEO_6 = require("../images/seo/6.svg").default;
export const SEO_7 = require("../images/seo/7.svg").default;
export const SEO_8 = require("../images/seo/8.svg").default;
export const SEO_9 = require("../images/seo/9.svg").default;
export const SEO_10 = require("../images/seo/10.svg").default;
export const SEO_11 = require("../images/seo/11.svg").default;
export const SEO_12 = require("../images/seo/12.svg").default;
export const SEO_13 = require("../images/seo/13.svg").default;
export const SEO_14 = require("../images/seo/14.svg").default;
export const SEO_15 = require("../images/seo/15.svg").default;

export const SOCIAL_1 = require("../images/social/1.svg").default;
export const SOCIAL_2 = require("../images/social/2.svg").default;
export const SOCIAL_3 = require("../images/social/3.svg").default;
export const SOCIAL_4 = require("../images/social/4.svg").default;
export const SOCIAL_5 = require("../images/social/5.svg").default;
export const SOCIAL_6 = require("../images/social/6.svg").default;
export const SOCIAL_7 = require("../images/social/7.svg").default;
export const SOCIAL_8 = require("../images/social/8.svg").default;
export const SOCIAL_9 = require("../images/social/9.svg").default;
export const SOCIAL_10 = require("../images/social/10.svg").default;
export const SOCIAL_11 = require("../images/social/11.svg").default;
export const SOCIAL_12 = require("../images/social/12.svg").default;
export const SOCIAL_13 = require("../images/social/13.svg").default;
export const SOCIAL_14 = require("../images/social/14.svg").default;
export const SOCIAL_15 = require("../images/social/15.svg").default;

export const SERVICE_1 = require("../images/service/1.svg").default;
export const SERVICE_2 = require("../images/service/2.svg").default;
export const SERVICE_3 = require("../images/service/3.svg").default;
export const SERVICE_4 = require("../images/service/4.svg").default;
export const SERVICE_5 = require("../images/service/5.svg").default;

export const SEO = require("../images/seo/seo.svg").default;
export const SOCIAL = require("../images/social/social.svg").default;
export const RESEARCH = require("../images/research/research.svg").default;
export const DESIGN = require("../images/design/design.svg").default;
export const BRANDING = require("../images/branding/branding.svg").default;

export const CLIENT_1 = require("../images/client/1.svg").default;
export const CLIENT_2 = require("../images/client/2.svg").default;
export const CLIENT_3 = require("../images/client/3.svg").default;
export const CLIENT_4 = require("../images/client/4.svg").default;
export const CLIENT_5 = require("../images/client/5.svg").default;
export const CLIENT_6 = require("../images/client/6.svg").default;
export const CLIENT_7 = require("../images/client/7.svg").default;
export const CLIENT_8 = require("../images/client/8.svg").default;
export const CLIENT_9 = require("../images/client/9.svg").default;
export const CLIENT_10 = require("../images/client/10.svg").default;
export const CLIENT_11 = require("../images/client/11.svg").default;
export const CLIENT_12 = require("../images/client/12.svg").default;
export const CLIENT_13 = require("../images/client/13.svg").default;
export const CLIENT_14 = require("../images/client/14.svg").default;
export const CLIENT_15 = require("../images/client/15.svg").default;
export const CLIENT_16 = require("../images/client/16.svg").default;
export const CLIENT_17 = require("../images/client/17.svg").default;
export const CLIENT_18 = require("../images/client/18.svg").default;
export const CLIENT_19 = require("../images/client/19.svg").default;
export const CLIENT_20 = require("../images/client/20.svg").default;
export const CLIENT_21 = require("../images/client/21.svg").default;
export const CLIENT_22 = require("../images/client/22.svg").default;
export const CLIENT_23 = require("../images/client/23.svg").default;
export const CLIENT_24 = require("../images/client/24.svg").default;
export const CLIENT_25 = require("../images/client/25.svg").default;
export const CLIENT_26 = require("../images/client/26.svg").default;
export const CLIENT_27 = require("../images/client/27.svg").default;
export const CLIENT_28 = require("../images/client/28.svg").default;
export const CLIENT_29 = require("../images/client/29.svg").default;
export const CLIENT_30 = require("../images/client/30.svg").default;
export const CLIENT_31 = require("../images/client/31.svg").default;
export const CLIENT_32 = require("../images/client/32.svg").default;

export const PARTNER_1 = require("../images/partner/1.svg").default;
export const PARTNER_2 = require("../images/partner/2.svg").default;
export const PARTNER_3 = require("../images/partner/3.svg").default;
export const PARTNER_4 = require("../images/partner/4.svg").default;
export const PARTNER_5 = require("../images/partner/5.webp");
export const PARTNER_6 = require("../images/partner/6.webp");

export const FACEBOOK = require("../images/social-icon/facebook.svg").default;
export const YOUTUBE = require("../images/social-icon/youtube.svg").default;
export const LINKEDIN = require("../images/social-icon/linkedin.svg").default;
export const INSTAGRAM = require("../images/social-icon/instagram.svg").default;
export const TWITTER = require("../images/social-icon/twitter.svg").default;
export const DRIBBBLE = require("../images/social-icon/dribbble.svg").default;
export const BEHANCE = require("../images/social-icon/behance.svg").default;

export const CONTENT_1 = require("../images/content/1.gif");
export const CONTENT_2 = require("../images/content/2.gif");
export const CONTENT_3 = require("../images/content/3.gif");
export const CONTENT_4 = require("../images/content/4.gif");
export const CONTENT_5 = require("../images/content/5.gif");

export const WORK_HELP_1 = require("../images/work-help/1.svg").default;
export const WORK_HELP_2 = require("../images/work-help/2.svg").default;
export const WORK_HELP_3 = require("../images/work-help/3.svg").default;

export const HERO = require("../images/hero/hero.json");
export const HERO_1 = require("../images/hero/1.gif");
export const HERO_2 = require("../images/hero/2.gif");

export const PERSON_1 = require("../images/person/1.svg").default;
export const PERSON_2 = require("../images/person/2.svg").default;
export const PERSON_3 = require("../images/person/3.svg").default;
export const PERSON_4 = require("../images/person/4.svg").default;
export const PERSON_5 = require("../images/person/5.svg").default;

//extra image
export const BLACK_LOGO = require("../images/extra/black-logo.png");
export const WHITE_LOGO = require("../images/extra/white-logo.png");
export const SCROLL_TOP = require("../images/extra/scroll-top.gif");
export const STAR = require("../images/extra/star.svg").default;
export const APPLY_ARROW = require("../images/extra/apply-arrow.svg").default;
export const APPLY_NOW = require("../images/extra/apply-now.svg").default;
export const CARD_BG = require("../images/extra/card-bg.svg").default;
export const IMPROVEMENT = require("../images/extra/improvement.svg").default;
export const INCREASE = require("../images/extra/increase.svg").default;
export const FOOTER_BG = require("../images/extra/footer-bg.svg").default;

export const GMAIL = require("../images/extra/gmail.svg").default;
export const PHONE = require("../images/extra/phone.svg").default;
export const LOCATION = require("../images/extra/location.svg").default;

export const REVIEW_YOUTUBE_BUTTON = require("../images/extra/review-youtube-button.svg").default;
export const PORTFOLIO_BG_1 = require("../images/extra/portfolio-bg-1.svg").default;
export const SERVICE_TOP_BG = require("../images/extra/service-top-bg.svg").default;
export const TEAM_CIRCLE_BG = require("../images/extra/team-circle-bg.svg").default;

export const WHY = require("../images/extra/why.svg").default;

export const PROPOSITION = require("../images/extra/proposition.svg").default;

export const TESTIMONIAL_BG = require("../images/extra/testimonial-bg.svg").default;

//Internship Program
export const INTERNSHIP_PROGRAM = require("../images/internship/program.png");
export const INTERNSHIP_BANNER = require("../images/internship/banner.jpg");
export const INTERNSHIP_WHO_APPLY = require("../images/internship/who-apply.png");
export const INTERNSHIP_OPEN = require("../images/internship/open.png");

export const HANDS_ON_EXPERIENCE = require("../images/internship/hands-on-experience.png");
export const EXPERT_MENTORSHIP = require("../images/internship/expert-mentorship.png");
export const NETWORKING_OPPORTUNITIES = require("../images/internship/networking-opportunities.png");
export const SKILL_DEVELOPMENT = require("../images/internship/skill-development.png");

export const MOTION_GRAPHICS_DESIGNER = require("../images/internship/motion-graphics-designer.png");
export const CONTENT_WRITER = require("../images/internship/content-writer.png");
export const SEO_SPECIALIST = require("../images/internship/seo-specialist.png");
export const DIGITAL_MARKETING_EXECUTIVE = require("../images/internship/digital-marketing-executive.png");
export const CORPORATE_SALES = require("../images/internship/corporate-sales-communication-manager.png");


// team members
export const PAVEL = require("../images/team/pavel.png");
export const SAMIA = require("../images/team/samia.jpeg");
export const ABRAR = require("../images/team/abrar.jpeg");
export const MORSHED = require("../images/team/morshed.png");
export const SAMI = require("../images/team/sami.png");
export const WALIUR = require("../images/team/waliur.jpg");
export const SAKIB = require("../images/team/sakib.png");
export const NITISH = require("../images/team/nitish.png");
export const ATIKUR = require("../images/team/atikur.png");
export const SAIMA = require("../images/team/saima.jpg");
export const SUSMOY = require("../images/team/susmoy.jpg");
export const RANA = require("../images/team/rana.jpg");
export const ASAD = require("../images/team/asad.jpg");
export const SHUVO = require("../images/team/shuvo.png");
export const NAYEEM = require("../images/team/nayeem.jpg");
export const REZAUL = require("../images/team/rezaul.jpg");
export const SINTHIYA = require("../images/team/sinthiya.jpg");
export const TURZO = require("../images/team/turzo.jpg");
export const RASEL = require("../images/team/rasel.jpg");

// gallery 
export const GALLERY_BG = require("../images/gallery/gellary-bg.svg").default;

//sister concern
export const WETECH_SOFTWARE = require("../images/sister/wetech-software.png");
export const WETECH_STUDIO = require("../images/sister/wetech-studio.png");
export const BUSINESS_INSIGHT = require("../images/sister/business-insight.png");