import React from 'react'
import ContactSection from '../../sections/ContactSection'

const ContactUsScreen = () => {
    return (
        <div className='w-full my-20 overflow-hidden'>
            <ContactSection />
        </div>
    )
}

export default ContactUsScreen