import React from 'react'
import AboutUsVideoSection from '../../sections/AboutUsVideoSection'
import AboutUsTeamSection from '../../sections/AboutUsTeamSection'
import ContactSection from '../../sections/ContactSection'
import HowHelpSection from '../../sections/HowHelpSection'

const AboutUsScreen = () => {
    return (
        <div className='flex flex-col mt-10 w-full overflow-hidden'>
            <div className='w-full'>
                <AboutUsVideoSection />
            </div>
            <div className='w-full'>
                <AboutUsTeamSection />
            </div>
            <div className='w-full mt-[120px]'>
                <HowHelpSection />
            </div>
            <div className='w-full my-[120px]'>
                <ContactSection />
            </div>
        </div>
    )
}

export default AboutUsScreen