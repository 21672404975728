import React, { useEffect, useState } from "react";
import WeTechVideoSection from "../../sections/WeTechVideoSection";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Loading from "../../components/Loading";
import { IBlog } from "../Blog/BlogScreen";
import { APPLY_ARROW, APPLY_NOW, SERVICE_TOP_BG, TEAM_CIRCLE_BG } from "../../assets/images/imageData";
import InternshipHeader from "../../components/InternshipHeader";
import SECTION_BG from '../../assets/images/extra/section-bg.svg';

export interface IJob {
    _id: string;
    customTitle: string;
    title: string;
    description: string;
    type: string;
    salary: string;
    metaTags: string[];
    customUrl: string;
    file: string;
}

const CareerScreen = () => {
    const baseUrl = process.env.REACT_APP_API_ENDPOINT;
    const [jobList, setJobList] = useState<IJob[]>([]);
    const [blogList, setBlogList] = useState<IBlog[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (baseUrl) {
            fetch(`${baseUrl}/career/list`)
                .then((response) => {
                    if (!response.ok) {
                        toast.error("Network response was not ok!");
                        throw new Error("Network response was not ok");
                    }
                    return response.json();
                })
                .then((data) => {
                    setJobList(data);
                    setLoading(false);
                })
                .catch((error) => {
                    toast.error("Something went wrong!");
                    console.error("Error fetching data:", error);
                    setLoading(false);
                });
        }
    }, [baseUrl]);

    const truncateText = (text: string, maxLength: number) => {
        if (text.length > maxLength) {
            return text.slice(0, maxLength) + '...';
        }
        return text;
    };

    const timeCounter = (data: IBlog): string => {
        const words =
            data.title.trim().split(/\s+/).length +
            data.author.trim().split(/\s+/).length +
            data.description.trim().split(/\s+/).length +
            data.metaDescription.trim().split(/\s+/).length +
            data.customTitle.trim().split(/\s+/).length;

        const minutesToRead = Math.ceil(words / 200);

        if (minutesToRead < 1) {
            const secondsToRead = Math.ceil((words / 200) * 60);
            return `${secondsToRead} second${secondsToRead === 1 ? '' : 's'} to read`;
        }

        return `${minutesToRead} minute${minutesToRead === 1 ? '' : 's'} to read`;
    };

    useEffect(() => {
        if (baseUrl) {
            fetch(`${baseUrl}/blog/list`)
                .then((response) => {
                    if (!response.ok) {
                        toast.error('Network response was not ok!')
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then((data) => {
                    setBlogList(data);
                    setLoading(false);
                })
                .catch((error) => {
                    toast.error('Something went wrong!')
                    console.error('Error fetching data:', error);
                    setLoading(false);
                });
        }
    }, [baseUrl]);

    return (
        <>
            {loading && <Loading />}
            <div className="flex flex-col justify-center items-center my-10">
                <div className="text-center text-h2 font-semibold mb-5">Career</div>
                <div className="text-center w-full lg:w-[60%]">
                    WeTech Digital is an all-in-one digital marketing services agency that
                    is dedicated to developing your business's outstanding success with
                    online advertising and marketing facilities.
                </div>
            </div>
            <div>
                <WeTechVideoSection videoUrl="https://youtu.be/IxJj5OzH8bM" />
            </div>
            <div className="flex flex-col justify-center items-center my-20">
                <div className="text-center text-h2 font-semibold mb-5">
                    Our Ongoing Jobs
                </div>
                <div className="text-center w-full lg:w-[60%]">
                    At Wetech Digital, we believe in nurturing the talent of tomorrow.
                    Whether you're a coding genius, a creative designer, a marketing
                    enthusiast, or a business strategist, we have the perfect opportunity
                    waiting for you to explore.
                </div>
            </div>
            {jobList?.length > 0 ? <div className="flex flex-col">
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-20 mt-10 w-full 2xl:px-[240px] xl:px-[40px] px-[10px]'>
                    {jobList?.map((job, index) => {
                        return (
                            <div key={index} className='bg-white rounded-lg p-[10px] flex flex-col items-center relative group overflow-hidden hover:scale-110 duration-500'>
                                <div>
                                    <img src={job?.file} alt={job?.title} className='w-full rounded-lg overflow-hidden' />
                                </div>

                                <div className='w-full flex justify-end items-center mt-10'>
                                    <Link to={`/job-details/${job?._id}`} className='px-2 py-1 bg-[#CCEBF8] text-primary1 hover:text-primary2 hover:bg-[#f8f4cc] rounded-md z-10 cursor-pointer duration-500'>Job Details</Link>
                                </div>
                                <div className='absolute -bottom-5 -left-5 z-0'>
                                    <img src={TEAM_CIRCLE_BG} alt="teamCircleBg" className='w-full h-full' />
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className="w-full 2xl:px-[240px] xl:px-[40px] px-[10px] py-[80px] relative overflow-hidden mt-20 text-white" style={{
                    backgroundImage: `url(${SECTION_BG})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                }}>
                    <div className='absolute bg-white/20 h-40 w-40 rounded-full bottom-[-65px] right-[-45px]' />
                    <div className='absolute bg-gradient-to-r from-white/30 transition-all animate-spin h-24 w-24 rounded-full bottom-8 right-8' />
                    <div className="absolute h-[200px] top-0 -left-10 -rotate-[85deg]">
                        <img src={SERVICE_TOP_BG} alt="servicesTopRightBG" className="w-full h-full" />
                    </div>
                    <div className="w-full flex  bg-white/10 backdrop-blur-sm my-10 rounded-lg overflow-hidden relative">
                        <div className="w-full p-10 flex flex-col justify-between gap-10">
                            <div className="text-white text-h3 md:text-h2 font-semibold">To join us, submit your CV here. Let's rock together!!</div>
                            <Link to="https://docs.google.com/forms/d/e/1FAIpQLSctw7Z9UN55D7jTxPHSpHNSGwIwSPoGfLAALbIYLQvTjjMVSA/viewform" target="_blank" className='w-fit px-3 py-2 bg-[#CCEBF8] text-primary1 hover:text-primary2 hover:bg-[#f8f4cc] rounded-md z-10 cursor-pointer duration-100'>Apply Now</Link>
                        </div>
                        <div className="w-full h-[300px] justify-end items-end -mr-[200px] overflow-hidden hidden md:flex">
                            <img src={APPLY_NOW} alt="apply_now" className="w-full h-full" />
                        </div>
                        <div className="absolute top-[30%] left-[50%] xl:left-[60%] hidden md:flex">
                            <img src={APPLY_ARROW} alt="apply_arrow" className="w-full h-full" />
                        </div>
                    </div>
                </div>

                <div className="w-full 2xl:px-[240px] xl:px-[40px] px-[10px] py-[80px] bg-white/50 backdrop-blur">
                    <InternshipHeader />
                </div>

                {blogList?.length > 0 && <>
                    <div className="flex flex-col justify-center items-center mt-20">
                        <div className="text-center text-h2 font-semibold mb-5">Latest Blog Post</div>
                        <div className="text-center w-full lg:w-[60%]">
                            Dive into the latest insights, trends, and innovations. Stay informed and inspired with our freshest blog content.
                        </div>
                    </div>

                    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-20 mb-10 w-full 2xl:px-[240px] xl:px-[40px] px-[10px]'>
                        {blogList?.slice(0, 3)?.map((blog, index) => {
                            return (
                                <div key={index} className='bg-white rounded-lg p-[10px] flex flex-col items-center relative group overflow-hidden hover:scale-110 duration-500'>
                                    <div>
                                        <img src={blog?.file} alt={blog?.title} className='w-full rounded-lg overflow-hidden' />
                                    </div>
                                    <div className='text-center my-4 text-h5 xl:text-h4 font-medium'>
                                        {truncateText(blog?.title, 50)}
                                    </div>

                                    <div className='w-full flex justify-between items-center'>
                                        <Link to={`/blog/${blog?.title}`} className='px-2 py-1 bg-[#CCEBF8] text-primary1 hover:text-primary2 hover:bg-[#f8f4cc] rounded-md z-10 cursor-pointer duration-500'>Read More...</Link>
                                        <div className=''>{timeCounter(blog)}</div>
                                    </div>

                                    <div className='absolute -bottom-5 -left-5 z-0'>
                                        <img src={TEAM_CIRCLE_BG} alt="teamCircleBg" className='w-full h-full' />
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                    <div className="w-full 2xl:px-[240px] xl:px-[40px] px-[10px] flex justify-center mb-20">
                        <Link to="/blog" className={`w-fit select-none rounded-lg py-2 px-3 truncate cursor-pointer bg-gradient-to-r from-primary1 to-[#5CE0FF] hover:from-primary2 hover:to-[#fffd98] text-center text-white hover:text-black`}>Find More...</Link>
                    </div>
                </>}
            </div> : <div className='text-h2 text-center'>
                No job opening at this time.
            </div>}
        </>
    );
};

export default CareerScreen;
