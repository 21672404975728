import React, { useEffect, useState } from 'react'
import Loading from '../../components/Loading'
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { TEAM_CIRCLE_BG } from '../../assets/images/imageData';

export interface IBlog {
    _id: string;
    customTitle: string;
    title: string;
    description: string;
    comments: any[];
    author: string;
    metaTags: string[];
    metaDescription: string;
    customUrl: string;
    file: string;
    cTime: Date | any;
}

export const truncateText = (text: string, maxLength: number) => {
    if (text.length > maxLength) {
        return text.slice(0, maxLength) + '...';
    }
    return text;
};

const BlogScreen = () => {
    const baseUrl = process.env.REACT_APP_API_ENDPOINT;
    const [blogList, setBlogList] = useState<IBlog[]>([]);
    const [loading, setLoading] = useState(true);

    const timeCounter = (data: IBlog): string => {
        const words =
            data.title.trim().split(/\s+/).length +
            data.author.trim().split(/\s+/).length +
            data.description.trim().split(/\s+/).length +
            data.metaDescription.trim().split(/\s+/).length +
            data.customTitle.trim().split(/\s+/).length;

        const minutesToRead = Math.ceil(words / 200);

        if (minutesToRead < 1) {
            const secondsToRead = Math.ceil((words / 200) * 60);
            return `${secondsToRead} second${secondsToRead === 1 ? '' : 's'} to read`;
        }

        return `${minutesToRead} minute${minutesToRead === 1 ? '' : 's'} to read`;
    };

    useEffect(() => {
        fetch(`${baseUrl}/blog/list`)
            .then((response) => {
                if (!response.ok) {
                    toast.error('Network response was not ok!')
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                setBlogList(data);
                setLoading(false);
            })
            .catch((error) => {
                toast.error('Something went wrong!')
                console.error('Error fetching data:', error);
                setLoading(false);
            });
    }, [baseUrl]);

    return (
        <div className='my-20'>
            {loading && <Loading />}
            <div className='flex flex-col justify-center items-center mb-[50px]'>
                <div className='text-center text-h2 font-semibold mb-5'>Explore Our Latest Blog Posts</div>
                <div className='text-center w-full lg:w-[50%]'>Discover a wealth of knowledge, insights, and updates on a wide range of topics. Dive into our blog posts to stay informed, inspired, and engaged with the latest trends and developments.</div>
            </div>
            {blogList?.length > 0 ? <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-20 mt-20 w-full 2xl:px-[240px] xl:px-[40px] px-[10px]'>
                {blogList?.map((blog, index) => {
                    return (
                        <div key={index} className='bg-white rounded-lg p-[10px] flex flex-col items-center relative group overflow-hidden hover:scale-110 duration-500'>
                            <div>
                                <img src={blog?.file} alt={blog?.title} className='w-full rounded-lg overflow-hidden' />
                            </div>
                            <div className='text-center my-4 text-h5 xl:text-h4 font-medium'>
                                {truncateText(blog?.title, 50)}
                            </div>

                            <div className='w-full flex justify-between items-center'>
                                <Link to={`/blog/${blog?._id}`} className='px-2 py-1 bg-[#CCEBF8] text-primary1 hover:text-primary2 hover:bg-[#f8f4cc] rounded-md z-10 cursor-pointer duration-500'>Read More...</Link>
                                <div className=''>{timeCounter(blog)}</div>
                            </div>

                            <div className='absolute -bottom-5 -left-5 z-0'>
                                <img src={TEAM_CIRCLE_BG} alt="teamCircleBg" className='w-full h-full' />
                            </div>
                        </div>
                    )
                })}
            </div> : <div className='text-h2 text-center'>
                There are no blogs available right now!
            </div>}
        </div>
    )
}

export default BlogScreen