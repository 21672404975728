import { useEffect, useState } from "react";
import WeTechVideoSection from "../../sections/WeTechVideoSection";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { toast } from "react-toastify";
import Loading from "../../components/Loading";
import { BsArrowRight } from "react-icons/bs";
import { truncateText } from "../Blog/BlogScreen";
import { Link } from "react-router-dom";
import YoutubeVideo from "../../components/YoutubeVideo";
import { clientList } from "../../assets/data/clientData";
import { REVIEW_YOUTUBE_BUTTON, SERVICE_TOP_BG, TEAM_CIRCLE_BG, TESTIMONIAL_BG } from '../../assets/images/imageData';
import SECTION_BG from '../../assets/images/extra/section-bg.svg';

export interface IServices {
    title: string;
    description: string;
    image: string;
    textColor: string;
    path: string;
}

export interface IReview {
    _id: string;
    name: string;
    designation: string;
    videoUrl: string;
    message: string;
    file: string;
}

const PortfolioScreen = () => {
    const baseUrl = process.env.REACT_APP_API_ENDPOINT;
    const [serviceList, setServiceList] = useState<IServices[] | null>(null);
    const [reviewList, setReviewList] = useState<IReview[] | null>(null);
    const [loading, setLoading] = useState(true);
    const [videoUrl, setVideoUrl] = useState<string | null>(null);

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 1400 },
            items: 4,
        },
        desktop: {
            breakpoint: { max: 1400, min: 1024 },
            items: 3,
        },
        tablet: {
            breakpoint: { max: 1024, min: 640 },
            items: 2,
        },
        mobile: {
            breakpoint: { max: 640, min: 0 },
            items: 1
        }
    };

    const responsive1 = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 1400 },
            items: 3,
        },
        desktop: {
            breakpoint: { max: 1400, min: 1024 },
            items: 3,
        },
        tablet: {
            breakpoint: { max: 1024, min: 640 },
            items: 2,
        },
        mobile: {
            breakpoint: { max: 640, min: 0 },
            items: 1
        }
    };

    const data = [
        { subData: [...clientList?.slice(0, 4)] },
        { subData: [...clientList?.slice(4, 8)] },
        { subData: [...clientList?.slice(8, 12)] },
        { subData: [...clientList?.slice(12, 16)] },
        { subData: [...clientList?.slice(16, 20)] },
        { subData: [...clientList?.slice(20, 24)] },
        { subData: [...clientList?.slice(24, 28)] },
        { subData: [...clientList?.slice(28, 32)] },
    ];

    useEffect(() => {
        if (baseUrl) {
            fetch(`${baseUrl}/section/list`)
                .then((response) => {
                    if (!response.ok) {
                        toast.error('Network response was not ok!')
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then((data) => {
                    const newList = data?.map((item: any) => {
                        return {
                            title: item?.name,
                            description: item?.description,
                            image: item?.file,
                            textColor: 'group-hover:text-primary1',
                            path: `/our-portfolio/${item?.customUrl}`,
                        }
                    })
                    setServiceList(newList);
                    setLoading(false);
                })
                .catch((error) => {
                    toast.error('Something went wrong!')
                    console.error('Error fetching data:', error);
                    setLoading(false);
                });

            fetch(`${baseUrl}/review/list`)
                .then((response) => {
                    if (!response.ok) {
                        toast.error('Network response was not ok!')
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then((data) => {
                    setReviewList(data);
                    setLoading(false);
                })
                .catch((error) => {
                    toast.error('Something went wrong!')
                    console.error('Error fetching data:', error);
                    setLoading(false);
                });
        }
    }, [baseUrl]);

    return (
        <div>
            {loading && <Loading />}
            <div className="flex flex-col justify-center items-center my-10">
                <div className="text-center text-h2 font-semibold mb-5">
                    Our Portfolio
                </div>
                <div className="text-center w-full lg:w-[60%]">
                    WeTech Digital is one of the fast growing digital marketing companies
                    that's handling various projects of many reputed organizations.
                    Established in 2020, we have continuously strived towards imparting
                    digital skills to clients. Go through the whole portfolio to learn
                    more about us!!!
                </div>
            </div>
            <div>
                <WeTechVideoSection videoUrl="https://youtu.be/IxJj5OzH8bM" />
            </div>
            <div className="w-full 2xl:px-[240px] xl:px-[40px] px-[10px] my-20">
                <div className="flex flex-col justify-center items-center my-14">
                    <div className="text-center text-h2 font-semibold mb-5">
                        We’ve Served 327+ Clients
                    </div>
                    <div className="text-center w-full lg:w-[60%]">
                        Discover digital success stories with WeTechDigital Marketing Agency
                        Bangladesh. From startups to established brands, our strategies
                        drive growth, elevate brands, and achieve excellence. Join us on a
                        success-driven digital journey.
                    </div>
                </div>
                <Carousel
                    swipeable={false}
                    draggable={true}
                    showDots={false}
                    responsive={responsive}
                    ssr={true}
                    arrows={false}
                    infinite={true}
                    autoPlay={true}
                    autoPlaySpeed={3000}
                    keyBoardControl={true}
                    customTransition="all .5s ease"
                    transitionDuration={500}
                >
                    {data?.map((item, ind) => {
                        return (
                            <div
                                key={ind}
                                className="flex flex-col gap-10"
                            >
                                {item?.subData?.map((image, index) => {
                                    return (
                                        <div key={index} className="bg-white rounded-lg p-4 flex items-center justify-center mx-5 h-[120px] overflow-hidden object-contain relative">
                                            <img src={image} alt={"image " + index} key={index} className="object-contain" />
                                            <div className='absolute -bottom-5 -left-5 z-0'>
                                                <img src={TEAM_CIRCLE_BG} alt="teamCircleBg" className='w-full h-[100px]' />
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        );
                    })}
                </Carousel>
            </div>
            <div className="w-full 2xl:px-[240px] xl:px-[40px] px-[10px] py-[80px] relative overflow-hidden" style={{
                backgroundImage: `url(${SECTION_BG})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
            }}>

                <div className='absolute bg-white/20 h-60 w-60 rounded-full bottom-[-65px] right-[-45px]' />
                <div className='absolute bg-gradient-to-r from-white/30 transition-all animate-spin h-32 w-32 rounded-full bottom-20 right-20' />
                <div className="absolute top-0 right-0">
                    <img src={SERVICE_TOP_BG} alt="servicesTopRightBG" className="w-full h-full" />
                </div>
                <div>
                    <div className="text-white text-center text-h3 md:text-h2 lg:text-h1 mb-[50px]">What We Bring to You</div>
                    <div className={`w-full grid grid-cols-1 sm:grid-cols-2 gap-8 sm:gap-10 md:gap-12 lg:gap-14 xl:gap-16`}>
                        {serviceList?.map((service, index) => {
                            return (
                                <div key={index} className="group bg-white/20 backdrop-blur-sm flex flex-col xl:flex-row gap-4 text-white rounded-lg overflow-hidden p-3 min-h-[150px] hover:bg-white hover:text-black duration-500">
                                    <div className="h-auto w-[250px] object-cover overflow-hidden rounded-xl bg-white group-hover:bg-[#e6e7e8]">
                                        <img src={service?.image} alt={service?.title} className="w-full h-full object-cover" />
                                    </div>
                                    <div className="flex flex-col gap-3 justify-between w-full">
                                        <div className="flex flex-col gap-5">
                                            <div className="text-4 lg:text-h3">{service?.title}</div>
                                            <div className="group-hover:animate-expandWidth bg-[#afaeae] h-[2px] w-[50%] group-hover:w-full transition-width duration-1000" />
                                            <div className="text-justify pr-3">{truncateText(service?.description, 100)}</div>
                                        </div>
                                        <div className="flex justify-end">
                                            <Link to={service?.path} className="flex gap-3 items-center text-primary1 hover:text-primary2 underline cursor-pointer text-h5 font-semibold">Explore <BsArrowRight /></Link>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>


            </div>
            {reviewList && <div className='w-full 2xl:px-[240px] xl:px-[40px] px-[10px] overflow-hidden my-20 relative'>
                <div className="hidden xl:flex absolute bottom-0 right-0 h-[350px]">
                    <img src={TESTIMONIAL_BG} alt="Testimonial BG" className="w-full h-full" />
                </div>
                <div className="flex flex-col justify-center items-center my-14">
                    <div className="text-center text-h2 font-semibold mb-5">
                        Reviews
                    </div>
                    <div className="text-center w-full lg:w-[60%]">
                        What our customers are saying
                    </div>
                </div>
                <Carousel
                    swipeable={false}
                    draggable={true}
                    showDots={false}
                    responsive={responsive1}
                    ssr={true}
                    arrows={false}
                    infinite={true}
                    autoPlay={true}
                    autoPlaySpeed={3500}
                    keyBoardControl={true}
                    customTransition="all .5s ease"
                    transitionDuration={500}
                >

                    {reviewList?.map((item, index) => {
                        return (
                            <div key={index} className='bg-white text-black backdrop-blur-sm p-4 m-5 rounded-lg flex flex-col gap-4'>
                                <div className="flex gap-4 items-center">
                                    <div className="h-[80px] w-[80px] rounded-full overflow-hidden bg-white">
                                        <img src={item?.file} alt={item?.name} className="h-full w-full object-fill" />
                                    </div>
                                    <div className="flex flex-col justify-between">
                                        <div className="text-[18px] font-semibold">{item?.name}</div>
                                        <div className="text-sm">{item?.designation}</div>
                                    </div>
                                </div>
                                <div>{truncateText(item?.message, 130)}</div>
                                <div className="flex justify-end">
                                    <div onClick={() => setVideoUrl(item?.videoUrl)} className="py-2 px-4 bg-[#f1f1f1] rounded-md cursor-pointer"><img src={REVIEW_YOUTUBE_BUTTON} alt="reviewYoutube" className="w-[30px] h-[30px]" /></div>
                                </div>
                            </div>
                        )
                    })}

                </Carousel>
            </div>}
            {videoUrl && <YoutubeVideo videoUrl={videoUrl} onClose={() => setVideoUrl(null)} />}
        </div>
    );
};

export default PortfolioScreen;
