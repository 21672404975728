import React, { useRef } from 'react'
import ReactPlayer from 'react-player'
import { IoMdCloseCircle } from 'react-icons/io';
import { useOnClickOutside } from 'usehooks-ts';

const YoutubeVideo = ({ videoUrl, onClose }: { videoUrl: string, onClose: () => void }) => {
    const dropDownRef = useRef<any>();

    useOnClickOutside(dropDownRef, () => onClose());

    return (
        <div className={`fixed top-0 bottom-0 left-0 right-0 w-[100vw] h-[100vh] flex justify-center items-center bg-white/20 backdrop-blur-sm`}>
            <div ref={dropDownRef} className='w-full lg:w-[50%] rounded-xl overflow-hidden'>
                <ReactPlayer
                    url={videoUrl}
                    width="100%"
                    height="450px"
                />
            </div>
            <div className='fixed top-10 right-10 text-red-500 hover:scale-150 duration-500 cursor-pointer'><IoMdCloseCircle size={32} /></div>
        </div>
    )
}

export default YoutubeVideo