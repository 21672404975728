import { BRANDING, BRANDING_1, BRANDING_10, BRANDING_11, BRANDING_12, BRANDING_13, BRANDING_2, BRANDING_3, BRANDING_4, BRANDING_5, BRANDING_6, BRANDING_7, BRANDING_8, BRANDING_9, DESIGN, DESIGN_1, DESIGN_10, DESIGN_2, DESIGN_3, DESIGN_4, DESIGN_5, DESIGN_6, DESIGN_7, DESIGN_8, DESIGN_9, GMAIL, LOCATION, PHONE, PROPOSITION, RESEARCH, RESEARCH_1, RESEARCH_2, RESEARCH_3, RESEARCH_4, RESEARCH_5, SEO, SEO_1, SEO_10, SEO_11, SEO_12, SEO_13, SEO_14, SEO_15, SEO_2, SEO_3, SEO_4, SEO_5, SEO_6, SEO_7, SEO_8, SEO_9, SERVICE_1, SERVICE_2, SERVICE_3, SERVICE_4, SERVICE_5, SOCIAL, SOCIAL_1, SOCIAL_10, SOCIAL_11, SOCIAL_12, SOCIAL_13, SOCIAL_14, SOCIAL_15, SOCIAL_2, SOCIAL_3, SOCIAL_4, SOCIAL_5, SOCIAL_6, SOCIAL_7, SOCIAL_8, SOCIAL_9, WHY } from "../images/imageData";

export interface IServiceDetails {
    title: string;
    description: string;
    url: string;
    contents:
    {
        title: string;
        description: string;
        image: string;
        url: string;
        data: {
            title: string;
            description: string;
        }[];
    }[];

}

export const serviceDetailsData: IServiceDetails[] = [
    {
        title: 'Organic Search Pricing',
        description: "Affordable and Effective Organic Search Pricing Plans Tailored to Boost Your Online Visibility and Growth!",
        url: 'organic-search-pricing',
        contents: [
            {
                title: 'SEO Service',
                description: 'We ensure High-ranked Search Engine Optimization with top-quality service. Let’s Dig into WeTech Digital insights and learn how to get the most out of your search engine marketing efforts.',
                image: SEO_1,
                url: 'seo-service',
                data: [
                    {
                        title: 'What Defines Us As The Top SEO Service Agency?',
                        description: 'WeTech Digital listens to your strategic goals, learns about your marketing strategy, marketplace, and competitive environment, and then uses that information to build a customized plan that takes you where you need to go and beyond. One of the most important considerations when selecting an SEO organization is how they assess campaign success. While some SEO firms use the same standardized reports all over every account, WeTech Digital creates personalized reports based on the KPIs that are most relevant to your company.',
                    },
                    {
                        title: 'Our Approach to SEO Service',
                        description: 'We specify the keywords with both the highest search volume and verify that your website ranks for all those aspects. We also analyze search queries to identify specific information people have about the products and services you provide. We will drive more traffic to websites by optimizing these search queries and ensuring your content contains the information your audience is looking for.',
                    },
                ]
            },
            {
                title: 'E-Commerce SEO',
                description: 'Taking your store online e-commerce solutions that work for your business and SEO is one of the most cost-effective and efficient strategies to expand your e-commerce shop and increase sales.',
                image: SEO_2,
                url: 'e-commerce-seo',
                data: [
                    {
                        title: 'What Defines Us As The Top E-Commerce SEO Agency?',
                        description: "WeTech Digital offers dedicated developers and programmers to support your team. Top companies and start-ups choose us to provide the highest quality stress-free e-commerce service & solutions, including SEO service. Our special committee of highly qualified and trained developers is committed to ensuring that your requirements are met. With 100% project delivery success, we deliver E-commerce SEO services to our clients to boost their e-commerce store's rankings.",
                    },
                    {
                        title: 'Our Approach to E-Commerce SEO',
                        description: "Our E-Commerce SEO services are based on extensive analysis and followed some steps with quality levels based on prices. We help you to increase your online orders. Your website will rank better in search results for your target audience's terms if you employ our e-commerce SEO services. As an e-commerce SEO company, we provide the best E-commerce SEO consultant to you. They work with search volume, CPC, SEO difficulty, etc., to get the best result. The far more essential phrases you can effectively target, the more qualified customers will click on your items, and as a result, your conversions and income will rise. We believe in providing the best service inside the local and global markets. Always care about your service to be better with our special effort and ensure that you'll get precisely what you want.",
                    },
                ]
            },
            {
                title: "Local SEO",
                description: "Do you need the greatest option to help your small company flourish in your neighborhood? Our SEO Experts know how your company will get more focus locally!",
                image: SEO_3,
                url: 'local-seo',
                data: [
                    {
                        title: "What Defines Us As The Top SEO Service Agency?",
                        description: "WeTech Digital listens to your strategic goals, learns about your marketing strategy, marketplace, and competitive environment, and then uses that information to build a customized plan that takes you where you need to go and beyond. One of the most important considerations when selecting an SEO organization is how they assess campaign success. While some SEO firms use the same standardized reports all over every account, WeTech Digital creates personalized reports based on the KPIs that are most relevant to your company.",
                    },
                    {
                        title: "Our Approach to Local SEO Services",
                        description: "We specify the keywords with both the highest search volume and verify that your website ranks for all those aspects. We also analyze search queries to identify specific information people have about the products and services you provide. We will drive more traffic to websites by optimizing these search queries and ensuring your content contains the information your audience is looking for.",
                    },
                ]
            },
            {
                title: "SEO Audit",
                description: "We assist you in identifying your core SEO concerns and developing a long-term approach to manage to beat your competitors.",
                image: SEO_4,
                url: 'seo-audit',
                data: [
                    {
                        title: "What Defines Us As The Top SEO Audit Agency?",
                        description: "WeTech Digital listens to your strategic goals, learns about your marketing strategy, marketplace, and competitive environment, and then uses that information to build a customized plan that takes you where you need to go and beyond. One of the most important considerations when selecting an SEO organization is how they assess campaign success. While some SEO firms use the same standardized reports all over every account, WeTech Digital creates personalized reports based on the KPIs that are most relevant to your company.",
                    },
                    {
                        title: "Our Approach to SEO Service",
                        description: "We specify the keywords with both the highest search volume and verify that your website ranks for all those aspects. We also analyze search queries to identify specific information people have about the products and services you provide. We will drive more traffic to websites by optimizing these search queries and ensuring your content contains the information your audience is looking for.",
                    },
                ]
            },
            {
                title: "Link Building Service",
                description: "Would you like to link your website back to any other websites? Because connections signal to Google that the page is a reliable site worthy of reference, building links is one of the numerous methods employed in search engine optimization (SEO). So, recognize your site as a trustworthy link building. Services are needed. We are here to take care of link building services for you. You can approach to us for getting best link building services.",
                image: SEO_5,
                url: 'link-building-service',
                data: [
                    {
                        title: "What Defines Us As The Top Link Building Service Company?",
                        description: "Even if you produce the finest material on a specific topic, Google may not give it a high ranking. In reality, they rarely do. To attract Google traffic these days, you need a lot more than simply content. So, what does it take to win? You'll need high-quality backlinks to your material sooner or later. WeTech Digital works for you as a middler to get you back-links. For good link building, you need to build good relationships; Wetech Digital does that for you. There are plenty of opportunities to make new contacts. Our experts start with niche-related communities: forums, blogs, or social groups. In addition, we offer backlink service. We have a large supply of backlink sites.",
                    },
                    {
                        title: "Our Approach to Link Building Services",
                        description: "WeTech Digital provides personalized and innovative services to improve the SEO performance of your website by constructing high-quality backlinks. We have best seo backlink services. SEO link building services, we provide can beat other company. Our provided backlink services are one of the best backlink services. To deliver high quality backlinks, we follow some strategies like Linkable online advertising, columnist interaction, visitor posting, strategic link insertion and curated in-article backlinks, all examples of linkable content marketing, etc., based on your SEO strategy company goals by our affordable link building services. The most important element of the world of SEO is a TOOL backlink generator for Organic Traffic. A method of attracting visitors to a website using (SEO) tactics such as appropriate linking to other relevant material on the Internet is White hat link building. Wetech Digital serves best White hat link building services for outstanding results. However, our company is a high class link building services company.",
                    },
                ]
            },
            {
                title: "App Store Optimization",
                description: "Want to Increase Visibility Of your App on Google Display & App Store?",
                image: SEO_6,
                url: 'app-store-optimization',
                data: [
                    {
                        title: "What Defines Us As The Top ASO Service Provider?",
                        description: "Our approach is based on a fundamental fact about app store optimization: It is critical to managing your app's credibility! A positive app reputation might boost your conversion rate. We can assist in the following ways: Recognize problems in your user evaluations that necessarily involve fast action, Display information & feedback quickly convert low reviews into good ones. We have a different and proven algorithm to increase your Apps visibility which will engage potential users and encourage conversions.",
                    },
                    {
                        title: "Our Approach to App Store Optimization",
                        description: "Our expert team knows better the procedure of marketing a game or program to optimize the presence across stores in Explore (when users search) and Discover (when users browse), create visibility to listing, and enhance profit margin in order to produce the highest percentage of organic installations. Organic installs are more important because they are based on people's explicit desire to find and install a specific program to fill a specific need, which is not the case when they are exposed to an ad. It's a higher-quality customer experience from the start, and we promise to give the best service possible through our top-rated professionals.",
                    },
                ]
            },
        ]
    },
    {
        title: 'Paid Marketing Pricing',
        description: "Effective Paid Marketing Solutions Tailored to Your Business Needs – Unlock Growth and Visibility Today!",
        url: 'paid-marketing-pricing',
        contents: [
            {
                title: "PPC Advertising",
                description: "Did you search for a result-driven PPC advertising agency for your business? We have the best solutions for you.",
                image: SEO_7,
                url: "ppc-advertising",
                data: [
                    {
                        title: "What Defines Us As The Top PPC Campaign Agency?",
                        description: "WeTech Digital is the top PPC advertising company. We are not efficient unless you achieve your desired success, and that's why your company is our highest concern. Our experts are dedicated to helping your company grow and thrive. There are many PPC advertising companies out there in the market. They follow some methods, like Google PPC campaigns. Google Ads is useful only if your advertising gets real visitors. Even then, Google ads PPC are most used. While some Google PPC advertising campaign services use the same standardized reports for every account, WeTech Digital creates personalized reports based on the KPIs that are most relevant to your company. As a PPC advertising company, our PPC experts are among the finest in the global industry, with a demonstrated history of working to grow tons of successful companies.",
                    },
                    {
                        title: "Our Approach to Google PPC Ads Service",
                        description: "We specify each of the research carefully and verify which campaign is going to cover your companies' demand. We also analyze search queries to identify specific information people have about the products and services you provide. We will drive more traffic to websites by optimizing these search queries and ensuring your content contains the information your audience is looking for. Sometimes, we also arrange Google PPC campaigns if required or our customers demand it. In Google PPC ads campaign we offer display, video, product listing, automated ads etc. Our approaches make WeTech Digital one of the best PPC advertising companies. Our PPC advertising services are very famous in the market. As a Google PPC agency, our Google ads PPC are most effective.",
                    },
                ]
            },
            {
                title: "e-commerce PPC management",
                description: "Taking your store online & helping to build practical e-commerce PPC solutions that work for your business. Grab our e-commerce PPC services.",
                image: SEO_8,
                url: "e-commerce-ppc-management",
                data: [
                    {
                        title: "What Defines Us As The Top E-commerce PPC Management Agency?",
                        description: "WeTech Digital is the top e-commerce PPC management service provider. We offer dedicated developers and programmers to support your team. Top companies and start-ups choose us to provide the highest quality, stress-free e-commerce PPC management services and solutions. Our dedicated team of highly trained and experienced developers is devoted to meeting your needs in the modern world across a broad range of offerings, providing expert know-how and expertise with 100% project delivery success. Our provided e-commerce PPC services are very popular. As an e-commerce PPC agency, Wetech Digital offers PPC marketing services. Wetech Digital, a PPC service provider, also offers local PPC management with many types of PPC management packages. If you are looking for an e-commerce PPC Management Company, then we are perfect to serve you.",
                    },
                    {
                        title: "Our Approach to E-commerce PPC Management",
                        description: "We are very popular e-commerce PPC agency. Our e-Commerce development solutions are based on extensive analysis and data-driven techniques that address various aspects of e-commerce and its elements. We don't make powerful or magic stuff, although, with our e-commerce PPC services’ help, you can manage much more. Learn about our innovative designers, skilled developers, and customer-focused project managers who can provide you with the best PPC marketing services possible. We believe in providing the best service in the local and global markets, as we want to be known as the best PPC service provider. Our local PPC management is very trustworthy to our home customers. So always care about your service that can be better with our special effort and ensure that you’ll get exactly what you want.",
                    },
                ]
            },
            {
                title: "Google Display Ads",
                description: "Do you want to meet too many local leads, maximize traffic flow, and boost maximum conversions for your company? Then you are in the right place to get Google Display Ads.",
                image: SEO_9,
                url: "google-display-ads",
                data: [
                    {
                        title: "What Defines Us As Best Google Display Ads Service Provider??",
                        description: "WeTech Digital is the top Google responsive display ads agency. Responsive display ads are ads that automatically adjust their size, appearance, and format to fit available ad spaces on the Google Display Network. Responsive display ads Google are well known. Google responsive display Ads help you measure the way your goals are met. See which websites are running your announcement, which advertisements are the most popular and which websites provide the lowest value. They also optimize your inputs automatically for more conversions. Broader reach with customizable dimensions, which helps you save time. Our expert team knows what it takes to build an effective Google advertisement campaign for their customers. We continuously develop premium and scalable strategic ads. It is not a good job to run Google Ads without a historical and strategic review. We carefully evaluate your business, market, and major participants. We always use world-class tools and software to review and analyze the competitor’s ads' to compete with them to add more value for your company.",
                    },
                    {
                        title: "Our Approach to Google Display Ads",
                        description: "The service of Google display advertising offered by WeTech Digital is very high-notch. Every year, we handle millions of dollars in Google Ads campaign spend, increasing search ad conversions by more than 320%. We operate your Google Ads (previously Google AdWords) account, focusing on the keywords which will bring customers to your website. Precisely focused keyword research will explore one of the most expensive strategies for attracting consumers looking for exactly what you have to sell. Continued investment in Google Ads would certainly assist highly motivated buyers in discovering your website that may not have found it otherwise by traditional (organic) search engine optimization techniques. Our mission is to transform every dollar you spend into several dollars of profit, resulting in a long-lasting and profitable initiative. Our display ads management is very effective and good.",
                    },
                ]
            },
            {
                title: "Local Search Ads Management",
                description: "Do you want to enhance local leads, traffic volume, and conversions for your company? If that's the case, Google Local Services advertisements are a wise investment, and WeTech Digital makes ad administration a breeze. We have the best solutions for you.",
                image: SEO_10,
                url: "local-search-ads-management",
                data: [
                    {
                        title: "What Defines Us As The Top Local search Ads Management Agency?",
                        description: "Local search advertising is a method for companies to be highlighted and stand out in Google's local results area. When a person searches for a specific service near them, they may find adverts that include your address, phone number, hours of operation, and ratings. Connecting with local search ads management, you will make contact with people in your area who are most likely to use your services; with Google Guarantee, you can build trust, Pay just for leads who are interested in your products and services. We arrange local search ads for Google which is a part of the local results section of a Google search. Google local ads services appear on user screens near their location. Google local search ads can also show your information as an advertisement in your locality. We ensure you give perfect local search ads management. Local ads personal can improve your business personality.",
                    },
                    {
                        title: "Our Approach to Local Search Ads",
                        description: "Management Service, we specify each of the research carefully and verify which campaign is going to cover your companies demand. We work on voice search optimization, budget management, review information of request, custom reporting. We have dedicated local marketing experts who focus on geographic targeting. Also, we are concerned with home depot local ads, which send emails about special promotions, offers, how to design & project ideas. Google realized this fact and began offering a solution for small companies in 2018 called Local Services Ads to try to fair competition. Local Services Ads are promoted business ads that appear in search results for service-related inquiries. We arrange local service ads by Google for you. We ensure that the best Google ads agency is collaborating with a local ad agency for your need. There seems to be no lowest possible expenditure on Google Ads, however, daily expenditure of $2 or $5 is almost hard to gain from. But still WeTech Digital makes a compromise Google ads agency price for you.",
                    },
                ]
            },
        ]
    },
    {
        title: 'Market Place Pricing',
        description: "Affordable and Flexible Marketplace Pricing Plans Tailored to Your Business Needs and Budget!",
        url: 'market-place-pricing',
        contents: [
            {
                title: "Amazon SEO & Product Optimization",
                description: "Do you want to optimize your product listing so that it appears near the top of Amazon search results? Then we are here to please you providing Amazon optimization services professionally.",
                image: SEO_11,
                url: "amazon-seo-product-optimization",
                data: [
                    {
                        title: "What Defines Us As The Top Amazon SEO Services & Product Optimization Agency?",
                        description: "WeTech Digital is a top Amazon SEO business that provides results-driven Amazon SEO services that include industry-leading tools and cutting-edge product listing optimization techniques to boost sales, online exposure, and market domination. When it comes to looking for a new product to buy, around half of all customers go to Amazon, which accounts for more than 40% of all online sales. We help your business and your items become the preferred choice of customers all around the world with our performance-driven Amazon SEO services. This leads to more sales, income, and growth for your company, allowing you to accomplish more with it. As an Amazon SEO agency, our services towards listing products optimization and most importantly at SEO services are very well known.",
                    },
                    {
                        title: "Our Approach to Amazon SEO & Product Optimization Service",
                        description: "With Amazon's highly competitive search queries and the growing dominance of Amazon listings in normal search results, it's becoming increasingly important for businesses to hire an Amazon SEO firm. WeTech Digital provides specialist Amazon SEO services to help your company not only sustain but also expand its sales statistics, implying that you're moving more goods regularly. Our Amazon SEO packages and Amazon listing optimization services are much affordable.",
                    },
                ]
            },
            {
                title: "Amazon Advertising Management",
                description: "WeTech Digital's Amazon PPC management services may help you advertise on Amazon. We'll help your company decrease its Advertising Cost of Sale while boosting sales and market share with our bespoke and data-driven tactics.",
                image: SEO_12,
                url: "amazon-advertising-management",
                data: [
                    {
                        title: "What Defines Us As The Top Amazon Advertising Management Agency?",
                        description: "WeTech Digital is the best Amazon marketing agency in the field. Playing on Amazon needs more than just an aggressive SEO approach; it also necessitates a competitive pay-per-click (PPC) program. By requesting a proposal now, you can start earning more money from Amazon advertising, such as Sponsored Products, with our Amazon PPC services, which include frequent reporting, a dedicated account manager, and proactive bid management. Amazon marketing campaigns can give the prices the hit. As an Amazon marketing agency, you can completely trust us.",
                    },
                    {
                        title: "Our Approach to Amazon Advertising Management Service",
                        description: "WeTech Digital is a popular choice for firms selling and wanting to expand on Amazon, with over 650 marketing campaigns executed and more than 20 years of expertise. Almost 10% of Amazon clicks now go to sponsored advertisements, indicating that if you're not promoting them, you're not making as much money as you might on the world's largest retail platform. We can assist you in maximizing your profits. WeTech Digital has an expert Amazon advertising agency team who can help you out.",
                    },
                ]
            },
            {
                title: "Facebook Marketplace for Business",
                description: "Want to sell more items by diversifying your online sales platforms? WeTech Digital can assist you in generating income on the Facebook Marketplace for Business, which provides you with access to over 800 million shoppers worldwide.",
                image: SEO_13,
                url: "facebook-marketplace-for-business",
                data: [
                    {
                        title: "What Defines Us As The Top Facebook Marketplace for Business Service Company?",
                        description: "WeTech Digital is the top Facebook marketplace services agency in the market. Facebook Marketplace, which is used monthly by over 800 million people in 70 countries, allows users to buy and sell goods such as furniture, clothes, and even vehicles. Facebook has begun to roll out Marketplace for Business, which is similar to how individual vendors utilize the site. Facebook's Marketplace for Business is a fantastic option for firms wanting to increase product sales online. Facebook marketplace services help you to advertise your business.",
                    },
                    {
                        title: "Our Approach to Facebook Marketplace for Business",
                        description: "The Facebook Marketplace is a platform where individuals can find, buy, and sell things. People may look through ads, search for goods for sale in their region, and look for products that can be delivered. Facebook marketplace for business pages is recently very popular as most people are active in this social platform. Facebook has launched Marketplace, a platform for buying and selling things in your local area. Individual vendors have typically utilized Facebook Marketplace. However, Facebook just launched Marketplace for Business, a new service aimed at assisting businesses in increasing their online sales.",
                    },
                ]
            },
            {
                title: "Shopify Optimization Services",
                description: "Do you want to get Shopify Optimization Services? Then we are here to please you providing that professionally.",
                image: SEO_14,
                url: "shopify-optimization-services",
                data: [
                    {
                        title: "What Defines Us As The Top Shopify SEO Services Company?",
                        description: "There are many Shopify SEO agencies out there. Our full-service Shopify optimization strategy assists mid-size and enterprise-level Shopify businesses in achieving their sales objectives. Our staff works hard to optimize your Shopify store's setup, change your theme, set up conversion tracking, and improve your store's Google ranking. We've worked in e-commerce for decades and have created new Shopify sites as well as improved old ones. We make the effort to learn about your business objectives and plans with you to create marketing strategies that will help you reach your sales, lead, or phone call goals. We also make the effort to learn about your business objectives and plans with you to create marketing strategies that will help you reach your sales, lead, or phone call goals. Our Shopify optimization services take a comprehensive marketing strategy, enhancing both the back-end and front-end of your site to help you boost traffic, improve user experience, and save time.",
                    },
                    {
                        title: "Our Approach to Shopify Optimization Services",
                        description: "WeTech Digital works with mid-sized and enterprise-level companies that utilize Shopify to fuel their online sales. Our team works with customers to improve their Shopify store's setup and increase income through SEO. Shopify SEO services give you the milestone you needed. As a Shopify SEO services agency, we served you with our Shopify SEO experts who give you the Shopify speed optimization service. Among Shopify optimization agencies, WeTech Digital is well known. When you collaborate with WeTech Digital, you're partnering with a team with a track record of e-commerce success. We've experimented with a variety of e-commerce systems, but Shopify connections and ease of use are our favorites.",
                    },
                ]
            },
            {
                title: "Shopify Advertising Agency",
                description: "Do you want Shopify advertising management services? Then we are here to please you by providing that professionally. Our Shopify advertising agency is famous in the market.",
                image: SEO_15,
                url: "shopify-advertising-agency",
                data: [
                    {
                        title: "What Defines Us As The Top Shopify Advertising ManagementCompany?",
                        description: "WeTech Digital is the top Shopify advertising agency in the market. Shopify is one of the most popular e-commerce platforms for a reason. It's easy to use while being flexible and it includes a lot of useful features. With over 1.7 million merchants using the platform, Shopify is a popular choice for businesses in a range of industries. Shopify is designed to be easy to use. Because it's a hosted platform, getting started is a lot easier. It also has a user-friendly interface and a choice of styles, making setting up a basic e-commerce site a breeze. We provide best Shopify advertising. Our Shopify Advertising management service is well known. We know that there are many agencies but you can believe us as the best Shopify advertising agency. For our focused management with Shopify, we are introducing ourselves as the best Shopify advertising management agency.",
                    },
                    {
                        title: "Our Approach to Shopify Advertising Management Services",
                        description: "WeTech Digital is dedicated to providing clients with interesting, relevant, and effective content as we are a famous and reliable Shopify advertising agency. Advertising management is a planned administrative process for overseeing and controlling the different advertising activities included in a programme to connect with a company's target market, with the goal of influencing consumer purchase choices. Working on that purpose, our Shopify advertising management services are most reliable with affordable Shopify advertising costs. Shopify advertising costs are normally high but we try to decrease it as much as we can. As a Shopify store development services company, we offer a wide range of Shopify advertising management. We also arrange a Shopify advertising campaign for you. Shopify advertising campaign is included with Shopify marketing campaign. Our Shopify marketing campaign will help you to spread your name and details. It is very effective to participate in this Shopify marketing campaign. All of our services lead you to get the best Shopify advertising.",
                    },
                ]
            },
        ]
    },
    {
        title: "Social Media Pricing",
        description: "Affordable Social Media Pricing Plans Tailored to Boost Your Online Presence and Engagement.",
        url: "social-media-pricing",
        contents: [
            {
                title: "Social Media Management Service",
                description: "Do you want well to build up social media marketing & management services? Then we are here to please you providing that professionally.",
                image: SOCIAL_1,
                url: "social-media-management-service",
                data: [
                    {
                        title: "What Defines Us As The Top Social Media Marketing & Management Service Company?",
                        description: "WeTech Digital is the top social media management company in the market, providing affordable social media management services. Your company can start increasing brand recognition and earning income from social media sites like Facebook, LinkedIn, Instagram, and others with a tailored social media marketing service package. When it comes to making a purchasing choice, over 75% of consumers utilize social media. Companies engage in professional social media services because it is a crucial touchpoint for shoppers in the business-to-consumer (B2C) and business-to-business (B2B) industries. WeTech Digital is a reputable provider of social media marketing services, with a customer recommendation score that is 488 percent higher than the industry average. We'll work with you to increase brand exposure, customer loyalty, income, and more as your partner. You can choose us as a trustable social media management agency.",
                    },
                    {
                        title: "Our Approach to Social Media Management Service",
                        description: "Browse our service bundles and rates below to see how (and why) our social media management services are the best. You may also use our free, user-friendly calculator to create a company estimate. WeTech Digital is best in social media management services pricing. Because no two businesses are similar, we provide customized social media management services. For this, the cost of social media management services varies. Social Media Management Company pricing is also different in this case of differences. We are also a reliable social media management company for small businesses. WeTech Digital is one of the top social media management companies out there.",
                    },
                ]
            },
            {
                title: "Social Media Design",
                description: "Are you looking for best social media design service? High-quality content and consistent branding are the cornerstones of effective social media marketing. Because social media is a visual channel for your business, neither is feasible without good design work. WeTech Digital has affordable social media design package.",
                image: SOCIAL_2,
                url: "social-media-design",
                data: [
                    {
                        title: "What Defines Us As The Top social media design Agency?",
                        description: "WeTech Digital, as one of the country's top social media design agency, understands the value of a well-designed Facebook Page, Twitter profile, Google+ header and more. Our expert social media designers can create something sophisticated and impressive, whether it's an appealing and fully integrated corporate blog or a simple symbol to represent yourself on a social networking site, all while ensuring your brand requirements are followed. Our offered social media design package price is very budget-friendly.",
                    },
                    {
                        title: "Our Approach to Social Media Design",
                        description: "Our social media service may assist you with establishing a presence on a variety of social media platforms such as blogs, Facebook, and Twitter. Social media has enormous influence, and online channels have evolved into dynamic platforms promoting and marketing companies, services, and ideas. Individuals or businesses generate social media content for social media platforms such as Facebook, Instagram, and Twitter. Companies are particularly interested in these platforms because they allow for far more direct connection with users than traditional marketing methods. WeTech Digital provides best social media content services.",
                    },
                ]
            },
            {
                title: "Social Media Advertising",
                description: "Are you looking for the best social media advertising packages? WeTech Digital is a prominent social media marketing agency that specializes in using social media to help businesses develop. Our social media advertising services may help you achieve your objectives, whether you want to improve your brand's social media presence or create targeted leads through social media advertising.",
                image: SOCIAL_3,
                url: "social-media-advertising",
                data: [
                    {
                        title: "What Defines Us As The Top social media advertising Agency?",
                        description: "WeTech Digital is the country's top social media advertising agency. For businesses across sectors, social media advertising services have become an essential element of their social strategy. With social advertising, businesses may diversify methods to target the appropriate individuals at the right time as algorithms evolve. Each social marketing campaign has its manner of contacting consumers and its own set of benefits. As a consequence, nearly every organization may profit from using social advertising to involve, inform, and persuade their potential customers. We have different kinds of social media marketing packages. We also offer monthly social media marketing packages. WeTech Digital is also shared social media marketing packages for small businesses.",
                    },
                    {
                        title: "Our Approach to social media advertising",
                        description: "Our social media advertising service may assist you with establishing a presence on a variety of social media platforms such as Facebook, Instagram, Twitter, LinkedIn, YouTube, and Pinterest. e-commerce, Enterprise & D2C social media advertising are also provided. Social media marketing agency pricing is different. There are many kinds of social media advertising prices. WeTech Digital provides affordable social media marketing packages.",
                    },
                ]
            },
            {
                title: "e-commerce Social Advertising",
                description: "Do you want e-commerce Social Advertising Services? Then we're here to make you happy by offering that service in a professional manner. It is very essential for your business success.",
                image: SOCIAL_4,
                url: "e-commerce-social-advertising",
                data: [
                    {
                        title: "What Defines Us As The Top e-commerce Social Media Advertising Services Company?",
                        description: "WeTech Digital is the top e-commerce social media advertising agency in the market. You can also call it an e-commerce digital marketing agency. Paid advertising on sites like Facebook, Twitter, Instagram, Linkedln, YouTube, and others is used for e-commerce social media advertising. Remunerated social advertising extends your influence on social networks beyond your organic social media approach. If the processes above seem overwhelming, we recommend collaborating with e-commerce advertising professionals, such as our team at WeTech Digital. An expert e-commerce social media advertising firm can guide you through each stage of the process of creating ads that provide results for your company. As an e-commerce social media marketing agency, we provide our service professionals.",
                    },
                    {
                        title: "Our Approach to e-commerce Social Advertising Services.",
                        description: "Nowadays social media advertising agency for e-commerce plays an important role. Exactly did you notice that 74 percent of the total of consumers make purchasing decisions based on social media? As a result, the social media advertisements on your e-commerce site can have a direct influx directly influence on me. Contact WeTech Digital to learn more about how our e-commerce social media advertising services as a Facebook Marketing Partner may help you develop your e-commerce business! It's more difficult than ever to reach clients organically in their social feeds, thanks to algorithm tweaks on sites like Facebook. That's why, especially for e-commerce shops wanting to expand their online sales, social media advertising should be considered.",
                    },
                ]
            },
            {
                title: "YouTube Advertising Services",
                description: "Are you looking for best YouTube advertising agency? YouTube is a large social media platform and your company can utilise it to raise brand recognition, acquire leads and create sales using YouTube advertising services. Join forces with Wetech Digital to develop a cutting-edge marketing campaign that produces results and a strong market position.",
                image: SOCIAL_5,
                url: "youtube-advertising-services",
                data: [
                    {
                        title: "What Defines Us As The Top YouTube Advertising Services Agency?",
                        description: "YouTube is the world's second-largest search engine, making it a great place for firms to increase brand exposure, lead generation, and sales. To get started, all you need is a reliable partner. WeTech Digital, as a full-service YouTube ad agency, can handle everything your company needs to promote on YouTube, from campaign development to ad creation. Our YouTube advertising services are of the highest quality and you can depend on us entirely. WeTech Digital is a very good YouTube channel management agency; you can blindly believe us.",
                    },
                    {
                        title: "Our Approach to YouTube Advertising Services",
                        description: "You may wonder if you should hire a YouTube ad agency such as WeTech Digital to handle your campaign as you learn more about YouTube advertising services. YouTube advertising services can aid in the growth of your brand if you create awesome, informative videos. The issue arises when you don't know how to promote that content so that it reaches a wider audience. With our YouTube advertising firm, you may reach a larger audience with your video ads. We'll use sophisticated filtering and bidding to help your video advertisements reach qualified prospects. Our YouTube video marketing services are affordable. As YouTube influencer agency, we offer exclusive YouTube promotion services. Do you need YouTube music video promotion? Here we are with YouTube music video promotion service.",
                    },
                ]
            },
        ]
    },
    {
        title: "Web Content Pricing",
        description: "Affordable Web Content Services Tailored to Your Needs – Quality, Quantity, and Value Combined!",
        url: "web-content-pricing",
        contents: [
            {
                title: "Website SEO Copywriting",
                description: "WeTech Digital is a top SEO copywriting agency that helps you achieve online and offline accomplishment by generating original, appealing copy for anything from blog posts to sales material to online tutorials. Our website SEO copywriting services can help your business reach its objectives, whether it's to improve engagement, online traffic, or sales. Check out our service and price options underneath to understand more about our industry-leading online copywriting services.",
                image: SOCIAL_6,
                url: "website-seo-copywriting",
                data: [
                    {
                        title: "What Defines Us As The Top Website SEO Copywrite Company?",
                        description: "Our WeTech Digital is renowned for its SEO copywriting services. One of the most important aspects of your website is the copy. It links your firm with your potential customers and convinces them to buy your goods, contact you, or even come into your store. Invest in expert website copywriting services for appealing material that resonates with readers and ranks at the top of search results. The excellent reputation of WeTech Digital is there for SEO content writing services worldwide. To generate accurate and appealing text, our copywriting team includes specialists from various backgrounds, including technical and non-technical. WeTech Digital is the lead website SEO Copywriting Company, which also can write content for websites. So you can get the most out of your marketing dollars quickly with our help. We've put together a team of experts to assist this in managing, tracking, following up with, and growing valuable leads – and eventually income.",
                    },
                    {
                        title: "Our Approach to Website SEO Copywrite Service",
                        description: "You may have heard the phrase 'SEO content' bandied about in marketing meetings if you're new to the realm of search marketing. For SEO-optimized content writing, we follow many strategies. We don’t only focus on search engine traffic because of bad results. We provide value above and beyond search engine optimization to delight search engines and potential consumers and repeat visits. To put it another way, we don't create 'thin' content that ranks and gets clicks but doesn't give value to the search engine user. We ensure that SEO Copywrite pricing can be affordable, and we choose the right one for our client. For every type of SEO content, WeTech Digital works hard to make sure an apparent success.",
                    },
                ]
            },
            {
                title: "Content Marketing services",
                description: "Our content marketing services make content marketing simple. Our professional team produces, writes, edits, and promotes unique as well as search engine compatible, content for your business as part of our content marketing service packages. WeTech Digital can handle everything from blog entries to online instructions to videos.",
                image: SOCIAL_7,
                url: "content-marketing-services",
                data: [
                    {
                        title: "What Defines Us As The Top Content Marketing Services Agency?",
                        description: "One of the most effective digital marketing tactics is content marketing. Content marketing is critical to growing your company's income since it can double website conversion rates, as well as generate brand awareness and website traffic. You can anticipate bespoke material that adheres to search engine optimization guidelines when you work with Wetech Digital as your content marketing services partner. Even more, you can rely on honesty - there are no hidden costs or methods. WeTech Digital is an affordable content marketing agency. As there are many content marketing service providers, Wetech Digital is also reliable.",
                    },
                    {
                        title: "Our Approach to Content Marketing Services",
                        description: "All of our content marketing services packages include a personalized content strategy tailored to your company, audience, and objectives. Then, for your company's must-share material, develop a content schedule. Whether it's a blog post, an online tutorial, or a video, our in-house copywriting and marketing team gets to work on your bespoke content. SEO, which optimizes your content for both consumers and search engines, is included in your content marketing management services. We help market your content online in the relevant areas very much to your audience by using our network of targeted audiences. We have content creation packages with different content creation price lists. Our content marketing pricing packages can meet your need.",
                    },
                ]
            },
            {
                title: "Personalized Web Content",
                description: "Do you want good personalized web content management services? Then we are here to please you providing that professionally.",
                image: SOCIAL_8,
                url: "personalized-web-content",
                data: [
                    {
                        title: "What Defines Us As The Top Personalized Web Content Company?",
                        description: "A smart customization plan allows you to tailor the online experience for each customer or client, resulting in happier visitors and more money for you. It's as simple as flicking a switch with our exclusive customization options! Our customization services are completely open, which means you always know where your money is going. WeTech Digital is a very good web content provider. Our personalized web content management is very good on this platform. As a web content personalized agency, WeTech digital can meet and fulfill all your requirements.",
                    },
                    {
                        title: "Our Approach to creating content for website",
                        description: "WeTech Digital is committed to providing high-quality services in all areas. We are in a good position to accomplish the task of creating content for the website. Your consumers are all unique and a one-size-fits-all marketing approach will fail to captivate them all. We adapt content for each site visitor based on their prior activity on the site. Dynamic website personalization is also a popular service of ours. Because it considers prospects' site-specific and general tendencies, dynamic website personalization is seeing a rebirth in marketing automation. This service has many advantages which make your website a high class and user-friendly one. Greater visitor engagement, enhanced customer experience, higher brand perception, increased conversion rates, and increased lead creation and client acquisition are all advantages of customization. Our website personalization statistics are good which helps you to stand high in the market.",
                    },
                ]
            },
            {
                title: "Web Video Production Services",
                description: "Do you want well to build up web video production services? Then we are here to please you providing that professionally.",
                image: SOCIAL_9,
                url: "web-video-production-services",
                data: [
                    {
                        title: "What Defines Us As The Top Web Video Production Service Company?",
                        description: "WeTech Digital is the top video marketing agency in the market, providing professional online films that are engaging, interesting, and successful in gaining viewers' confidence. WeTech Digital understands the financial effect that a single, well-shot video can have. Our pricing for custom video creation services is completely open, so you always know at which your money is going. Our web video production services are very affordable. As a creative video agency, we have a good reputation among clients. It has been proven many times again that firms that offer video creation services are seen as more trustworthy by their consumers. Don't lose out on the chance to showcase your business to clients in the method in which the vast majority of customers seek information. So you can contact us for an online video production service",
                    },
                    {
                        title: "Our Approach to Web Video Production Service",
                        description: "WeTech Digital is dedicated to providing clients with interesting, relevant, and effective content. What finer approach to achieve this than by using web videos? Our product portfolio videos put your message, services, and products in the spotlight. A professionally written screenplay and recorded narration are used in video commercials to tell a tale. A current customer's video testimonial is an excellent approach to create credibility. Online video marketing services have a favorable influence on 82 percent of marketers' businesses, organizations, or institutions. Print and direct mail advertising are 600% less successful than video marketing. If you're ready to engage your audience like never before, it's time to consider using video to tell your story. That’s why we are here to provide video marketing services at reasonable prices.",
                    },
                ]
            },
            {
                title: "Product Photography Services",
                description: "Any image of a product for sale qualifies as product photography. Do you want product photography services? Then we are here to please you providing that services professionally.",
                image: SOCIAL_10,
                url: "product-photography-services",
                data: [
                    {
                        title: "What Defines Us As The Top Product Photography Services Agency?",
                        description: "WeTech Digital is a top Product Photography agency. Product photography is a form of commercial photography in which photographs of items are taken for business purposes. The object, meal, or cooked dish may be the product. A product in product photography, on the other hand, is often small enough to set on a table. Recently, e-commerce has become popular. Most of the things are sold online. That’s why we also started to give e-commerce product photography services. Lifestyle product photography, also known as in-context or plain context photography is the technique of shooting your product in a curated and designed scenario, often with models, to expose a potential consumer to the notion of a certain lifestyle or aesthetic supported by the company. You can find us to get lifestyle product photography services. We have well-furnished product photography studio. Our product photography services are well known.",
                    },
                    {
                        title: "Our Approach to Product Photography Services",
                        description: "WeTech Digital can assist you in giving your goods the greatest first impression possible. In Dhaka, Bangladesh, we are one of the top professional product photographers. We provide amazing product photography for any of your requirements. We too have a fantastic professional grade studio setup for all of your items, and we can provide all of the necessary arrangements to ensure your things seem elegant. Sometimes you can think which product photography services near me or about product photography Bangladesh. Or you might think about “Professional product photography BD”. There are many Photography companies in Bangladesh. All you have to do is find one near you and an affordable one. Product photography price in Bangladesh are started from 400 to 1200 taka.",
                    },
                ]
            },
        ]
    },
    {
        title: "Video & Animation",
        description: "Captivate and Engage Your Audience with Stunning Video and Animation Creations – Transform Ideas into Visual Masterpieces!",
        url: "video-animation",
        contents: [
            {
                title: "Promo Video Production",
                description: "Would you like to use video to raise brand recognition and sales?? Then we're here to help you with that and with a video production price list that has different level prices.",
                image: SOCIAL_11,
                url: "promo-video-production",
                data: [
                    {
                        title: "What Defines Us As The Top Promo Video Production Company?",
                        description: "WeTech Digital is the top Promo Video Production Company. Some people will keep reading about your organization, on the other hand, others are increasingly choosing to view high-quality films about the companies they enjoy. Allow your clients and prospects to choose—but don't restrict yourself to simply a corporate marketing film. As a promo video production company, we can advise you to take the help of promo video. From advertising to animation, tales to product demos, our Curated Marketplace has the most successful sorts of videos for your promotional message. A bespoke project, on the other hand, could be the best option for you. A 30- to 60-second app promo is a short video used by businesses to engage with users and promote benefits. Because of its color arrangement and informational but clear voice, app promo films greatly enhance downloads. That’s why; we also provide app promo video service to help you out. Corporate video production is nowadays at hype. WeTech Digital is also well known as the corporate video production company.",
                    },
                    {
                        title: "Our Approach to Promo Video Production.",
                        description: "WeTech Digital is committed to offering clients engaging, relevant, and successful Promo Video Production Services. Promotional videos may be used to promote a certain cause, new corporate advancements, rebranding, really anything you can think of. To raise brand recognition and engagement, create promotional films for your website or post on YouTube and other social media platforms. You can watch our video production price list to select which one is perfect for you. Promo video prices are normally different based on their contents, length, and quality. We create very attractive app promo videos. You can completely trust our app promo video service. Our videos bring your story to life, whether it's for social media, internal communications, or a comprehensive marketing campaign. We like a good challenge and can help with everything from video production to motion graphics and virtual reality. We cover product debuts, training DVDs, and live events in a day's – or night’s – work. These all works are under corporate video production Services. We dedicatedly declare ourselves as a corporate video production company.",
                    },
                ]
            },
            {
                title: "Infographics and Motion Graphics",
                description: "Infographics are a great way to deliver material to your audience in a fun, interactive manner, and they're also far more likely to go viral and get a lot of attention than other forms of content. Our principal aim is to give you the best infographics and motion graphics.",
                image: SOCIAL_12,
                url: "infographics-and-motion-graphics",
                data: [
                    {
                        title: "What Defines Us As The Top InfoGraphics and Motion Graphics Agency?",
                        description: "WeTech Digital understands what it takes to create the ideal infographics for your company and industry: Our bespoke infographic pricing is completely transparent, so you always know where your money is going. As a motion graphics company, we take care of your needs. Our motion graphics services are well known in the market. 3D motion graphics services are nowadays very popular among everybody because of its eye-catching sight. Infographics come in a variety of shapes and sizes, and they may express a variety of ideas. Wetech Digital specializes in the bespoke image, motion/video, and micro infographics as a leading Infographic design business. There are many Infographic companies out there but you have to find the perfect one. You can rely on our motion graphics production.",
                    },
                    {
                        title: "Our Approach to InfoGraphics and Motion Graphics",
                        description: "We develop content that has the potential to go viral, increasing your website's total reach and brand exposure. Infographic design agency as well as motion graphics agency, WeTech Digital have experts in data visualization that create compelling information visuals that explain and engage visitors on a certain issue in different media. They help people engage emotionally with your brand and establish your firm as an industry leader with a depth of expertise on a certain subject. Our digital advertising and public relations team creates a plan that reflects your brand and industry while also being likely to be shared on popular websites.",
                    },
                ]
            },
            {
                title: "Animation Video Service (2D & 3D)",
                description: "Do you need an Animation video Service? Then we're here to help you out competently with our creativity.",
                image: SOCIAL_13,
                url: "animation-video-service-2d-3d",
                data: [
                    {
                        title: "What Defines Us As The Top Animation Video Service Agency?",
                        description: "WeTech Digital is the top Animation Video Service Agency. Figures are modified to appear as moving pictures in the animation process. Television and video are prominent electronic animation media that were formerly analog but are now digitally operated. Techniques such as animated GIF and Flash animation were created for computer display. 3D animation is a visual method that uses motion to bring people, props, and other things to life. Though 3D animation is most commonly associated with video games, films, and television shows, its applications have expanded in tandem with its popularity. We are a very strong animated explainer video company. Everybody in the market knows us as the best-animated explainer video production company. We offer both 2D & 3D animation services. Our 3D product animation services are much customized. For both services, you can call those companies either 2D animation companies or 3D animation companies.",
                    },
                    {
                        title: "Our Approach Animation Video Service",
                        description: "WeTech Digital is an animated video company that is very devoted to its works. Our 2D & 3D animation services are very creative and we use high-quality technology. You can find many 3D animation companies but WeTech Digital is very reliable. Our 3D animation services are very famous for their uniqueness. A 2D animated video is a form of video created in a computer environment using two–dimensional motion pictures. It's utilized to clearly communicate the brand's essence in a user-friendly way. WeTech Digital is one of the best 2D animation companies. Besides, we also offer 3D product animation services which are a little bit rare. It is also included with Animation Video Service; most specifically included with 3d animation services. Explainer videos may help you build long-term relationships with potential consumers, so you should include them in your video sales plan. A video is an excellent tool for capturing the attention of your audience. It also piques Google's interest. So, we also maintain the rule as an animated explainer video production company.",
                    },
                ]
            },
            {
                title: "Character Animation Services",
                description: "Do you want Character animation services? Here, we are to help you.",
                image: SOCIAL_14,
                url: "character-animation-services",
                data: [
                    {
                        title: "What Defines Us As The Top Character Animation Service company?",
                        description: "WeTech Digital is the top Character Animation Agency. Character animation is the process of making a specific character move in a two- or three-dimensional environment. It's a crucial step in the animation process. Many people connect early character animation with Walt Disney Studios, where cartoonists developed specific characters and gave them certain features and characteristics to display on the screen. This necessitates combining a great deal of technical sketching or animation with certain high-level concepts about how the character moves, likes to think, acts, and consistently appears on the screen. So it is understandable that character animation is very essential. In this manner, 3d character animation services are very popular. Also, there are many kinds of character studio 3ds max. We provide all of them very professionally. For our service quality, WeTech Digital is one of the best character design companies.",
                    },
                    {
                        title: "Our Approach to Character Animation Service",
                        description: "WeTech Digital is dedicated to providing clients with interesting, relevant, and effective Character Animation Services. Character animation has evolved in tandem with the evolution of rudimentary cartoon animation into current three-dimensional animation. Character rigging and the introduction of object-oriented frameworks for constructing character sequences are part of today's character animation. By observing the market demand, we started to provide 3d character animation services. There are many character design companies out there. All you have to do is to find the correct and affordable one. We have a well-equipped character design studio which helps to decrease the cost of the elements. Professional tools for animating 3D characters are available in 3ds Max's character studio feature set. It's a program that allows you to rapidly and simply create skeletons (also known as character rigs) and then animate them, resulting in motion sequences. We provide character studio 3ds max to compare with the need of the age.",
                    },
                ]
            },
            {
                title: "3D Product Visualization",
                description: "Do you want high-quality 3D product visualization services? Then we will offer you, professionally, exactly what you need.",
                image: SOCIAL_15,
                url: "3d-product-visualization",
                data: [
                    {
                        title: "What Defines Us As The Top 3D Product Visualization Services Company?",
                        description: "WeTech Digital is the top 3D Product Visualization Services Company in the market. The representation of 3D products is just the development of 3D product designs. Three-dimensional design offers captivating images that display items far more impressively and thoroughly than a basic description of a written product. Visualization of 3D products is an art–and not only the design of the product! 3D product visualization helps online retailers to enhance conversions, minimize returns, extend their product range and improve consumer experience, from real-time product modification to increased real-life. Product visualization services of Wetech Digital are very good. We also provide 3D product animation services. The visualization of products, sometimes called the rendering of products, is a technique enabling rapid and realistic visual representation of items or architecture. When a product, programme or concept is still in its earliest stage, 3D rendering and viewing may be utilized to generate attractive pictures to communicate the storyline. Our services like product animation services are very popular among customers. Wetech Digital is one of the best 3d product visualization companies.",
                    },
                    {
                        title: "Our Approach to 3D Product Visualization Services",
                        description: "Wetech Digital is dedicated to providing clients with interesting, relevant, and effective content. Working on that purpose, our product visualization services are most reliable with affordable 3D product animation cost. As a 3D product animation company, we offer a wide range of 3D product animation services. We provide cost effective product animation services. There are many product visualization companies but you can blindly rely on us for his service. Wetech Digital is the best 3d product animation company on the market.",
                    },
                ]
            },
        ]
    },
    {
        title: "Analysis",
        description: "Uncover insights, patterns, and trends with our comprehensive data analysis services. Transform data into informed decisions.",
        url: "analysis",
        contents: [
            {
                title: "CRM ROI",
                description: "Choose to make much use of your sales and marketing data to boost your small or midsized business's profitability? Raise a toast to CRM ROI, a sales management system designed for small sales teams with big ambitions.",
                image: RESEARCH_1,
                url: "crm-roi",
                data: [
                    {
                        title: "What Defines Us As The Top CRM ROI Agency?",
                        description: "WeTech Digital provides the best CRM ROI services in today's market. CRM refers to Customer relationship management and ROI refers to Return on investment. CRM ROI is an economical option for helping your team closes more sales if you don't already have one. CRM ROI integrates unique product information with best practices in lead management to make it simpler to connect sales and marketing data. As a return on Investment Company, we provide the best service to please you. That's why our ROI service is very famous in the market.",
                    },
                    {
                        title: "Our Approach to CRM ROI",
                        description: "Half the money I spend on advertising is squandered; the issue is, I don't know which half, remarked marketing pioneerJohn Wanamaker. It's difficult to analyze the outcomes of your marketing activities without a CRM. On the other hand, CRM ROI may assist you in tracking ROI and allocating funding to the channels that generate the most sales and income. WeTech Digital is known as a return on investment optimized service provider. Our service is the best on this platform that you can rely on.",
                    },
                ]
            },
            {
                title: "Lead Management Solutions",
                description: "Did you know that leads that are reached within five minutes have a 90% chance of becoming sales? It's difficult to organize, prioritize, and follow up on leads without a good lead management system. Fortunately, WeTech Digital — our clients' proprietary lead management software — can assist you in converting leads into increased sales and money for your small or mid-sized firm. As the top lead management company, you can put your faith in us.",
                image: RESEARCH_2,
                url: "lead-management-solutions",
                data: [
                    {
                        title: "What Defines Us As The Top Lead Management Solutions Agency?",
                        description: "Lead management is a systematic procedure for qualifying, analyzing and nurturing incoming leads in order to turn them into new business possibilities. Leads from various sources enter your lead management system and sales-ready leads are transformed into transactions in a conventional sales process. If you want to stand out from the crowd, you'll need to handle and track your leads effectively. A good lead management system can help you streamline your conversion funnel so you can get the most out of your marketing dollars. We've put together a team of experts to assist lead-based businesses in managing, tracking, following up with, and growing valuable leads – and eventually income. Real estate agents may use lead generating tools to discover clients and seal deals. Agents can use lead generating firms to identify buyers and sellers that reside in specific locations, have certain interests, or are seeking particular sorts of homes. We introduce you to top lead generation companies for real estate for your better good. WeTech Digital is a lead management agency that can help you out.",
                    },
                    {
                        title: "Our Approach to Lead Management Solutions Service",
                        description: "We keep track of your marketing ROI in real-time (ROI), follow your leads throughout their entire lifespan. From their first encounter through their purchase, Manage your sales funnel efficiently and assist you in generating more leads, sales, and revenue. We provide a full range of lead-generating services to assist you in increasing lead conversions and, eventually, revenues. Attract your audience's attention. Increase the number of conversions. Boost your return on investment. Consult with us to know about lead generation companies for small businesses. We provide software for lead management. B2B lead generation is the process of locating and attracting the right consumers for your product or service. For B2B sales and marketing teams, it's a must-do activity that our experts control. As there are many b2b lead generation companies, you have to find the correct and affordable one. However, some of them are the best b2b lead generation companies. When clients request it, we create b2b lead generation websites. WeTech Digital is a top b2b lead generation agency. Our b2b lead generation services are well known.",
                    },
                ]
            },
            {
                title: "Custom SEO Reporting",
                description: "Do you need custom SEO report services? Our SEO Experts know how your company will get more focus locally!",
                image: RESEARCH_3,
                url: "custom-seo-reporting",
                data: [
                    {
                        title: "What Defines Us As The Top Custom SEO Reporting Agency?",
                        description: "WeTech Digital learns about your marketing strategy, market, and rivals while paying attention to your strategic objectives. Custom SEO services can guarantee that your customers discover you and keep an eye on your customer base by using the information to develop a tailored plan that takes you where you need to go. Custom SEO report services by us make you go ahead in the market. WeTech Digital offers numerous bespoke reporting solutions for clients that want advanced monthly data on the effectiveness of their company's website. Every month, these sophisticated reports offer your company very specific data. WeTech Digital clients that want more detailed information than the monthly reports included with our SEO programs may now request advanced reports for a charge.",
                    },
                    {
                        title: "Our Approach to Custom SEO Reporting Services",
                        description: "In the world of digital marketing, accurate reporting is crucial. As an SEO analysis agency, WeTech Digital offers sophisticated, customized reports that include everything from call monitoring to lead attribution and all in between. Our custom reporting pricing is completely transparent, so you always know where your money is going. Organic search engine traffic, PPC, and social media traffic, and income reports are split up by particular marketing channels. In Google Analytics or other website analytics platforms, custom objectives, events, and dashboards may be created for advanced monthly tracking. Call monitoring, cost-per-lead, e-commerce, lead attribution, firms visiting your website, and more advanced reporting features are available.",
                    },
                ]
            },
        ]
    },
    {
        title: "Optimization",
        description: "Unlock Peak Performance: Strategies and Techniques to Optimize Your Workflow and Maximize Productivity.",
        url: "optimization",
        contents: [
            {
                title: "Conversion Rate Optimization",
                description: "Do you want to raise the number of people who complete the desired action on a website? We are here to please you only by speed up your page’s Conversion Rate Optimization professionally.",
                image: RESEARCH_4,
                url: "conversion-rate-optimization",
                data: [
                    {
                        title: "What Defines Us As The Top Conversion Rate Optimization Agency?",
                        description: "The technique of raising the percentage of users or website visitors that do the desired action is known as conversion rate optimization. Conversion rate optimization (CRO) is the practice of improving the likelihood of a visitor doing desired actions (conversions) on a website or landing page by optimizing the site or landing page experience based on website visitor behavior. If your traffic isn't turning into paying customers, sales, or clients, you're wasting your time. Conversion rate optimization is an essential aspect of digital marketing that we at Wetech Digital are well-versed in. Though a top CRO agency, our CRO pricing is entirely open, so you always know where your money is going and how it is helping your site generate income. Our expert optimization team makes possible a good conversion rate optimization for you. We also knew as an E-commerce conversion rate optimization agency. We provide a high-level E-commerce conversion rate optimization. As an E-commerce CRO agency, our success rate is desirable. You can try our conversion rate optimization services.",
                    },
                    {
                        title: "Our Approach to Conversion Rate Optimization",
                        description: "WeTech Digital has tactful conversion rate optimization strategies. Every homepage has its own set of objectives. A lead generation website, an e-commerce store that sells things, and a blog that transforms visitors into subscribers are examples of industrial websites. Unfortunately, many website owners don't pay as much attention to how effectively they meet those objectives as they should, and many marketers define success in terms of traffic and rankings rather than conversions. CRO (conversion rate optimization) has become highly significant since it allows you to determine what isn't working, why it isn't working, and how to solve it. Identifying leaks in your sales funnel can significantly influence the performance of your website. The sales funnel refers to the complete sales process, from generating interest to affecting potential consumers that they need your product or service to eventually sealing the transaction. Identifying leaks in this funnel can have a significant influence on the performance of your website. We take care of them.",
                    },
                ]
            },
            {
                title: "Page Speed Optimization",
                description: "Do you want to make your web page most visible and visited by users? Then, we are here to please you only by speeding up your page’s speed professionally.",
                image: RESEARCH_5,
                url: "page-speed-optimization",
                data: [
                    {
                        title: "What Defines Us As The Top Page Speed Optimization Agency?",
                        description: "WeTech Digital offers developers and programmers to support you who are wholeheartedly dedicated. Our experts work for top companies as well as start-ups to ensure page speed optimization for their pages. Our experienced team of highly trained and dedicated developers is devoted to meeting your needs, making them our own, and providing the best experience for your users. As a result, our success rate is high, and it can make you appreciate us, WeTech Digital. There are many companies out there that provide page speed optimization services. Our page speed optimization service is also compatible with theirs. Database cleaning, image compressing, site cache create are done with the help of WordPress website speed optimization. For your website speed optimization, you can rely on WeTech Digital.",
                    },
                    {
                        title: "Our Approach to Page speed Optimization.",
                        description: "Our page speed optimization is based on extensive analysis and some techniques to put your page in a high position on the Google list. We have many ways to increase the optimization of page speed. We analyzed some essential terms related to it. Such as Search volume, CPC, Paid difficulty, SEO difficulty, etc. And for best results, some other ways are also followed by us. All aspects are considered. Our oath is to provide the best service inside the local and global markets. We always remember that to please you with our service. So you can find us helping you with excellent website speed optimization services.",
                    },
                ]
            },
        ]
    },
    {
        title: "Design Pricing",
        description: "Affordable Pricing Plans Tailored to Your Needs for Quality Services and Excellent Value.",
        url: "design-pricing",
        contents: [
            {
                title: "UX Design for Website",
                description: "We design user experience design as your thinking and consider the client’s review. As a result, top-rated companies choose our Unique set of creating solutions for their products.",
                image: DESIGN_1,
                url: "ux-design-for-website",
                data: [
                    {
                        title: "What Defines Us As The Top UX Website Design Agency?",
                        description: "The process through which design teams generate products that give customers meaningful and relevant experiences is known as user experience design (UX). Using the design thinking approach, our clients are widely researched. We strive to learn as much as possible about our customers to create a consistent User Experience and visual identity perfectly matched with corporate goals and complete factorial design. As a result, we’ve about contact added a wide range of businesses, from start-ups to huge enterprises. Our agency can successfully produce branding and UI/UX design, responsive and bespoke web design, and user experience testing, thanks to our industry-based expertise. For getting best UX UI design services you can rely on us. There are many UI UX design agency and they provide UI UX services. You only have to find the most reliable UX design agency for getting high level UX design services.",
                    },
                    {
                        title: "Our Approach to UX Website Design",
                        description: "The design of your UX website is the first impression your audience has of your business. Beautiful websites are necessary to establish a positive first impression on the audience. Essential value-adding components, user-centric architecture, colors that match your brand, appropriate photos, and testing the proper font responsive about contacted should all be included on your website. So WeTech Digital agency helps turn your ideas into reality as web visuals. We became the most excellent website design company globally by creating bespoke and best UX web design and understanding how our great design would aid your business. In one word, our UI UX designing services are first-rated.",
                    },
                ]
            },
            {
                title: "User Experience Testing",
                description: "Are you looking for strategies to boost your profits? A positive online user experience (UX) results in more visits, customers, and money. Our user experience testing method made that possible for you.",
                image: DESIGN_2,
                url: "user-experience-testing",
                data: [
                    {
                        title: "What Defines Us As The Top User Experience Testing Agency?",
                        description: "The method by which design teams create products that provides value to customers. Usability testing, often known as User Experience (UX) testing, determines how user-friendly and straightforward a product is. We follow- 1) Responding to your consumers' feedback and enhancing their experience as a result; 2) By reducing the amount of tricky navigation, slow checkout procedures, and ambiguous directions, you can increase brand loyalty and trust. For more sales, more lead form submissions, or a better conversion rate’s you can seek help from Wetech Digital to test your website’s user experience and learn about what to change to be improved. Usability testing services track time spent on a job, job completion, and might discover roadblocks you didn't expect. Good Firms has combed across a few companies and compiled a list of the best usability testing services to meet your needs. We arrange usability testing companies for you. As usability testing agency, you can rely on WeTech Digital.",
                    },
                    {
                        title: "Our Approach to User Experience Testing",
                        description: "Beautiful websites are necessary to establish a positive first impression on the audience. WeTech Digital agency helps turn your ideas into reality as web visuals. We became the most significant UX Tester Company in the world by our working method. We have the best user experience tools. Our UX Testing procedures create a test strategy; people should be recruited (target users) and locate an appropriate site. After that, the test should be moderated and keep a record of the test results. There are many UX testing agency like us who uses best user testing websites. You sometimes need user testing websites. Wetech Digital collaborates with top user testing websites in need.",
                    },
                ]
            },
            {
                title: "Landing Page Design",
                description: "Are you looking for strategies to increase your sales and boost your profits? Landing pages are a crucial component of any marketing and advertising strategy. Using skilled landing page design services may help you improve the impact of your landing pages. Our experienced Landing page creators are here to help you out.",
                image: DESIGN_3,
                url: "landing-page-design",
                data: [
                    {
                        title: "What Defines Us As The Top Landing Page Design Agency?",
                        description: "A landing page is a page on your website that is created with the goal of converting visitors into leads. Wetech Digital can help your firm get a competitive advantage. Our landing page designer services provide a turn-key solution for creating, deploying, and upgrading landing pages, resulting in more leads, sales, and money for your company. Landing page design services can help you convert visitors into sales. Instead of sending visitors to your homepage, send them to a highly focused landing page. Landing pages persuade visitors to remain and do a certain action, such as buying a product. We successfully do all these for our clients as we have best landing page design experts.",
                    },
                    {
                        title: "Our Approach to Landing Page Design",
                        description: "You may create a page dedicated to your dog walking business using a custom landing page design. You may drive potential customers to this specific page instead of your homepage when they click on your ad. They'll be more likely to complete the purchase if they're given fast access to information, price, and other details about your dog walking service. You'll get a different conversion strategy and design for each landing page. Working with a competent account manager can help you get the most bangs for your buck. Create landing pages that are mobile-friendly, desktop-friendly, and tablet-friendly. Conversion tracking codes should be included to all of your landing pages. Our landing page development services are world class. Wetech gives the best landing page services.",
                    },
                ]
            },
        ]
    },
    {
        title: "Development Pricing",
        description: "Affordable Development Pricing Tailored to Your Needs - Get Quality Development Services Without Breaking the Bank!",
        url: "development-pricing",
        contents: [
            {
                title: "Web Development",
                description: "Our website Development agency is transforming the way brands connect with audiences in a digital world. Learn why market leaders choose our Unique set of development and solutions.",
                image: DESIGN_4,
                url: "web-development",
                data: [
                    {
                        title: "What Defines Us As The Top Web Development Company/Agency?",
                        description: "We assist you in improving the customer experience, responsive and custom website development service at affordable prices by offering top-rated solutions built with modern frameworks like Angular JS, Node JS, React JS, ASP.NET, PHP, WordPress, and others. Our process-driven and enthusiastic team of designers and developers have extensive expertise and experience in developing stable, cross-browser compatible, and device-independent web applications.",
                    },
                    {
                        title: "Our Approach to Landing Page Design",
                        description: "You've come to the correct location if you're searching for personalized website development solutions. Our highly experienced web development experts are well-versed in today's cutting-edge web technologies. To be successful, web development projects must be highly decorated. Every day, our technical project managers and developers collaborate to ensure that our clients' needs are always prioritized. Our development team also collaborates closely with the UX and UI teams to ensure that best practices in customer experience are always at the forefront of our minds.",
                    },
                ]
            },
            {
                title: "E-commerce Development",
                description: "Taking your store online & help to build practical e-commerce solutions that work for your business",
                image: DESIGN_5,
                url: "e-commerce-development",
                data: [
                    {
                        title: "What Defines Us As The Top E-commerce Development Agency?",
                        description: "WeTech Digital offers dedicated developers and programmers to support your team. Top companies and start-ups choose us as we provide the highest quality stress-free e-commerce service & solutions. Our dedicated team of highly trained and experienced developers is devoted to meeting your needs in the modern world across a broad range of offerings, providing experts know-how and expertise. With 100% project delivery success, We deliver E-commerce website & application development services to local and global businesses for five years.",
                    },
                    {
                        title: "Our Approach to E-commerce Development",
                        description: "Our e-Commerce development solutions are base on extensive analysis and data-driven techniques that address various aspects of e-commerce and its elements. We don't make powerful or magic stuff, although, with our help, you can manage much more. Learn about our innovative designers, skilled developers, and customer-focused project managers who can provide you with the best services possible. We believe in providing the best service inside the local and global market. Always care about your service that can be better with our special effort and ensure that you’ll get exactly what you want.",
                    },
                ]
            },
            {
                title: "Portfolio Site",
                description: "Would you like to get work as a freelancer, get more clients for your firm, or get a job? A portfolio site is a digital version of a freelancer's (or firm's) resume. It gives potential clients an easy method to see your work while also allowing you to broaden your talents and services. This isn't, however, the primary goal of a portfolio website. Our portfolio site experts can help you out.",
                image: DESIGN_6,
                url: "portfolio-site",
                data: [
                    {
                        title: "What Defines Us As The Top Portfolio Site Agency?",
                        description: "The primary objective of a portfolio website is to help you gain more clients, whether that means freelancing work, new clients for your firm, or job opportunities. Before you start adding material to your website, you need to figure out what you want to achieve. For freelancers operating in the internet era, portfolio sites are essential. While all freelancers can send out business cards, and other freelancers, such as photographers, can hand out actual portfolios to potential clients, a portfolio website allows any freelancer in any profession to reach a larger number of clients on a worldwide scale. It also allows you to be more creative with the details and information you reveal about yourself and your business. Sometimes your portfolio is for photography websites, you have to find the best photography websites where we can arrange help. We also customize personal portfolio websites. Wetech Digital is renowned for creating the best portfolio websites. Our portfolio website examples are admirable.",
                    },
                    {
                        title: "Our Approach to Portfolio Site",
                        description: "The types of content and elements you add to your site as well as the way you implement them will affect your work to find clients. Our world-class Portfolio Site experts keep their eyes on some features to create a good portfolio site. They synchronically work with design, tag-lined logo, CTA, high-quality images, testimonials, services, contact information, your introduction, contents, your photo, your social media accounts links, your projects, free samples, FAQ part, guest appearances to make a perfect portfolio site. We also arrange a free portfolio website. For portfolio website design we analyze many portfolio website examples.",
                    },
                ]
            },
            {
                title: "Mobile Application Development",
                description: "Explore our expertise in mobile application development. We craft innovative and user-centric mobile apps tailored to your business needs for seamless user experiences.",
                image: DESIGN_7,
                url: "mobile-application-development",
                data: [
                    {
                        title: "What Defines Us As The Top Mobile & Web Apps Development Company?",
                        description: "Our approach is based on a fundamental fact about apps development. most new applications resemble previous versions. That isn't to suggest there aren't any new app ideas. Rather, it means that regardless of how creative or innovative the concept is, there are fundamental industry standards that developers all over the world use to design cutting-edge applications. This allows us to quickly put together the basic framework of your application, giving us more time for customization and fine-tuning.",
                    },
                    {
                        title: "Our Approach to Apps Development",
                        description: "Our expert team will oversee the creation of your Android and iOS apps. We provide you with a specified term and a straightforward timeline with deadlines for your project. and will be there to support you every step of the way with your committed project manager, And our service of mobile and web apps developments are included with android app development, ios, and android app development. You will find us as the best apps development company as we are exploring both in local & global market.",
                    },
                ]
            },
            {
                title: "Shopify Advertising Agency",
                description: "Do you want Shopify advertising management services? Then we are here to please you by providing that professionally. Our Shopify advertising agency is famous in the market.",
                image: DESIGN_8,
                url: "shopify-advertising-agency",
                data: [
                    {
                        title: "What Defines Us As The Top Shopify Advertising ManagementCompany?",
                        description: "WeTech Digital is the top Shopify advertising agency in the market. Shopify is one of the most popular e-commerce platforms for a reason. It's easy to use while being flexible and it includes a lot of useful features. With over 1.7 million merchants using the platform, Shopify is a popular choice for businesses in a range of industries. Shopify is designed to be easy to use. Because it's a hosted platform, getting started is a lot easier. It also has a user-friendly interface and a choice of styles, making setting up a basic e-commerce site a breeze. We provide best Shopify advertising. Our Shopify Advertising management service is well known. We know that there are many agencies but you can believe us as the best Shopify advertising agency. For our focused management with Shopify, we are introducing ourselves as the best Shopify advertising management agency.",
                    },
                    {
                        title: "Our Approach to Shopify Advertising Management Services",
                        description: "WeTech Digital is dedicated to providing clients with interesting, relevant, and effective content as we are a famous and reliable Shopify advertising agency. Advertising management is a planned administrative process for overseeing and controlling the different advertising activities included in a programme to connect with a company's target market, with the goal of influencing consumer purchase choices. Working on that purpose, our Shopify advertising management services are most reliable with affordable Shopify advertising costs. Shopify advertising costs are normally high but we try to decrease it as much as we can. As a Shopify store development services company, we offer a wide range of Shopify advertising management. We also arrange a Shopify advertising campaign for you. Shopify advertising campaign is included with Shopify marketing campaign. Our Shopify marketing campaign will help you to spread your name and details. It is very effective to participate in this Shopify marketing campaign. All of our services lead you to get the best Shopify advertising.",
                    },
                ]
            },
            {
                title: "Website Maintenance Services",
                description: "Do you want to get website maintenance services? Then, we are here to please you professionally. WeTech Digital has the knowledge and skills that your business requires.",
                image: DESIGN_9,
                url: "website-maintenance-services",
                data: [
                    {
                        title: "What Defines Us As The Top Website maintenance services Agency?",
                        description: "WeTech Digital is a leading website maintenance service provider, assisting businesses all over the world in improving the performance and security of their websites. Many agencies provide website maintenance services. WeTech Digital has the skillset your organization needs, whether you need monthly, weekly, after, or during website maintenance. Every internet search leads to the discovery of a new company, product, or service by 50% of consumers. They go to your website, look at your products, look at your services, and form an initial impression of your company. The initial impression you make may make or break your next transaction. This reality makes website upkeep a key concern for today's firms. We, as your partner, create a bespoke and comprehensive website maintenance plan that enables your organization to give a quick, secure, and smooth online experience. In addition, as a full-service digital marketing firm, we provide turnkey solutions for improving the performance of your website. Our website maintenance cost is very affordable.",
                    },
                    {
                        title: "Our Approach to Website maintenance services",
                        description: "Our expert team of developers, designers, and content creators can help you keep your website up to date and improve it. There are varieties of costs of website maintenance in the market. We offer this at a minimal rate. Our team of engineers maintains your website functioning and safe for users by performing frequent updates during business hours. Your business will benefit from our development team's experience as well as our website maintenance plans, which include access to our top-notch tech support. Our staff manages WordPress updates and security fixes for your organization as part of your contract.",
                    },
                ]
            },
            {
                title: "Content Management",
                description: "Generate more leads & sales with an effective Content Marketing System. There's a better way to grow, and we are the solution!",
                image: DESIGN_10,
                url: "content-management",
                data: [
                    {
                        title: "What Defines Us As The Top CMS Development Agency?",
                        description: "Our Content Marketing Strategists use research evidence to develop appropriate activities to meet the client's goal in inbound marketing and CMS strategies. The foundation of any deliverable produced to maintain high-quality content is shown to your primary audience is content strategy. A well-planned and implemented content management system defines each scope of work. Additionally, our SEO team always ensures that we use the best content management system, and those are 100% custom CMS development, SEO optimized, conducting with the best keyword research.",
                    },
                    {
                        title: "Our Approach to Content Management Service",
                        description: "We specify each of the research carefully and verify which content marketing strategy and system will cover your companies demand. We also analyze search queries to identify specific information people have about the products and services you provide. We always prefer sales-oriented content development that helps to drive more audience, sales, and marketing, whether it’s social, website, script, or anything. Ensuring your content contains the information your audience is looking for.",
                    },
                ]
            },
        ]
    },
    {
        title: "Branding Strategy",
        description: "Crafting a Cohesive Branding Strategy to Elevate Your Business and Resonate with Your Audience.",
        url: "branding-strategy",
        contents: [
            {
                title: "Brand Identity",
                description: "Do you want to get brand & identity design? Then we're here to help you out competently.",
                image: BRANDING_1,
                url: "brand-identity",
                data: [
                    {
                        title: "What Defines Us As The Top Brand Identity Agency?",
                        description: "WeTech Digital is the top Brand Identity Agency in Bangladesh. We are famous for our brand & identity design. The visual components of a brand, such as color, design, and logo that identify and distinguish the brand in the eyes of customers are known as brand identity. Brand identity is not the same as brand image. In its goods and marketing, it makes use of colours, forms and other visual aspects. Its advertising is written in a certain way. So design a brand identity is essential for business growth. There are different kinds of brand identity pricing packages. You can choose any of them. To get brand identity design services, you can contact with us any time.",
                    },
                    {
                        title: "Our Approach to Brand Identity",
                        description: "WeTech Digital is a dedicate brand identity design agency. Your brand identity is the secret sauce of your business that sets you apart from everyone else on the block, much like your personal identity makes you uniquely you. What about your logo design? It's what gives your business its personality. Express your brand motto and identity by design a brand identity. Our brand identity pricing packages are very affordable. However, branding & identity design is must for business branding. Quickly prepare your branding & identity design. As brand identity design agency, WeTech Digital is here with our brand identity design services.",
                    },
                ]
            },
            {
                title: "Business Development Strategy",
                description: "Business Development Agency Do you seek for Business Development Agency? Then we're here to help you with our strategy.",
                image: BRANDING_2,
                url: "business-development-strategy",
                data: [
                    {
                        title: "What Defines Us As The Top Business Development Agency?",
                        description: "WeTech Digital is the top Business Development Agency. A solid business development strategy is a guide for your team to follow in order to identify and produce high-value leads that will help you achieve your long-term objectives. Without even a framework, one's team may struggle to discover qualified leads or, in the worst-case scenario, spend countless hours nurturing leads that don't convert. To understand all these elaborately, you need a business development consultancy. WeTech Digital can be that business development consultancy agency for you. Our services towards BDS consultancy are very helpful for further business development. There are many kinds of business development consultancy services under our business development services. You can choose us as the best business development Services Company with your eyes close among many.",
                    },
                    {
                        title: "Our Approach to Business Development Services",
                        description: "Business development is frequently mistaken for sales, is frequently ignored, and is only accorded the tactical attention it needs on rare occasions. WeTech Digital can separately differentiate them. However, having a business growth strategy is critical for main contributions and ensuring that everyone in your organization is working toward the same objective. Though there are many agencies that provide business development services. But WeTech Digital is dedicated to providing clients with top-notch business development services. We can offer affordable services to anybody who consults with our business development consultancy team. Our focused and expert team will serve the clients with business development consultancy services. They can work for the right goal setting, research, and many other related things. As a business development agency, we are more devoted than others. For this reason, WeTech Digital has mostly desired business development Services Company among clients.",
                    },
                ]
            },
            {
                title: "Market Research Service",
                description: "Do you want to get Market research for your business? Then we're here to help you out competently.",
                image: BRANDING_3,
                url: "market-research-service",
                data: [
                    {
                        title: "What Defines Us As The Top Market Research Service Agency?",
                        description: "WeTech Digital is the top Market Research Service Agency. The most successful internet marketers are those that are always educating and testing the bounds. With this in mind, we've developed a section called WeTech Digital Market Research to share and republish information that encourages and educates us. Intellectual and data-driven investigations are examples of this. Here are a few samples of the kinds of things you could discover in our research section: Articles from academe that have been peer-reviewed, essays based on sources, demonstrations. WeTech Digital is one of the best market research service firms. We have cost-effective market research service price.",
                    },
                    {
                        title: "Our Approach to Market Research Service",
                        description: "WeTech Digital’s market research service is very much effective. Market research is when a firm does studies and assesses the viability of a new product or service through the use of surveys, product tests, and focus groups. There are many market research service providers in the market. As market research service provider surveys, interviews, focus groups, and consumer observation are four typical forms of market research methodologies done by us. Like other market research service firms, we took all hassles to complete market research for you. But our market research service price is very affordable.",
                    },
                ]
            },
            {
                title: "Competitor Analysis",
                description: "Do you want well to build up Competitor Analysis services? Then we are here to please you providing that professionally.",
                image: BRANDING_4,
                url: "competitor-analysis",
                data: [
                    {
                        title: "What Defines Us As The Top Competitor Analysis Service Company?",
                        description: "WeTech Digital is the top Competitor Analysis services company in the market. Every day, competitors are analyzed to help develop goods, guide marketing campaigns, identify target consumers, and build strategy. Competitive analysis reports, when applied correctly, may give various advantages. It's a game of chance in business. There are many competitor analysis companies in the market. But WeTech Digital is one of the best companies out there. An SEO competitor analysis includes looking at the content and other aspects of the content, such as the links and keywords utilized by a rival. Simply said, it's an SEO comparison of two rival websites in the same sector or business. Similar to other SEO competitor analysis services, we offer the most quality full work for you.",
                    },
                    {
                        title: "Our Approach to Competitor Analysis Service",
                        description: "WeTech Digital is dedicated to providing clients with interesting, relevant, and effective content. If you'd like to surpass your competitors, you must understand their tactics, campaigns, and methods for attracting leads to their company. Competitor analysis in digital marketing is critical to your marketing success. Frequent competitor analysis is beneficial to all brands. We help you to recognize new inconsistencies, create new goods and services. We level your competitors in three as direct, indirect, and replacement competitors. Direct rivals are the most hazardous since, in many respects, they are carbon copies of your company. Your indirect rivals may offer similar products and services to you, but they are likely to operate under a different business model or have distinct business objectives. Replacement rivals provide items or services that may be used to replace the ones you provide. As a result, if your products or services fail and your consumers want to try something new to achieve their goal, they can turn to your substitute competitors. Like other competitor analysis companies, we solve all these for your better good.",
                    },
                ]
            },
            {
                title: "Community Management",
                description: "Do you want to get Online Community Management Services? Then we're here to help you out competently.",
                image: BRANDING_5,
                url: "community-management",
                data: [
                    {
                        title: "What Defines Us As The Top Community Management Agency?",
                        description: "WeTech Digital is the top Community Agency in the Market. The administration of a shared resource or issue by a community via the collective activity of volunteers and stakeholders is known as community management or widely accepted resource management. A material or informational resource can be managed. Are you looking for innovative ways to strengthen your consumer relationships? Do you want to improve client loyalty and online involvement with your company? Then, you are in right place. We provide the best online community management services. It lays the groundwork for you to engage with existing and future consumers, as well as for them to communicate with one another, in a controlled atmosphere. As a social media community management agency, we keep our costs comparatively low.",
                    },
                    {
                        title: "Our Approach to Community Management",
                        description: "WeTech Digital will create an online community that is congruent with your brand. It encourages user interaction, allowing you to stay in touch with your consumers at all times. WeTech Digital is dedicated to providing clients with very dedicated works. The greatest way to create long-term connections with your consumers is to increase customer loyalty. The most successful approach to increasing income for a company is creating, facilitating, and nurturing client connections. To fulfill all these, online community management services are important. WeTech Digital can be your savior as we are the top-notch community management agency. There are many social media community management agencies out there, but we are the most affordable one.",
                    },
                ]
            },
        ]
    },
    {
        title: "Brand Awareness",
        description: "Elevate Your Brand's Presence and Recognition with Our Proven Strategies for Lasting Brand Awareness.",
        url: "brand-awareness",
        contents: [
            {
                title: "TVC Marketing",
                description: "Are you looking for a Creative TV Commercial Agency? High-quality content and TVC products are needed for success. WeTech Digital has an affordable TV commercial cost package.",
                image: BRANDING_6,
                url: "tvc-marketing",
                data: [
                    {
                        title: "What Defines Us As The Top TVC Agency?",
                        description: "WeTech Digital, as one of the country's top TVC advertising agency. The abbreviation TVC stands for television commercial, which is a type of advertising that uses the television media to advertise products, services, ideas, people, or organizations. TV commercials can be anything from ten seconds to 3 minutes long. In this digital era, TVC advertising is important for awareness, attention-seeking, and spreading news. There are many TV Advertising Agencies but all you have to do is find the reliable one. In this case, you can completely trust us. WeTech Digital is here to provide you with creative content as we are a very creative TV commercial agency.",
                    },
                    {
                        title: "Our Approach to TVC Services",
                        description: "Our TVC team may assist you with establishing a presence before everybody. WeTech Digital is a creative TV commercial agency that can help with TVC making. The main goal of the TV commercial is to introduce a new product, spread awareness, and eventually persuade people to switch to the advertised brand. A TV commercial also serves to remind the consumer audience of the product's existence in order to sustain demand over time. TV Advertising Agencies mainly focus on the message they need to spread. We also focus on that with clear branding and also try to make unique content that snatches attention.",
                    },
                ]
            },
            {
                title: "OVC Marketing",
                description: "Do you want Online Video Commercial? Then we're here to help you out competently by giving OVC marketing services.",
                image: BRANDING_7,
                url: "ovc-marketing",
                data: [
                    {
                        title: "What Defines Us As The Top Online Video Commercial Agency?",
                        description: "WeTech Digital is the top Online Video Commercial Agency in Bangladesh. Although video commercials refer to advertising that happens before, during, and/or after a recorded video on an online platform, it is widely understood that they relate to promotion that occurs before, during, and/or after a video stream on the World Wide Web. In this digital era, OVC marketing not only has good effectiveness but is also highly needed. That's why online video commercial services are given by our agency to keep pace with hype. YouTube, Facebook, Instagram can be the best platform for this. Also as a social video marketing agency we are at top in the market. Social video marketing is nowadays very popular. It requires experts and well equipped agency for the best results. We can proudly say that, we are the top notch video marketing service provider in Bangladesh.",
                    },
                    {
                        title: "Our Approach to Online Video Commercial",
                        description: "WeTech Digital is dedicated to providing clients with interesting, relevant, and effective video contents services since these are under OVC marketing. You may use online videos to better describe your product, service, or concept. It has to do with how individuals consume information. People who use the internet have short attention spans, and videos allow them to ingest more information in a shorter amount of time. Videos are far more likely to engage visitors than static text, therefore more interactive and visual material is critical. Animated online video commercial is also a popular feature in OVC marketing. Animation has the ability to captivate the hearts of people of all ages. The client has already given you their attention; now it's up to you to persuade them to keep paying attention to you. Online video commercial helps to brought them. WeTech Digital has online video commercial package. Our online video commercial package is very cost effective. Our social video marketing team can help you to solve your issues. You can anytime contact with them.",
                    },
                ]
            },
            {
                title: "Influencer Marketing",
                description: "Are you looking for the best influencer marketing services? WeTech Digital is here to help you heartedly.",
                image: BRANDING_8,
                url: "influencer-marketing",
                data: [
                    {
                        title: "What Defines Us As The Top Influencer Marketing Agency?",
                        description: "WeTech Digital is a premier influencer marketing agency that develops bespoke, data-driven plans to help you increase your income, brand exposure, and brand recognition. We can accomplish your company's highest goals with our influencer marketing strategy and more than 200 digital marketing professionals. Our clients have achieved their largest goals with our unique and bespoke digital marketing tactics, from growing their company to extending their staff. We've produced more than $1.5 million in sales and 4.6 million leads for our clients using our data-driven strategy. So, WeTech Digital is the best influencer management agency in the market. Low influencer marketing pricing increases our popularity.",
                    },
                    {
                        title: "Our Approach to Influencer Marketing Services",
                        description: "Influencer marketing is becoming a vital investment for firms since more than 90% of consumers trust an influencer's suggestion over a brand. Your company may increase brand recognition and revenue by partnering with a prominent influencer. You can achieve much more with WeTech Digital on your side. Instagram influencers are individuals who have amassed a large following and established authority in a certain specialty on the site. Consumer opinion and purchase decisions may be influenced by this authority, also known as an influence. Instagram influencers might have tens of thousands of followers or millions. So, WeTech Digital takes an attempt to become an Instagram influencer marketing agency to follow the trend. Our influencer marketing agency pricing is affordable.",
                    },
                ]
            },
            {
                title: "Newspaper/Portal Ads",
                description: "Do you need help with newspaper/portal ads? Then you are in the right place to get it.",
                image: BRANDING_9,
                url: "newspaper-portal-ads",
                data: [
                    {
                        title: "What Defines Us As The Top Newspaper/Portal Ads Agency?",
                        description: "WeTech Digital is the top Newspaper/Portal Ads Services Company in the market. Publish advertising in regional or national news sources, daily or weekly. Newspaper ads have existed longer than any other sort of advertising, and are still the initial type of advertising companies consider. In recent eras, newspapers have appeared as portals online, where advertising is also published. As a well-staged newspaper advertising agency, WeTech Digital helps customers who want to advertise. Our services are very good, which attracts attention. Our newspaper ads cost and local newspaper ads cost are very budget-friendly. For our good service, we are well known in the market. A survey shows that our website banner advertising rates are very good.",
                    },
                    {
                        title: "Our Approach to Newspaper/Portal Ads",
                        description: "WeTech Digital is devoted and sensitive to its customers. Those who require news/portal ads can look for aid from us as we are a famous newspaper advertising agency. We provide reasonable newspaper ads cost. Our local newspaper ads costs are very low. We take all the hassles on behalf of our customers; we prepare content, find a platform, do all the negotiation, and publish ads. We also have a platform of our own to publish newspaper/portal ads. Nowadays, website banner advertising rates are growing. With these increasing rates, we also provide website banner advertising services.",
                    },
                ]
            },
        ]
    },
    {
        title: "Legal Documentation",
        description: "Essential Legal Documents: Protect Your Rights and Interests with Properly Drafted Legal Agreements and Contracts.",
        url: "legal-documentation",
        contents: [
            {
                title: "Business Registration in Bangladesh",
                description: "Do you want to get Business Registration in Bangladesh? Then we're here to help you out competently.",
                image: BRANDING_10,
                url: "business-registration-in-bangladesh",
                data: [
                    {
                        title: "What Defines Us As The Top Business Registration Agency in Bangladesh?",
                        description: "WeTech Digital is the top Business Registration Agency in Bangladesh. The act of registering a business entails applying information about the company. WeTech Digital is a leading agency for business registration in Bangladesh. We also provide e-commerce business registration to e-commerce sellers. As we are also a VAT consultancy agency, we have a very expert VAT TAX consultant who can help you out. Our main focus is to give you liability protection. We offer that lawfully which gives you uniqueness and firmness. It seems to be that business registration is very costly, but we promised to do that at a comparatively low cost.",
                    },
                    {
                        title: "Our Approach to Business Registration in Bangladesh",
                        description: "WeTech Digital is dedicated to providing clients with interesting, relevant, and effective business registration and VAT consultancy services. Advertising management is a planned administrative process for overseeing and controlling the different advertising activities included in a program to connect with a company's target market to influence consumer purchase choices. Working on that purpose, our Shopify advertising management services are most reliable with affordable Shopify advertising costs. As a Shopify store development services company, we offer a wide range of Shopify advertising management. We also arrange a Shopify advertising campaign for you.",
                    },
                ]
            },
            {
                title: "License and Certification",
                description: "Do you want to get License and Certification Services in Bangladesh? If that's the case, we'll gladly assist you. WeTech Digital is here for you with its business license compliance services.",
                image: BRANDING_11,
                url: "license-and-certification-services",
                data: [
                    {
                        title: "What Defines Us As The Top License and Certification Services Agency in Bangladesh?",
                        description: "WeTech Digital is the top License and Application Services Agency in Bangladesh. Licenses and certificates demonstrate that an individual has the requisite qualifications or competence to do a task. These certifications are usually earned after you've accomplished your schooling. Achieving requirements, which typically include passing an exam, is required to obtain a license or certification. Licenses and certificates are typically only effective for a certain length of time and must be reissued on a regular basis. Either credential may be required by an employment. As likely, business licenses not just to hold corporations accountable for their activities and conduct, but they also protect the public's health and safety. Professional licenses indicate the degree of competence and knowledge possessed by a certain professional. So, business license help your business to protect it legally. Our business license compliance services are very helpful. WeTech Digital is very well known business license service provider in Bangladeshi business market. Small business certificate online are also provided by our company. Because small business is also need to be certified and need to get their license. We are here to help you with legal documentation services. If you are a businessman, you must need legal documents for business to secure it.",
                    },
                    {
                        title: "Our Approach to License and Certification Services in Bangladesh",
                        description: "WeTech Digital is dedicated to providing clients with interesting, relevant, and effective license & certification. The vast number of federal, state, and municipal authorities creates a tangle of licensing, registration and permit requirements, which vary depending on location, industry and kind of company's operations. Rules are likewise in continual flux. Up to 65% of license registration criteria change each year, and enforcement actions for business licensing breaches are on the rise. New needs may be triggered by changes to your company's activities, locations or services. If your firm does not comply, it may suffer penalties, fines, business interruption and negative publicity. So, we are here to give business license compliance services. Our business license compliance services are best in Bangladesh. We also renew business license as it is very common to expire the previous one. All of our services are cost effective.",
                    },
                ]
            },
            {
                title: "Trade Mark Application Service",
                description: "Do you want Trade Mark Application Service in Bangladesh? Then we're here to help you out competently.",
                image: BRANDING_12,
                url: "trade-mark-application-service",
                data: [
                    {
                        title: "What Defines Us As The Top Trade Mark Application Agency in Bangladesh?",
                        description: "WeTech Digital is the top Trade Mark Application Service Agency in Bangladesh. A trademark is a civil right that is basically the same as a brand name. It may be any name, term, sign, motto, or gadget that is used to identify and differentiate a company or product from others in the market. A trademark must be registered in order to be protected. As specified in section 15 of the TA 2009, any person claiming to be the proprietor of a trademark currently in use or intended to be used in Bangladesh can apply for trademark registration. Trademark registration companies are going through all kinds of hassles in order to get trademarks for clients' businesses. There are many trademark registration companies around Bangladesh; they offer trademark registration services. We have also some kind of services towards trademark registration services.",
                    },
                    {
                        title: "Our Approach to Trade Mark Application in Bangladesh",
                        description: "WeTech Digital is dedicated to providing clients with trademark documents if they want to apply through us. Our company is a trademark registration service provider in Bangladesh. We provide the chance to the clients to file an application to us to get the trademark for your business. In order to do that, we do all the procedures regarding this. But our trademark registration price is very low comparatively. If you contact us, the cost of trademark registration will not be a hindrance for you anymore because ours is cost-effective. You can also apply online through our webpage.",
                    },
                ]
            },
            {
                title: "Partnership Deed",
                description: "Do you want to get Partnership Deed in Bangladesh? Then we're here to help you out competently. WeTech Digital has the best business partnership agreement consultant team.",
                image: BRANDING_13,
                url: "partnership-deed-in-bangladesh",
                data: [
                    {
                        title: "What Defines Us As The Top business partnership agreement consultant Agency in Bangladesh?",
                        description: "WeTech Digital is the top business partnership agreement consultant Agency in Bangladesh. A partnership deed is an agreement between a firm's partners that spells out the terms and conditions of their partnership. This is what the partnership deed is for. One cannot deny the importance of business partnership agreement in collaborative business. It defines conditions like profit/loss sharing, salary, interest on capital, draws and new partner admission and so on. We offer that lawfully which give you uniqueness and firmness. Partnership deed requires a well decided name, all partners' information, related information, the amount of money each partner has put in. At first you have to apply for Partnership deed Registration. It seems to be that Partnership deed Registration is very costly, but we promised to do that at a comparatively low cost. Our partnership deed services are very popular in Bangladeshi market and it introduces us as the best partnership deed service agency in Bangladesh.",
                    },
                    {
                        title: "Our Approach to Partnership Deed in Bangladesh",
                        description: "WeTech Digital is dedicated partnership deed service agency who provides business partnership agreement. Each investor will directly contribute to the firm's equity. The firm's profits and losses will be shared evenly among the partners. Partners will not be paid a compensation for executing company tasks. There will be no interest authorized on the capitals of partners. These things should be strongly followed. WeTech Digital will help to maintain these all. Cost of Partnership deed Registration is not very much. As partnership deed service providers, we are much focused to sort out all related matters. A service partnership agreement, also known as a firm contractual relationship, is a legal document that spells out the terms and conditions of your company's connection with its service partner. So you will need service partnership agreement if you have joint business. So, you understand the importance of Business development Agreement service in your business.",
                    },
                ]
            },
        ]
    },
];

export const growthProcessKeys = [
    {
        title: 'Keyword Research',
        icon: SERVICE_1,
    },
    {
        title: 'Competitor Analysis',
        icon: SERVICE_2,
    },
    {
        title: 'On Page Optimization',
        icon: SERVICE_3,
    },
    {
        title: 'Off Page Optimization',
        icon: SERVICE_4,
    },
    {
        title: 'Detailed Analytics Reporting',
        icon: SERVICE_5,
    }];

export const serviceQuestion = {
    title: "Elevate Your Brand's Digital Success With Us!",
    data: [
        {
            title: "Why US ?",
            subTitle: "At WeTech, we're not just a digital marketing agency; we're your growth accelerator. With a dedicated team of seasoned experts, we craft bespoke strategies, create captivating content, and design user-centric websites to transform your brand's online presence. Our data-driven approach ensures measurable results, increased engagement, and enhanced ROI. Join forces with us and let's conquer the digital landscape together, one click at a time.",
        },
        {
            title: "Our Unique Propositions",
            subTitle: "At WeTech, our unique propositions redefine digital marketing. With a blend of creativity and data-driven precision, we tailor strategies to your brand's DNA. Our seasoned experts harness SEO, social media, content marketing, and web design to create a holistic online experience. We prioritize client collaboration, transparency, and measurable ROI. We're not just an agency; we're your growth partner. Discover the WeTech advantage and unlock unparalleled success in the digital realm. Your brand's journey to excellence begins here.",
        },
    ]
}


export interface IService {
    title: string;
    description: string;
    image: string;
    url: string;
    section: {
        title: string;
        description: string;
        image: string;
        url: string;
    }[];
}

export const serviceData: IService[] = [
    {
        title: 'SEO & SALES CONVERSION',
        description: "Boost Your Website's Performance and Maximize Sales Conversion Rates with Our Expert SEO Strategies and Tailored Solutions, Designed to Improve Online Visibility and Drive Targeted Traffic to Your Business.",
        image: SEO,
        url: "seo",
        section: [
            {
                title: "Organic Search Pricing",
                description: "Affordable and Effective Organic Search Pricing Plans Tailored to Boost Your Online Visibility and Growth!",
                image: SERVICE_1,
                url: "organic-search-pricing"
            },
            {
                title: "Paid Marketing Pricing",
                description: "Effective Paid Marketing Solutions Tailored to Your Business Needs – Unlock Growth and Visibility Today!",
                image: SERVICE_2,
                url: "paid-marketing-pricing"
            },
            {
                title: "Market Place Pricing",
                description: "Affordable and Flexible Marketplace Pricing Plans Tailored to Your Business Needs and Budget!",
                image: SERVICE_3,
                url: "market-place-pricing"
            },
        ],
    },
    {
        title: "SOCIAL & CREATIVE CONTENT",
        description: "Elevate Your Brand with Engaging Social and Creative Content Strategies: Harness the Power of Storytelling, Visuals, and Engagement to Foster Connections and Achieve Business Success.",
        image: SOCIAL,
        url: "social",
        section: [
            {
                title: "Social Media Pricing",
                description: "Affordable Social Media Pricing Plans Tailored to Boost Your Online Presence and Engagement.",
                image: SERVICE_1,
                url: "social-media-pricing"
            },
            {
                title: "Web Content Pricing",
                description: "Affordable Web Content Services Tailored to Your Needs – Quality, Quantity, and Value Combined!",
                image: SERVICE_2,
                url: "web-content-pricing"
            },
            {
                title: "Video & Animation",
                description: "Captivate and Engage Your Audience with Stunning Video and Animation Creations – Transform Ideas into Visual Masterpieces!",
                image: SERVICE_3,
                url: "video-animation"
            },
        ],
    },
    {
        title: "RESEARCH & OPTIMIZATION",
        description: "Comprehensive Research and Optimization Services: Harness the Power of Data and Insights to Enhance Your Strategy, Drive Growth, and Stay Ahead in Today's Competitive Landscape.",
        image: RESEARCH,
        url: "research",
        section: [
            {
                title: "Analysis",
                description: "Uncover insights, patterns, and trends with our comprehensive data analysis services. Transform data into informed decisions.",
                image: SERVICE_1,
                url: "analysis"
            },
            {
                title: "Optimization",
                description: "Unlock Peak Performance: Strategies and Techniques to Optimize Your Workflow and Maximize Productivity.",
                image: SERVICE_2,
                url: "optimization"
            },
        ],
    },
    {
        title: "DESIGN & DEVELOPMENT",
        description: "Comprehensive Design & Development Services: Transforming Your Ideas into Stunning, Functional Solutions - From Conceptualization and Prototyping to Full-Scale Implementation and Ongoing Maintenance, We've Got You Covered.",
        image: DESIGN,
        url: "design",
        section: [
            {
                title: "Design Pricing",
                description: "Affordable Pricing Plans Tailored to Your Needs for Quality Services and Excellent Value.",
                image: SERVICE_1,
                url: "design-pricing",
            },
            {
                title: "Development Pricing",
                description: "Affordable Development Pricing Tailored to Your Needs - Get Quality Development Services Without Breaking the Bank!",
                image: SERVICE_2,
                url: "development-pricing",
            },
        ],
    },
    {
        title: "BRANDING & BUSINESS SOLUTION",
        description: "Elevate Your Brand's Presence and Accelerate Business Growth with Our Comprehensive Branding and Business Solutions, Strategically Designed to Enhance Visibility, Engagement, and Success.",
        image: BRANDING,
        url: "branding",
        section: [
            {
                title: "Branding Strategy",
                description: "Crafting a Cohesive Branding Strategy to Elevate Your Business and Resonate with Your Audience.",
                image: SERVICE_1,
                url: "branding-strategy",
            },
            {
                title: "Brand Awareness",
                description: "Elevate Your Brand's Presence and Recognition with Our Proven Strategies for Lasting Brand Awareness.",
                image: SERVICE_2,
                url: "brand-awareness",
            },
            {
                title: "Legal Documentation",
                description: "Essential Legal Documents: Protect Your Rights and Interests with Properly Drafted Legal Agreements and Contracts.",
                image: SERVICE_3,
                url: "legal-documentation",
            },
        ],
    },
];


export const reachOutData = [
    {
        title: 'contact@wetechdigital.com',
        icon: GMAIL,
        link: 'mailto:contact@wetechdigital.com'
    },
    {
        title: '+1 (516) 526-8201',
        icon: PHONE,
        link: 'tel:+1 (516) 526-8201'
    },
    {
        // title: "4205 Engadina Pass, Round Rock, TX 78665, USA",
        title: "54 Wordsworth Road, Small heath, Birmingham. B10 0EE, UK.",
        icon: LOCATION,
        link: 'https://maps.app.goo.gl/B5XbqnxNY91RCxFHA'
    },
]


export const service1List = [
    {
        title: "SEO",
        subTitle: "Unlock Your Website's Potential with Expert SEO Services: Drive Traffic, Boost Rankings, and Grow Your Business Online.",
        image: SERVICE_1,
        textColor: 'group-hover:text-primary1',
        path: "/service/seo",
    },
    {
        title: "RESEARCH",
        subTitle: "Unlocking Knowledge Frontiers: Empowering Your Vision Through Comprehensive Research Solutions.",
        image: SERVICE_2,
        textColor: 'group-hover:text-primary2',
        path: "/service/research",
    },
    {
        title: "SOCIAL",
        subTitle: "Connecting Communities, Changing Lives: Our Commitment to Social Impact and Transformation.",
        image: SERVICE_3,
        textColor: 'group-hover:text-primary1',
        path: "/service/social",
    },
    {
        title: "DESIGN",
        subTitle: "Transforming Ideas into Stunning Realities: Unleash the Power of Design with Our Expert Services.",
        image: SERVICE_4,
        textColor: 'group-hover:text-primary2',
        path: "/service/design",
    },
    {
        title: "BRANDING",
        subTitle: "Elevate Your Brand Identity and Make a Lasting Impression with Our Expert Branding Solutions.",
        image: SERVICE_5,
        textColor: 'group-hover:text-primary1',
        path: "/service/branding",
    },
];

export interface IServiceExploreData {
    title: string;
    description: string;
    url: string;
    contents: {
        title: string;
        image: string;
        subContents: string[];
    }[];
}

export const serviceExploreData: IServiceExploreData[] = [
    {
        title: 'SEO Service',
        description: 'We ensure High-ranked Search Engine Optimization with top-quality service. Let’s Dig into WeTech Digital insights and learn how to get the most out of your search engine marketing efforts.',
        url: 'seo-service',
        contents: [
            {
                title: 'Why should you choose our SEO services?',
                image: WHY,
                subContents: [
                    'We have global SEO specialists (Included international SEO capabilities)',
                    'We seem more than the best SEO services provider; also we can able to handle all types of client’s needs',
                    "We seem more than an SEO company, can able to handle We combine SEO with social media, CRO, SEM, content marketing, and public relations",
                    "We use robust techniques to grow any business with an online presence",
                    "Clear and Customized reporting",
                    "We provide professional SEO service for small to large businesses"
                ]
            },
            {
                title: 'Our Unique Selling Propositions for SEO Services',
                image: PROPOSITION,
                subContents: [
                    'Result-oriented outcomes',
                    "Fulfill industry-specific requirements",
                    "We plan, execute, and refine strategy through our experts"
                ]
            },
        ]
    },
    {
        title: 'E-Commerce SEO',
        description: 'Taking your store online e-commerce solutions that work for your business and SEO is one of the most cost-effective and efficient strategies to expand your e-commerce shop and increase sales.',
        url: 'e-commerce-seo',
        contents: [
            {
                title: 'Why should you choose our E-Commerce SEO services?',
                image: WHY,
                subContents: [
                    "We provide fully prepared E-Commerce SEO experts to you to help your business expand",
                    "Ensure your website appear in the top searc",
                    "We always keep the focus on Robust security",
                    "E-Commerce SEO connects you with high-value shoppers",
                    "We optimize your product line"
                ]
            },
            {
                title: 'Our Unique Selling Propositions for E-Commerce SEO Services',
                image: PROPOSITION,
                subContents: [
                    "Custom E-Commerce SEO approaches",
                    "Keyword research and optimization",
                    "Increasing your competitive advantage",
                    "Increasing your competitive advantage",
                    "Product markup",
                    "Product SEO Copywrite",
                    "Google Analytics setup and Search Console setup",
                    "Professional blog or article copywriting",
                ]
            },
        ]
    },
    {
        title: "Local SEO",
        description: "Do you need the greatest option to help your small company flourish in your neighborhood? Our SEO Experts know how your company will get more focus locally!",
        url: 'local-seo',
        contents: [
            {
                title: 'Why should you choose our Local SEO?',
                image: WHY,
                subContents: [
                    "We provide fully prepared E-commerce SEO experts to you to help your business expand",
                    "Ensure your website appear in the top search",
                    "We always keep the focus on Robust security",
                    "E-commerce SEO connects you with high-value shoppers",
                    "We optimize your product line",
                ]
            },
            {
                title: 'Our Unique Selling Propositions for Local SEO',
                image: PROPOSITION,
                subContents: [
                    "Custom E-commerce SEO approaches",
                    "Keyword research and optimization",
                    "Increasing your competitive advantages",
                    "Product markup",
                    "Product SEO Copywrite",
                    "Google Analytics setup and Search Console setup",
                    "Professional blog or article copywriting",
                ]
            },
        ]
    },
    {
        title: "SEO Audit",
        description: "We assist you in identifying your core SEO concerns and developing a long-term approach to manage to beat your competitors.",
        url: 'seo-audit',
        contents: [
            {
                title: 'Why should you choose our SEO Audit?',
                image: WHY,
                subContents: [
                    "We have global SEO specialists (Included international SEO capabilities)",
                    "We seem more than the best SEO services provider; also we can able to handle all types of client’s needs",
                    "We use robust techniques to grow any business with an online presence",
                    "Clear and Customized reporting",
                    "We provide professional SEO services for small to large businesses.",
                    "Your Competitive SEO audit is carried out by skilled SEO strategists.",
                ]
            },
            {
                title: 'Our Unique Selling Propositions for SEO Audit',
                image: PROPOSITION,
                subContents: [
                    "Result-oriented outcomes",
                    "Fulfill industry-specific requirements",
                    "We plan, execute, and refine strategy through our experts",
                ]
            },
        ]
    },
    {
        title: "Link Building Service",
        description: "Would you like to link your website back to any other websites? Because connections signal to Google that the page is a reliable site worthy of reference, building links is one of the numerous methods employed in search engine optimization (SEO). So, recognize your site as a trustworthy link building. Services are needed. We are here to take care of link building services for you. You can approach to us for getting best link building services.",
        url: 'link-building-service',
        contents: [
            {
                title: 'Why should you choose our Link Building Service?',
                image: WHY,
                subContents: [
                    "We offers quality link building services",
                    "We have the best & flexible Teams",
                    "We give you authority",
                    "Our work gives more qualified traffics",
                    "We make sure that your brand mentions across the network",
                    "We ensure good leads and sales",
                ]
            },
            {
                title: 'Our Unique Selling Propositions for Link Building Service',
                image: PROPOSITION,
                subContents: [
                    "We have good communication and connections that get backlinks quickly",
                    "We'd like to ask permission to repost all or part of a fantastic article on your site",
                    "Increasing your competitive advantage",
                    "Meeting industry-specific needs",
                ]
            },
        ]
    },
    {
        title: "App Store Optimization",
        description: "Want to Increase Visibility Of your App on Google Display & App Store?",
        url: 'app-store-optimization',
        contents: [
            {
                title: 'Why should you choose our App Store Optimization?',
                image: WHY,
                subContents: [
                    "A detailed analysis of your app store visibility",
                    "A glimpse at your competitors' activity",
                    "To showcase your app's USPs and captivate users, employ strategic language and creative images",
                    "An increase in the organic discoverability of your app",
                    "An increase in the conversion rate of app store visitors to proactive app users",
                    "Day after day will increase more organic downloads",
                ]
            },
            {
                title: 'Our Unique Selling Propositions for App Store Optimization',
                image: PROPOSITION,
                subContents: [
                    "We convert development into profitable solutions",
                    "Result-oriented outcomes",
                    "Fulfill industry-specific requirements",
                ]
            },
        ]
    },
    {
        title: "PPC Advertising",
        description: "Did you search for a result-driven PPC advertising agency for your business? We have the best solutions for you.",
        url: "ppc-advertising",
        contents: [
            {
                title: 'Why should you choose our PPC Advertising?',
                image: WHY,
                subContents: [
                    "We have global PPC specialists (Included international & proven capabilities)",
                    "We are more than the best PPC advertising service provider; we can also handle all types of clients’ needs",
                    "Our PPC advertising services are very different from others and the outcome is very satisfactory",
                    "Clear and Customized reporting",
                    "We provide professional Google PPC ads, demanding and effective PPC advertising solutions for small to large businesses",
                ]
            },
            {
                title: 'Our Unique Selling Propositions for PPC Advertising',
                image: PROPOSITION,
                subContents: [
                    "Result-oriented outcomes as a local & global PPC agency",
                    "Fulfill industry-specific requirements",
                    "We plan, execute, and refine strategy through our experts",
                    "With our service, you can rely on us as a Google PPC agency",
                ]
            },
        ],
    },
    {
        title: "e-commerce PPC management",
        description: "Taking your store online & helping to build practical e-commerce PPC solutions that work for your business. Grab our e-commerce PPC services.",
        url: "e-commerce-ppc-management",
        contents: [
            {
                title: 'Why should you choose our E-Commerce PPC management?',
                image: WHY,
                subContents: [
                    "Ensure your website has Payment system integration",
                    "We always keep the focus on Robust security",
                    "We can manage PHP code and configure your online store's frontend and backend",
                    "We seem more than the best PPC advertising service provider; we can also handle all types of client’s needs",
                    "We use robust techniques to grow any business with an online presence",
                    "Clear and Customized reporting",
                    "We provide professional Google PPC ads demanding and effective PPC advertising solutions for small to large businesses",
                ]
            },
            {
                title: 'Our Unique Selling Propositions for E-Commerce PPC management',
                image: PROPOSITION,
                subContents: [
                    "Result-oriented outcomes as a local & global E-commerce PPC agency",
                    "Fulfill industry-specific requirements",
                    "We provide affordable PPC management packages",
                    "We plan, execute, and refine strategy through our experts",
                ]
            },
        ],
    },
    {
        title: "Google Display Ads",
        description: "Do you want to meet too many local leads, maximize traffic flow, and boost maximum conversions for your company? Then you are in the right place to get Google Display Ads.",
        url: "google-display-ads",
        contents: [
            {
                title: 'Why should you choose our Display Ads?',
                image: WHY,
                subContents: [
                    "We have a dedicated team who always keeps track of Local and global Marketing expertise",
                    "Target potential customer for your service",
                    "Our Google Display Ads campaign is low-cost compare to other kinds of marketing",
                    "Improve operational efficiency and customer satisfaction",
                    "Enhance the experience and improve operational efficiency",
                    "Mitigate risk",
                ]
            },
            {
                title: 'Our Unique Selling Propositions for Display Ads',
                image: PROPOSITION,
                subContents: [
                    "We convert campaigns into profitable solutions",
                    "Result-oriented outcomes",
                    "Fulfill industry-specific requirements",
                    "Focused and top-notched Google display advertising",
                ]
            },
        ],
    },
    {
        title: "Local Search Ads Management",
        description: "Do you want to enhance local leads, traffic volume, and conversions for your company? If that's the case, Google Local Services advertisements are a wise investment, and WeTech Digital makes ad administration a breeze. We have the best solutions for you.",
        url: "local-search-ads-management",
        contents: [
            {
                title: 'Why should you choose our Local Search Ads?',
                image: WHY,
                subContents: [
                    "We confirm your eligibility",
                    "Prepare a business profile",
                    "Take care on adding license & insurance details",
                    "Make a friendly budget",
                    "CCreate a way for you to track appointments easily",
                ]
            },
            {
                title: 'Our Unique Selling Propositions for Local Search Ads',
                image: PROPOSITION,
                subContents: [
                    "Give you a way to get more valuable leads",
                    "We make sure that you only pay for valid leads",
                    "Fulfill local industry-specific requirements",
                    "We plan, execute, and refine strategy through our experts",
                ]
            },
        ],
    },
    {
        title: "Amazon SEO & Product Optimization",
        description: "Do you want to optimize your product listing so that it appears near the top of Amazon search results? Then we are here to please you providing Amazon optimization services professionally.",
        url: "amazon-seo-product-optimization",
        contents: [
            {
                title: 'Why should you choose our Amazon SEO?',
                image: WHY,
                subContents: [
                    "We offer the best Amazon listing optimization service in the field",
                    "We provide affordable services cost",
                    "Rates in stock are being monitored by our experts",
                    "We arrange different services for different platforms",
                    "Provide unique ad postings",
                ]
            },
            {
                title: 'Our Unique Selling Propositions for Amazon SEO',
                image: PROPOSITION,
                subContents: [
                    "As internet marketing service providers, we take care of your image in the market field",
                    "Monitoring Amazon's inventory planning",
                    "Continuously improving product pages optimization",
                    "Videos and images are being added regularly in this",
                    "Designing custom content with high technology",
                ]
            },
        ],
    },
    {
        title: "Amazon Advertising Management",
        description: "WeTech Digital's Amazon PPC management services may help you advertise on Amazon. We'll help your company decrease its Advertising Cost of Sale while boosting sales and market share with our bespoke and data-driven tactics.",
        url: "amazon-advertising-management",
        contents: [
            {
                title: 'Why should you choose our Amazon Advertising?',
                image: WHY,
                subContents: [
                    "We offer the best Amazon advertising management service in the field",
                    "We offer amazon marketing service prices cheaply",
                    "We have many brands and product ads that have sponsored this event",
                    "Rates in stock are being monitored by our experts",
                    "We arrange different services for different platforms",
                    "Provide unique ad postings give the",
                ]
            },
            {
                title: 'Our Unique Selling Propositions for Amazon Advertising',
                image: PROPOSITION,
                subContents: [
                    "As internet marketing service providers, we take care of your image in the market field",
                    "A professional's expertise in Amazon marketing",
                    "Monitoring Amazon's inventory planning",
                    "Reach to target clients with the related product on Amazon",
                    "Continuously improving product pages optimization. Our Recent Projects",
                ]
            },
        ],
    },
    {
        title: "Facebook Marketplace for Business",
        description: "Want to sell more items by diversifying your online sales platforms? WeTech Digital can assist you in generating income on the Facebook Marketplace for Business, which provides you with access to over 800 million shoppers worldwide.",
        url: "facebook-marketplace-for-business",
        contents: [
            {
                title: 'Why should you choose our Facebook Marketplace?',
                image: WHY,
                subContents: [
                    "Even if you don't post directly on Marketplace, we make sure to advertise your shop or products there",
                    "Our retail products, house rents, automobiles, and event tickets are all on exhibition",
                    "We provide affordable services cost",
                    "We arrange different services for different platforms",
                    "Provide social media postings that are unique",
                ]
            },
            {
                title: 'Our Unique Selling Propositions for Facebook Marketplace',
                image: PROPOSITION,
                subContents: [
                    "We let consumers buy without having to leave Facebook",
                    "Freely advertise new or reconditioned products from your Facebook Page store on Marketplace",
                    "As a Facebook marketplace for business service providers, we take care of your image in the market field",
                    "Designing custom content with high technology",
                    "We offer social media network establishment, optimization, and identity auditing services. Our Recent Projects",
                ]
            },
        ],
    },
    {
        title: "Shopify Optimization Services",
        description: "Do you want to get Shopify Optimization Services? Then we are here to please you providing that professionally.",
        url: "shopify-optimization-services",
        contents: [
            {
                title: 'Why should you choose our Shopify Optimization?',
                image: WHY,
                subContents: [
                    "We construct an effective product stream",
                    "We have Shopify Store Research and Development",
                    "We provide affordable services cost",
                    "We arrange different services for different platforms",
                ]
            },
            {
                title: 'Our Unique Selling Propositions for Shopify Optimization',
                image: PROPOSITION,
                subContents: [
                    "As much as targeted keywords require we provide",
                    "We offer optimization of themes and subthemes",
                ]
            },
        ],
    },
    {
        title: "Shopify Advertising Agency",
        description: "Do you want Shopify advertising management services? Then we are here to please you by providing that professionally. Our Shopify advertising agency is famous in the market.",
        url: "shopify-advertising-agency",
        contents: [
            {
                title: 'Why should you choose our Shopify Advertising?',
                image: WHY,
                subContents: [
                    "Assist you in comprehending target industry",
                    "We provide work that is more efficient",
                    "We arrange different services for different platform",
                    "Vast promotional campaign",
                    "Provide best Shopify advertising",
                ]
            },
            {
                title: 'Our Unique Selling Propositions for Shopify Advertising',
                image: PROPOSITION,
                subContents: [
                    "We examine the sales strategies and outcomes of your rivals",
                    "We provide adaptable and personalized services",
                    "Designing custom content with high technology",
                    "Well prepared media planning",
                ]
            },
        ],
    },
    {
        title: "Social Media Management Service",
        description: "Do you want well to build up social media marketing & management services? Then we are here to please you providing that professionally.",
        contents: [
            {
                title: 'Why should you choose our Social Media Management?',
                image: WHY,
                subContents: [
                    "We provide affordable social media management services cost",
                    "We arrange different services for different platforms",
                    "Provide social media postings that are unique",
                    "Website copy analysis",
                    "Solving your problems steadily",
                    "Delivery to you after we are satisfied",
                    "Will work on it again if you are still not satisfied",
                ]
            },
            {
                title: 'Our Unique Selling Propositions for Social Media Management',
                image: PROPOSITION,
                subContents: [
                    "Designing custom pictures, covers, and profile photos",
                    "We offer social media network establishment, optimization, and identity auditing services",
                    "We offer brand reputation analysis, improved post-ad management, and more",
                    "Data-driven social strategy, in-depth competition analysis",
                ]
            },
        ],
        url: "social-media-management-service"
    },
    {
        title: "Social Media Design",
        description: "Are you looking for best social media design service? High-quality content and consistent branding are the cornerstones of effective social media marketing. Because social media is a visual channel for your business, neither is feasible without good design work. WeTech Digital has affordable social media design package.",
        contents: [
            {
                title: 'Why should you choose our Social Media Design?',
                image: WHY,
                subContents: [
                    "We offer best social media design pricing",
                    "We examine the concept from a variety of perspective",
                    "We customize your social media accounts, sites, and apps to match your website and brand guidelines",
                    "Make it mobile-friendly",
                    "We are eager to keep contacts with you while working and also positive with your opinion",
                ]
            },
            {
                title: 'Our Unique Selling Propositions for Social Media Design',
                image: PROPOSITION,
                subContents: [
                    "Personalized social media design strategies for businesses wanting to expand their social media presence across many social platforms",
                    "Provide professional social media design services that pay close attention to detail and satisfy your social media profile requirements",
                    "Meet industry-specific specifications",
                    "Maximize your competitive edge",
                    "Fulfill industry-specific requirements",
                ]
            },
        ],
        url: "social-media-design"
    },
    {
        title: "Social Media Advertising",
        description: "Are you looking for the best social media advertising packages? WeTech Digital is a prominent social media marketing agency that specializes in using social media to help businesses develop. Our social media advertising services may help you achieve your objectives, whether you want to improve your brand's social media presence or create targeted leads through social media advertising.",
        contents: [
            {
                title: 'Why should you choose our Social Media Advertising?',
                image: WHY,
                subContents: [
                    "We provide affordable social media management services cost",
                    "We arrange different services for different platforms",
                    "Provide social media postings that are unique",
                    "Website copy analysis",
                    "Solving your problems steadily",
                    "Delivery to you after we are satisfied",
                    "Will work on it again if you are still not satisfied",
                ]
            },
            {
                title: 'Our Unique Selling Propositions for Social Media Advertising',
                image: PROPOSITION,
                subContents: [
                    "Designing custom pictures, covers, and profile photos",
                    "We offer social media network establishment, optimization, and identity auditing services",
                    "We offer brand reputation analysis, improved post-ad management, and more",
                    "Data-driven social strategy, in-depth competition analysis",
                ]
            },
        ],
        url: "social-media-advertising"
    },
    {
        title: "e-commerce Social Advertising",
        description: "Do you want e-commerce Social Advertising Services? Then we're here to make you happy by offering that service in a professional manner. It is very essential for your business success.",
        contents: [
            {
                title: 'Why should you choose our E-Commerce Social Advertising?',
                image: WHY,
                subContents: [
                    "Assist you in comprehending the target industry",
                    "We provide work that is more efficient",
                    "We arrange different services for different platforms",
                    "Vast promotional campaign",
                    "We have a fully focused advertising manager",
                    "Unique advertising idea",
                ]
            },
            {
                title: 'Our Unique Selling Propositions for E-Commerce Social Advertising',
                image: PROPOSITION,
                subContents: [
                    "Substantial assistance from members of the social team who are experts on each network",
                    "We use customer records or social links can be used to create bespoke audiences",
                    "Cost-effective campaign arrangements",
                    "The special management and optimization services",
                ]
            },
        ],
        url: "e-commerce-social-advertising"
    },
    {
        title: "YouTube Advertising Services",
        description: "Are you looking for best YouTube advertising agency? YouTube is a large social media platform and your company can utilise it to raise brand recognition, acquire leads and create sales using YouTube advertising services. Join forces with Wetech Digital to develop a cutting-edge marketing campaign that produces results and a strong market position.",
        contents: [
            {
                title: 'Why should you choose our YouTube Advertising?',
                image: WHY,
                subContents: [
                    "We offer best YouTube advertising service pricing",
                    "We give Ad creative performance testing",
                    "Make it mobile-friendly",
                    "We are eager to keep contacts with you while working and also positive with your opinion",
                ]
            },
            {
                title: 'Our Unique Selling Propositions for YouTube Advertising',
                image: PROPOSITION,
                subContents: [
                    "Provide professional YouTube advertising service that pay close attention to detail and satisfy your requirements",
                    "Meet industry-specific specifications",
                    "Maximize your competitive edge",
                    "Fulfill industry-specific requirements",
                ]
            },
        ],
        url: "youtube-advertising-services"
    },
    {
        title: "Website SEO Copywriting",
        description: "WeTech Digital is a top SEO copywriting agency that helps you achieve online and offline accomplishment by generating original, appealing copy for anything from blog posts to sales material to online tutorials. Our website SEO copywriting services can help your business reach its objectives, whether it's to improve engagement, online traffic, or sales. Check out our service and price options underneath to understand more about our industry-leading online copywriting services.",
        url: "website-seo-copywriting",
        contents: [
            {
                title: 'Why should you choose our SEO Copywriting?',
                image: WHY,
                subContents: [
                    "We optimize your content for readers and search engine",
                    "We provide service packages for clients’ websites",
                    "We always keep the focus on Robust security",
                    "Very user-friendly, customizable, sales focused & technical SEO website Copywrite services",
                ]
            },
            {
                title: 'Our Unique Selling Propositions for SEO Copywriting',
                image: PROPOSITION,
                subContents: [
                    "Provide distinct types of SEO copywriting for your website",
                    "Also working on E-commerce copywriting",
                    "Fulfill industry-specific requirements",
                    "Our SEO copywriting price is very affordable",
                ]
            },
        ],
    },
    {
        title: "Content Marketing services",
        description: "Our content marketing services make content marketing simple. Our professional team produces, writes, edits, and promotes unique as well as search engine compatible, content for your business as part of our content marketing service packages. WeTech Digital can handle everything from blog entries to online instructions to videos.",
        url: "content-marketing-services",
        contents: [
            {
                title: 'Why should you choose our Content Marketing?',
                image: WHY,
                subContents: [
                    "We offer the best hands-on approach, a tailored plan, and a project manager who is devoted",
                    "We examine the concept from a variety of perspectives",
                    "We may also create bespoke solutions to meet your company's specific requirements",
                    "Make it mobile-friendly",
                    "We are eager to keep in contact with you while working and also positive about your opinion",
                ]
            },
            {
                title: 'Our Unique Selling Propositions for Content Marketing',
                image: PROPOSITION,
                subContents: [
                    "We work with your organization to gain a thorough understanding of your firm's mission, goals, and industry",
                    "We assist you in creating a unique content marketing plan",
                    "We meant to aid in the ranking of your website's content in search engine results and the conversion of consumers",
                    "We increase the amount of high-quality visitors to your website",
                    "Maximize your competitive edge",
                    "Fulfill industry-specific requirements",
                ]
            },
        ],
    },
    {
        title: "Personalized Web Content",
        description: "Do you want good personalized web content management services? Then we are here to please you providing that professionally.",
        url: "personalized-web-content",
        contents: [
            {
                title: 'Why should you choose our Personalized Web Content?',
                image: WHY,
                subContents: [
                    "We provide affordable social media management services cost",
                    "In real-time, analyze visitor behavior and respond with relevant calls to action",
                    "Using demographic and firmographics data, we personalize offers",
                    "Website copy analysis",
                    "Will work on it again if you are still not satisfied",
                ]
            },
            {
                title: 'Our Unique Selling Propositions for Personalized Web Content',
                image: PROPOSITION,
                subContents: [
                    "Substantial assistance from members of the social team who are experts on each network",
                    "We use customer records or social links can be used to create bespoke audiences",
                    "Cost-effective campaign arrangements",
                    "The special management and optimization services. Always send the appropriate message to the appropriate person at the appropriate time",
                    "Designing custom pictures, covers, and profile photos",
                    "We offer social media network establishment, optimization, and identity auditing services",
                    "Our website personalization strategy is satisfying",
                ]
            },
        ],
    },
    {
        title: "Web Video Production Services",
        description: "Do you want well to build up web video production services? Then we are here to please you providing that professionally.",
        url: "web-video-production-services",
        contents: [
            {
                title: 'Why should you choose our Web Video Production?',
                image: WHY,
                subContents: [
                    "Professional whiteboard films are available to assist you in engaging and converting more consumers",
                    "We are a corporate video production company",
                    "We provide affordable services cost",
                    "We arrange different services for different platforms",
                    "Provide social media postings that are unique",
                ]
            },
            {
                title: 'Our Unique Selling Propositions for Web Video Production',
                image: PROPOSITION,
                subContents: [
                    "As internet marketing service providers, we take care of your image in the market field",
                    "Designing custom content with high technology",
                    "We offer social media network establishment, optimization, and identity auditing services",
                    "We offer brand reputation analysis, improved post-ad management, and more",
                ]
            },
        ],
    },
    {
        title: "Product Photography Services",
        description: "Any image of a product for sale qualifies as product photography. Do you want product photography services? Then we are here to please you providing that services professionally.",
        url: "product-photography-services",
        contents: [
            {
                title: 'Why should you choose our Product Photography?',
                image: WHY,
                subContents: [
                    "We provide originality and innovation in all we do",
                    "We provide affordable services cost",
                    "We provide high-resolution photographs",
                    "Our service is quick and efficient",
                    "We arrange different services for different platform",
                ]
            },
            {
                title: 'Our Unique Selling Propositions for Product Photography',
                image: PROPOSITION,
                subContents: [
                    "For your website, an onsite photo-shoot of the business",
                    "Theme shot for a magazine and leaflet",
                    "Trade show photography that tells a narrative visually",
                    "For your product, videography is required",
                    "You will have complete copyright to all photographs",
                ]
            },
        ],
    },
    {
        title: "Promo Video Production",
        description: "Would you like to use video to raise brand recognition and sales?? Then we're here to help you with that and with a video production price list that has different level prices.",
        url: "promo-video-production",
        contents: [
            {
                title: 'Why should you choose our Promo Video?',
                image: WHY,
                subContents: [
                    "We always balancing customer requirements",
                    "High-quality technology",
                    "We provide more efficient work",
                    "We arrange different services for different platforms",
                    "We have affordable service costs",
                ]
            },
            {
                title: 'Our Unique Selling Propositions for Promo Video',
                image: PROPOSITION,
                subContents: [
                    "Affordable promo video prices",
                    "No third-party interference",
                    "Portrait messages very clearly",
                    "Various platform types of promo video production",
                    "For smooth video production, automated procedures are used",
                ]
            },
        ],
    },
    {
        title: "Infographics and Motion Graphics",
        description: "Infographics are a great way to deliver material to your audience in a fun, interactive manner, and they're also far more likely to go viral and get a lot of attention than other forms of content. Our principal aim is to give you the best infographics and motion graphics.",
        url: "infographics-and-motion-graphics",
        contents: [
            {
                title: 'Why should you choose our Infographics and Motion?',
                image: WHY,
                subContents: [
                    "Throughout the visual information creation process, our Infographic designers keep this in mind",
                    "We pay close attention to customer feedback at all times",
                    "We examine your matter with care",
                    "We create visuals that are both entertaining and informative",
                    "We are eager to keep in contact with you while working and also optimistic about your opinion",
                ]
            },
            {
                title: 'Our Unique Selling Propositions for Infographics and Motion',
                image: PROPOSITION,
                subContents: [
                    "Optimize your user Experience as a unique user-friendly website",
                    "Our data visualization graphics are easy to understand and captivating",
                    "We recommend the best fit for you depending on your marketing objectives",
                    "Fulfill industry-specific requirements",
                    "Help to grow local & international business",
                ]
            },
        ],
    },
    {
        title: "Animation Video Service (2D & 3D)",
        description: "Do you need an Animation video Service? Then we're here to help you out competently with our creativity.",
        url: "animation-video-service-2d-3d",
        contents: [
            {
                title: 'Why should you choose our Animation Video?',
                image: WHY,
                subContents: [
                    "We always balancing customer requirements",
                    "We provide more efficient work",
                    "We arrange different services for different platforms",
                    "We have affordable service costs",
                    "We give animation services in 2D and 3D of the highest quality",
                ]
            },
            {
                title: 'Our Unique Selling Propositions for Animation Video',
                image: PROPOSITION,
                subContents: [
                    "Creative, unique, attractive, meaningful storyline",
                    "Specialists who are both developers and givers of concepts",
                    "Customized works",
                    "Vast working experiences",
                ]
            },
        ],
    },
    {
        title: "Character Animation Services",
        description: "Do you want Character animation services? Here, we are to help you.",
        url: "character-animation-services",
        contents: [
            {
                title: 'Why should you choose our Character Animation?',
                image: WHY,
                subContents: [
                    "We always balancing customer requirements",
                    "We provide more efficient work",
                    "We have a personal character design studio",
                    "We arrange different services for different platforms",
                    "We have affordable service costs",
                ]
            },
            {
                title: 'Our Unique Selling Propositions for Character Animation',
                image: PROPOSITION,
                subContents: [
                    "We have well-structured software",
                    "We give importance to customer opinion",
                    "Guarantees that your character animation will be unique",
                    "Best Developers and concept makers",
                ]
            },
        ],
    },
    {
        title: "3D Product Visualization",
        description: "Do you want high-quality 3D product visualization services? Then we will offer you, professionally, exactly what you need.",
        url: "3d-product-visualization",
        contents: [
            {
                title: 'Why should you choose our 3D Product?',
                image: WHY,
                subContents: [
                    "We always balancing customer requirements",
                    "Help you understand the target industry",
                    "We deliver more efficient work",
                    "We offer cost-effective services",
                    "For various platforms, we arrange different services",
                ]
            },
            {
                title: 'Our Unique Selling Propositions for 3D Product',
                image: PROPOSITION,
                subContents: [
                    "We focused on rich quality",
                    "We provide adaptable and personalized services",
                    "Designing custom content with high technology",
                    "We consider customer preference",
                ]
            },
        ],
    },
    {
        title: "CRM ROI",
        description: "Choose to make much use of your sales and marketing data to boost your small or midsized business's profitability? Raise a toast to CRM ROI, a sales management system designed for small sales teams with big ambitions.",
        url: "crm-roi",
        contents: [
            {
                title: 'Why should you choose our CRM ROI?',
                image: WHY,
                subContents: [
                    "We offer simple pipeline administration",
                    "We take care of everything and from interactivity to buy",
                    "Keep track of your sales funnel",
                    "We always take a good look at client's reviews",
                    "We review the idea in many aspects",
                    "Increase the number of transactions you close",
                ]
            },
            {
                title: 'Our Unique Selling Propositions for CRM ROI',
                image: PROPOSITION,
                subContents: [
                    "We keep track of your marketing budget's return on investment in real-time",
                    "We follow your leads throughout their entire lifespan, beginning with their initial visit to your website or brand",
                    "We provide lead management that runs smoothly",
                    "Maximize your competitive edge",
                    "Fulfill industry-specific requirements",
                ]
            },
        ],
    },
    {
        title: "Lead Management Solutions",
        description: "Did you know that leads that are reached within five minutes have a 90% chance of becoming sales? It's difficult to organize, prioritize, and follow up on leads without a good lead management system. Fortunately, WeTech Digital — our clients' proprietary lead management software — can assist you in converting leads into increased sales and money for your small or mid-sized firm. As the top lead management company, you can put your faith in us.",
        url: "lead-management-solutions",
        contents: [
            {
                title: 'Why should you choose our Lead Management?',
                image: WHY,
                subContents: [
                    "We have qualified lead management software",
                    "Prepare a plan of short & long-term budget",
                    "Very user-friendly & customizable lead management system",
                    "Our product is well integrated",
                ]
            },
            {
                title: 'Our Unique Selling Propositions for Lead Management',
                image: PROPOSITION,
                subContents: [
                    "Fulfill local industry-specific requirements",
                    "Provide distinct types of software based on needs",
                    "Our lead management software is available for a wide range of business sizes",
                ]
            },
        ],
    },
    {
        title: "Custom SEO Reporting",
        description: "Do you need custom SEO report services? Our SEO Experts know how your company will get more focus locally!",
        url: "custom-seo-reporting",
        contents: [
            {
                title: 'Why should you choose our Custom SEO?',
                image: WHY,
                subContents: [
                    "We invigilate at SEO ranking check service",
                    "We provide custom SEO analysis services at a low cost",
                    "We have SEO specialists (Included international SEO capabilities) who can guide you",
                    "We appear to be more than just the top local SEO services supplier; we can also manage a wide range of customer requirements in local markets",
                    "We have well-researched keywords",
                    "We use robust techniques to grow any business with an online presence",
                    "We monitor ratings and reviews and also create links",
                    "We consider user reviews most to give them a friendly experience",
                ]
            },
            {
                title: 'Our Unique Selling Propositions for Custom SEO',
                image: PROPOSITION,
                subContents: [
                    "We offer Google website ranking check services",
                    "As it is easy to track, our SEO team makes you visible on top",
                    "We have lead creation and sales development for organic and paid keywords",
                    "We provide ROI advisory services",
                    "Meet industry-specific criteria in a specific area",
                    "Our specialists help you to plan, execute and revise strategies",
                ]
            },
        ],
    },
    {
        title: "Conversion Rate Optimization",
        description: "Do you want to raise the number of people who complete the desired action on a website? We are here to please you only by speeding up your page’s Conversion Rate Optimization professionally.",
        url: "conversion-rate-optimization",
        contents: [
            {
                title: 'Why should you choose our Conversion Rate?',
                image: WHY,
                subContents: [
                    "We do usability analysis",
                    "We do a conversion analysis on ‘internet marketing",
                    "Conversion funnel analysis",
                    "Provide an affordable conversion rate optimization services pricing",
                    "Website copy analysis",
                    "Aesthetic analysis",
                    "Solving your problems steadily",
                    "Again testing the results",
                    "Delivery to you after we are satisfied",
                    "Will work on it again if you are still not satisfied",
                ]
            },
            {
                title: 'Our Unique Selling Propositions for Conversion Rate',
                image: PROPOSITION,
                subContents: [
                    "We convert optimization into profitable solutions",
                    "We increase revenue with a custom CRO package",
                    "Result-oriented outcomes",
                    "Fulfill requirements in the best manner",
                ]
            },
        ],
    },
    {
        title: "Page Speed Optimization",
        description: "Do you want to make your web page most visible and visited by users? Then, we are here to please you only by speeding up your page’s speed professionally.",
        url: "page-speed-optimization",
        contents: [
            {
                title: 'Why should you choose our Page Speed?',
                image: WHY,
                subContents: [
                    "Attentive testing is conducted for your page",
                    "A detailed analysis of your page speed",
                    "Ensure your website has what problem",
                    "We always keep the focus on Robust security",
                    "Solving your problems steadily",
                    "Again testing the results",
                    "Delivery to you after we are satisfied",
                    "I Will work on it again if you are still not satisfied",
                ]
            },
            {
                title: 'Our Unique Selling Propositions for Page Speed',
                image: PROPOSITION,
                subContents: [
                    "We convert optimization into profitable solutions",
                    "Result-oriented outcomes",
                    "Fulfill requirements in the best manner",
                ]
            },
        ],
    },
    {
        title: "UX Design for Website",
        description: "We design user experience design as your thinking and consider the client’s review. As a result, top-rated companies choose our Unique set of creating solutions for their products.",
        url: "ux-design-for-website",
        contents: [
            {
                title: 'Why should you choose our UI/UX Design?',
                image: WHY,
                subContents: [
                    "Our talented UX designers will work with you to create the right design",
                    "We take care of everything and more",
                    "We always take a good look at client’s reviews",
                    "We review the idea in many aspects",
                    "We are eager to keep in about contact with you while working and also positive about optimistic your opinion",
                ]
            },
            {
                title: 'Our Unique Selling Propositions for UI/UX Design',
                image: PROPOSITION,
                subContents: [
                    "Optimize your user Experience",
                    "We convert our best UX website design into profitable solutions",
                    "Maximize your competitive edge",
                    "Increse the effeictiveness of your digital marketing plan",
                    "Fulfill industry-specific requirements",
                    "Help to grow local & international business",
                ]
            },
        ],
    },
    {
        title: "User Experience Testing",
        description: "Are you looking for strategies to boost your profits? A positive online user experience (UX) results in more visits, customers, and money. Our user experience testing method made that possible for you.",
        url: "user-experience-testing",
        contents: [
            {
                title: 'Why should you choose our User Experience Testing?',
                image: WHY,
                subContents: [
                    "We improve the navigational flow of your website",
                    "Our talented UX designers will work with you to create the right design",
                    "We always take a good look at client’s reviews",
                    "We examine the concept from a variety of perspectives",
                    "Encourage people to come back",
                    "Remove any technical flaws",
                    "Reduce the cost of customer assistance by lowering the cost of maintenance",
                    "Increase your conversion rates",
                    "Boost your revenue",
                    "We are eager to keep in contact with you while working and also optimistic about your opinion",
                ]
            },
            {
                title: 'Our Unique Selling Propositions for User Experience Testing',
                image: PROPOSITION,
                subContents: [
                    "Reduce the time it takes for pages to load",
                    "Reduce the amount of time it takes to complete specific tasks",
                    "Maintain a user-friendly interface",
                    "Optimize your user Experience following two methods accordingly",
                    "We convert our best UX testing for your better good as profitable solutions",
                    "Maximize your competitive edge",
                    "Fulfill industry-specific requirements",
                ]
            },
        ],
    },
    {
        title: "Landing Page Design",
        description: "Are you looking for strategies to increase your sales and boost your profits? Landing pages are a crucial component of any marketing and advertising strategy. Using skilled landing page design services may help you improve the impact of your landing pages. Our experienced Landing page creators are here to help you out.",
        url: "landing-page-design",
        contents: [
            {
                title: 'Why should you choose our Landing Page Design?',
                image: WHY,
                subContents: [
                    "We have best Landing Page Development Teams",
                    "We provide our focus sincerely on any product",
                    "We make headlines that will catch people's attention",
                    "We establish a landing page design that is consistent",
                    "We examine the concept from a variety of perspectives",
                    "Make strong appeals to action (CTAs)",
                    "Integrate cues of trust",
                    "Increase your conversion rates",
                    "We boost your revenue",
                    "Make it mobile-friendly",
                    "Keep forms to a minimum and include at least one image",
                    "We are eager to keep contacts with you while working and also positive with your opinion",
                ]
            },
            {
                title: 'Our Unique Selling Propositions for Landing Page Design',
                image: PROPOSITION,
                subContents: [
                    "We turn campaigns into money-making solutions by landing page designing services",
                    "Outcomes that are aimed towards achieving a certain goal",
                    "Meet industry-specific specifications",
                    "Maximize your competitive edge",
                    "Fulfill industry-specific requirements",
                ]
            },
        ],
    },
    {
        title: "Web Development",
        description:
            "Our website Development agency is transforming the way brands connect with audiences in a digital world. Learn why market leaders choose our Unique set of development and solutions.",
        url: "web-development",
        contents: [
            {
                title: 'Why should you choose our Web Development?',
                image: WHY,
                subContents: [
                    "We believe quality service and our team develops accurately and accurately. We make sure our customers' needs are met by the end product",
                    "Even after completion of the project, our development team will be available",
                    "We will adjust our working hours to ensure that we overlap with your time zone, depending where you are",
                    "We provide customized Website Development solutions to satisfy customers worldwide, ranging from start-ups to established businesses searching for exceptional website design services with minimal development cost",
                ]
            },
            {
                title: 'Our Unique Selling Propositions for Web Development',
                image: PROPOSITION,
                subContents: [
                    "We convert development into profitable solutions",
                    "Result-oriented outcomes",
                    "Fulfill industry-specific requirements",
                    "We offer social media network establishment, optimization and identity auditing services",
                ]
            },
        ],
    },
    {
        title: "E-commerce Development",
        description:
            "Taking your store online & help to build practical e-commerce solutions that work for your business",
        url: "e-commerce-development",
        contents: [
            {
                title: 'Why should you choose our E-Commerce Development?',
                image: WHY,
                subContents: [
                    "We provide fully prepared e-commerce websites and drop shipping websites as an e-commerce development company to help your business expand",
                    "Ensure your website has Payment system integration",
                    "We always keep the focus on Robust security",
                    "We can manage PHP code and configure your online store's frontend and backend",
                ]
            },
            {
                title: 'Our Unique Selling Propositions for E-Commerce Development',
                image: PROPOSITION,
                subContents: [
                    "Custom e-commerce design and development",
                    "Payment gateway integration",
                    "SAAS e-commerce solutions",
                    "Build a multi-store e-commerce platform",
                    "We offer social media network establishment, optimization and identity auditing services",
                ]
            },
        ],
    },
    {
        title: "Portfolio Site",
        description:
            "Would you like to get work as a freelancer, get more clients for your firm, or get a job? A portfolio site is a digital version of a freelancer's (or firm's) resume. It gives potential clients an easy method to see your work while also allowing you to broaden your talents and services. This isn't, however, the primary goal of a portfolio website. Our portfolio site experts can help you out.",
        url: "portfolio-site",
        contents: [
            {
                title: 'Why should you choose our Portfolio Site?',
                image: WHY,
                subContents: [
                    "We have best & flexible Teams",
                    "We save & reuse; so maintain a library",
                    "We have the best personal websites",
                    "We make headlines that will catch people's attention",
                    "Clear and Customized reporting",
                    "We examine the concept from a variety of perspectives",
                    "Make strong appeals to action (CTAs)",
                    "Integrate cues of trust",
                    "Increase your conversion rates",
                    "Make it mobile-friendly",
                    "We are eager to keep in contact with you while working and also positive about your opinion",
                ]
            },
            {
                title: 'Our Unique Selling Propositions for Portfolio Site',
                image: PROPOSITION,
                subContents: [
                    "Aiming to achieve a specific objective",
                    "Increasing your competitive advantage",
                    "Meeting industry-specific needs",
                ]
            },
        ],
    },
    {
        title: "Mobile Application Development",
        description:
            "Explore our expertise in mobile application development. We craft innovative and user-centric mobile apps tailored to your business needs for seamless user experiences.",
        url: "mobile-application-development",
        contents: [
            {
                title: 'Why should you choose our Mobile Application?',
                image: WHY,
                subContents: [
                    "We don't just create applications; we deliver game-changing digital solutions that offer your company a strategic advantage while driving innovation and development",
                    "Differentiate from your competition",
                    "We always keep the focus on Robust security",
                    "Accelerate time to value",
                    "Improve operational efficiency and customer satisfaction",
                    "Enhance the experience and improve operational efficiency",
                    "Mitigate risk",
                ]
            },
            {
                title: 'Our Unique Selling Propositions for Mobile Application',
                image: PROPOSITION,
                subContents: [
                    "We convert development into profitable solutions",
                    "Result-oriented outcomes",
                    "Fulfill industry-specific requirements",
                    "Designing custom pictures, covers, and profile photos",
                ]
            },
        ],
    },
    {
        title: "Shopify Advertising Agency",
        description:
            "Do you want Shopify advertising management services? Then we are here to please you by providing that professionally. Our Shopify advertising agency is famous in the market.",
        url: "shopify-advertising-agency",
        contents: [
            {
                title: 'Why should you choose our Shopify Advertising?',
                image: WHY,
                subContents: [
                    "Assist you in comprehending target industry",
                    "We provide work that is more efficient",
                    "We arrange different services for different platform",
                    "Vast promotional campaign",
                    "Provide best Shopify advertising",
                ]
            },
            {
                title: 'Our Unique Selling Propositions for Shopify Advertising',
                image: PROPOSITION,
                subContents: [
                    "We examine the sales strategies and outcomes of your rivals",
                    "We provide adaptable and personalized services",
                    "Designing custom content with high technology",
                    "Well prepared media planning",
                ]
            },
        ],
    },
    {
        title: "Website Maintenance Services",
        description:
            "Do you want to get website maintenance services? Then, we are here to please you professionally. WeTech Digital has the knowledge and skills that your business requires.",
        url: "website-maintenance-services",
        contents: [
            {
                title: 'Why should you choose our Website Maintenance?',
                image: WHY,
                subContents: [
                    "We deliver site enhancements in general",
                    "A detailed analysis for you is our concern",
                    "We provide regular updates on products and services",
                    "Solving your problems steadily",
                    "Specials on the web",
                    "Upkeep of interactivity",
                ]
            },
            {
                title: 'Our Unique Selling Propositions for Website Maintenance',
                image: PROPOSITION,
                subContents: [
                    "We always keep the focus on robust security as much",
                    "Basic retouching, removals, and additions on contents",
                    "Some exclusives services are also offered",
                    "We convert optimization into profitable solutions",
                    "Result-oriented outcomes",
                    "Fulfill requirements in the best manner",
                ]
            },
        ],
    },
    {
        title: "Content Management",
        description:
            "Generate more leads & sales with an effective Content Marketing System. There's a better way to grow, and we are the solution!",
        url: "content-management",
        contents: [
            {
                title: 'Why should you choose our Content Management?',
                image: WHY,
                subContents: [
                    "We have global CMS specialists (Included international & proven capabilities)",
                    "We seem more than the best CMS agency; we can also handle all client needs",
                    "We use robust techniques to grow any business with an online presence",
                    "Transparent and Customized reporting for content development",
                    "We provide professional Ad content, blog, article, infographic, website content, Case studies, newsletters, email, and many more CMS solutions for small to large businesses",
                ]
            },
            {
                title: 'Our Unique Selling Propositions for Content Management',
                image: PROPOSITION,
                subContents: [
                    "Result-oriented outcomes as a local & global agency",
                    "Fulfill industry-specific requirements",
                    "We plan, execute, and refine strategy through our experts",
                ]
            },
        ],
    },
    {
        title: "Brand Identity",
        description: "Do you want to get brand & identity design? Then we're here to help you out competently.",
        url: "brand-identity",
        contents: [
            {
                title: 'Why should you choose our Brand Identity?',
                image: WHY,
                subContents: [
                    "The best brand identity design agency",
                    "Our job is more efficient",
                    "We provide a variety of services for many platforms",
                    "Our service fees are reasonable",
                    "We are always balancing the needs of our customers",
                ]
            },
            {
                title: 'Our Unique Selling Propositions for Brand Identity',
                image: PROPOSITION,
                subContents: [
                    "Our work is Identifiable and Recallable",
                    "Our product is adaptable and extensible",
                    "Guarantees your brand image",
                    "Spreads your fame everywhere by our links",
                ]
            },
        ]
    },
    {
        title: "Business Development Strategy",
        description: "Business Development Agency Do you seek for Business Development Agency? Then we're here to help you with our strategy.",
        url: "business-development-strategy",
        contents: [
            {
                title: 'Why should you choose our Business Development Strategy?',
                image: WHY,
                subContents: [
                    "We always balancing customer requirements",
                    "We maintain the quality of your website's content and user interface",
                    "We improve reaction speed",
                    "We arrange different services for different platforms",
                    "We have affordable service costs",
                ]
            },
            {
                title: 'Our Unique Selling Propositions for Business Development Strategy',
                image: PROPOSITION,
                subContents: [
                    "We build long-term ties with customers",
                    "We use feedback from clients",
                    "We make use of a business strategy to discover potential growth opportunities",
                    "Help to get business registration",
                ]
            },
        ]
    },
    {
        title: "Market Research Service",
        description: "Do you want to get Market research for your business? Then we're here to help you out competently.",
        url: "market-research-service",
        contents: [
            {
                title: 'Why should you choose our Market Research Service?',
                image: WHY,
                subContents: [
                    "We provide more efficient work",
                    "We arrange different services for different platforms",
                    "We have affordable service costs",
                    "We always balancing customer requirements",
                    "Dedicated and patient team",
                ]
            },
            {
                title: 'Our Unique Selling Propositions for Market Research Service',
                image: PROPOSITION,
                subContents: [
                    "Give importance to customers",
                    "A full-proofa research strategy",
                    "Arrange legal information and documents",
                ]
            },
        ]
    },
    {
        title: "Competitor Analysis",
        description: "Do you want well to build up Competitor Analysis services? Then we are here to please you providing that professionally.",
        url: "competitor-analysis",
        contents: [
            {
                title: 'Why should you choose our Competitor Analysis?',
                image: WHY,
                subContents: [
                    "Assist you in comprehending the target industry",
                    "We offer more efficient production and sales",
                    "We provide affordable services cost",
                    "We arrange different services for different platforms",
                ]
            },
            {
                title: 'Our Unique Selling Propositions for Competitor Analysis',
                image: PROPOSITION,
                subContents: [
                    "We examine the sales strategies and outcomes of your rivals",
                    "Make sure your delivery prices are competitive",
                    "Check to see if your delivery costs are reasonable",
                    "Designing custom content with high technology",
                ]
            },
        ]
    },
    {
        title: "Community Management",
        description: "Do you want to get Online Community Management Services? Then we're here to help you out competently.",
        url: "community-management",
        contents: [
            {
                title: 'Why should you choose our Community Management?',
                image: WHY,
                subContents: [
                    "We always balancing customer requirements",
                    "We provide more efficient work",
                    "Distinctive methods to the relationship with clients",
                    "We arrange different services for different platforms",
                    "We have affordable service costs",
                ]
            },
            {
                title: 'Our Unique Selling Propositions for Community Management',
                image: PROPOSITION,
                subContents: [
                    "Our motto is to give you a managed community circle",
                    "Increasing client retention",
                    "We make sure to keep all old & new clients connect with you",
                    "Spreads your fame everywhere by our links to make the community",
                ]
            },
        ]
    },
    {
        title: "TVC Marketing",
        description: "Are you looking for a Creative TV Commercial Agency? High-quality content and TVC products are needed for success. WeTech Digital has an affordable TV commercial cost package.",
        url: "tvc-marketing",
        contents: [
            {
                title: 'Why should you choose our TVC Marketing?',
                image: WHY,
                subContents: [
                    "We offer the best TVC services pricing",
                    "We examine the concept from a variety of perspectives",
                    "We customize your ideas",
                    "We send a strong message",
                    "Also, make it mobile-friendly",
                    "We are eager to keep in contact with you while working and also positive about your opinion",
                ]
            },
            {
                title: 'Our Unique Selling Propositions for TVC Marketing',
                image: PROPOSITION,
                subContents: [
                    "Solitary — just one clear message will be delivered",
                    "Provide professional services that pay close attention to detail and satisfy your requirements",
                    "Meet industry-specific specifications",
                    "Maximize your competitive edge",
                    "Fulfill industry-specific requirements",
                    "Exclusive - not a clone of another company's product",
                    "Allows you to buy something after you've watched the commercial. Our Recent Projects",
                ]
            },
        ]
    },
    {
        title: "OVC Marketing",
        description: "Do you want Online Video Commercial? Then we're here to help you out competently by giving OVC marketing services.",
        url: "ovc-marketing",
        contents: [
            {
                title: 'Why should you choose our OVC Marketing?',
                image: WHY,
                subContents: [
                    "We are always balancing the needs of our customers",
                    "Our job is more efficient",
                    "We provide a variety of services for many platforms",
                    "Our service fees are reasonable",
                ]
            },
            {
                title: 'Our Unique Selling Propositions for OVC Marketing',
                image: PROPOSITION,
                subContents: [
                    "Provide high quality animated Online Video Commercial",
                    "We arrange social platform to showing your online video commercial",
                    "Informative, creative and sometimes animated online video commercial are show our unique ideas",
                    "Spreads your fame everywhere by online video commercial",
                ]
            },
        ]
    },
    {
        title: "Influencer Marketing",
        description: "Are you looking for the best influencer marketing services? WeTech Digital is here to help you heartedly.",
        url: "influencer-marketing",
        contents: [
            {
                title: 'Why should you choose our Influencer Marketing?',
                image: WHY,
                subContents: [
                    "We offer the best influencer marketing cost",
                    "We have focused on outreach practitioners",
                    "We are eager to keep in contact with you while working and also positive about your opinion",
                    "Fulfill industry-specific requirements",
                ]
            },
            {
                title: 'Our Unique Selling Propositions for Influencer Marketing',
                image: PROPOSITION,
                subContents: [
                    "We provide open and honest coverage",
                    "Provide professional services that pay close attention to detail and satisfy your requirements",
                    "Public opinion polling",
                    "Meet industry-specific specifications 5. Maximize your competitive edge",
                ]
            },
        ]
    },
    {
        title: "Newspaper/Portal Ads",
        description: "Do you need help with newspaper/portal ads? Then you are in the right place to get it.",
        url: "newspaper-portal-ads",
        contents: [
            {
                title: 'Why should you choose our Newspaper/Portal Ads?',
                image: WHY,
                subContents: [
                    "Help you in the market through advertising",
                    "We provide work that is more efficient",
                    "We provide affordable services cost",
                    "We arrange different services for different platforms",
                ]
            },
            {
                title: 'Our Unique Selling Propositions for Newspaper/Portal Ads',
                image: PROPOSITION,
                subContents: [
                    "We examine all possibilities",
                    "We tried to give unique services",
                    "We provide profitable service",
                    "Designing custom content with high technology",
                ]
            },
        ]
    },
    {
        title: "Business Registration in Bangladesh",
        description: "Do you want to get Business Registration in Bangladesh? Then we're here to help you out competently.",
        url: "business-registration-in-bangladesh",
        contents: [
            {
                title: 'Why should you choose our Business Registration?',
                image: WHY,
                subContents: [
                    "We always balancing customer requirements",
                    "We provide more efficient work",
                    "We arrange different services for different platforms",
                    "We have affordable service costs",
                ]
            },
            {
                title: 'Our Unique Selling Propositions for Business Registration',
                image: PROPOSITION,
                subContents: [
                    "Our comparability is simple",
                    "We provide help on the opening bank account",
                    "Guarantees your brand image as a legal business",
                    "Spreads your fame everywhere by our links",
                ]
            },
        ]
    },
    {
        title: "License and Certification",
        description: "Do you want to get License and Certification Services in Bangladesh? If that's the case, we'll gladly assist you. WeTech Digital is here for you with its business license compliance services.",
        url: "license-and-certification-services",
        contents: [
            {
                title: 'Why should you choose our License and Certification?',
                image: WHY,
                subContents: [
                    "We are always harmonizing the needs of our customers",
                    "Our job is more efficient",
                    "We provide a variety of services for many platforms",
                    "Our service fees are reasonable. 5. We make our clients' lives easier",
                ]
            },
            {
                title: 'Our Unique Selling Propositions for License and Certification',
                image: PROPOSITION,
                subContents: [
                    "We renew business license any time on clients' request",
                    "We too provide help to online businessmen",
                    "Guarantees your brand image as a legal business",
                    "Cost effective high level services",
                ]
            },
        ]
    },
    {
        title: "Trade Mark Application Service",
        description: "Do you want Trade Mark Application Service in Bangladesh? Then we're here to help you out competently.",
        url: "trade-mark-application-service",
        contents: [
            {
                title: 'Why should you choose our Trade Mark Application Service?',
                image: WHY,
                subContents: [
                    "We always balancing customer requirements",
                    "We provide more efficient work",
                    "Our services are fully abided by laws",
                    "Decrease your pressures by taking most of the responsibility through getting a trademark",
                    "We arrange different services for different platforms",
                    "We have cost-effective service",
                ]
            },
            {
                title: 'Our Unique Selling Propositions for Trade Mark Application Service',
                image: PROPOSITION,
                subContents: [
                    "With our help, don't need to consult with a lawyer",
                    "All procedures are fully our responsibility",
                    "We have a lawyer for a consultation",
                    "Guarantees your trademark legally",
                ]
            },
        ]
    },
    {
        title: "Partnership Deed",
        description: "Do you want to get Partnership Deed in Bangladesh? Then we're here to help you out competently. WeTech Digital has the best business partnership agreement consultant team.",
        url: "partnership-deed-in-bangladesh",
        contents: [
            {
                title: 'Why should you choose our Partnership Deed?',
                image: WHY,
                subContents: [
                    "We always balancing customer requirements",
                    "One of the reliable partnership deed service providers",
                    "We provide more efficient work",
                    "We arrange different services for different platforms",
                    "We have affordable service cost",
                ]
            },
            {
                title: 'Our Unique Selling Propositions for Partnership Deed',
                image: PROPOSITION,
                subContents: [
                    "Very low cost of Partnership deed Registration",
                    "Cost effective Business development Agreement service",
                    "Best consultant team provider",
                    "Dedicated and expert team",
                ]
            },
        ]
    }
]