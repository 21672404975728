import { BEHANCE, DRIBBBLE, FACEBOOK, INSTAGRAM, LINKEDIN, TWITTER, YOUTUBE } from "../images/imageData";

export const socilaIcons = [
    {
        name: FACEBOOK,
        url: 'https://www.facebook.com/WeTechDigitalGlobal/'
    },
    {
        name: INSTAGRAM,
        url: 'https://www.instagram.com/wetechdigitalglobal/'
    },
    {
        name: LINKEDIN,
        url: 'https://www.linkedin.com/company/wetechdigitalglobal'
    },
    {
        name: YOUTUBE,
        url: 'https://www.youtube.com/channel/UC2bEtF_v_-zOiJqULPCAj5w'
    },
    {
        name: TWITTER,
        url: 'https://twitter.com/WeTech_Digital'
    },
    {
        name: DRIBBBLE,
        url: 'https://dribbble.com/WeTechDigital'
    },
    {
        name: BEHANCE,
        url: 'https://www.behance.net/wetechdigitalglobal'
    },
];

export const tabs = [
    {
        title: 'About Us',
        path: '/about-us',
    },
    {
        title: 'Services',
        path: '/services',
    },
    {
        title: 'Blog',
        path: '/blog',
    },
    {
        title: 'Contact Us',
        path: '/contact-us',
    },
    {
        title: 'Our Portfolio',
        path: '/our-portfolio',
    },
    {
        title: 'Career',
        path: '/career',
    },
    {
        title: 'Gallery',
        path: '/gallery',
    },
]