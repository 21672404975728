import React from 'react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { partnerList } from '../assets/data/clientData';

const PartnerSection = () => {
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 1280 },
            items: 6
        },
        desktop: {
            breakpoint: { max: 1280, min: 768 },
            items: 4
        },
        tablet: {
            breakpoint: { max: 768, min: 0 },
            items: 2
        },
    };
    return (
        <div className="">
            <Carousel
                swipeable={false}
                draggable={true}
                showDots={false}
                responsive={responsive}
                ssr={true}
                arrows={false}
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={2000}
                keyBoardControl={true}
                customTransition="all .5s ease"
                transitionDuration={500}
            >

                {partnerList?.map((item, index) => {
                    return (
                        <div key={index} className='h-32 flex items-center justify-center'>
                            <img src={item} alt={'image ' + index} />
                        </div>
                    )
                })}

            </Carousel>
        </div>
    )
}

export default PartnerSection