import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { IBlog } from './BlogScreen';
import { toast } from 'react-toastify';
import Loading from '../../components/Loading';

const BlogDetailsScreen = () => {
    const params = useParams();
    const baseUrl = process.env.REACT_APP_API_ENDPOINT;
    const [blogDetails, setBlogDetails] = useState<IBlog | null>(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        if (baseUrl && params?.id) {
            fetch(`${baseUrl}/blog/list`)
                .then((response) => {
                    if (!response.ok) {
                        toast.error('Network response was not ok!')
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then((data) => {
                    const blog = data?.filter((blog: IBlog) => blog?._id === params?.id);
                    if (blog?.length > 0) {
                        setBlogDetails(blog[0]);
                    } else {
                        setBlogDetails(null);
                    }
                    setLoading(false);
                })
                .catch((error) => {
                    toast.error('Something went wrong!')
                    console.error('Error fetching data:', error);
                    setLoading(false);
                });
        }
    }, [baseUrl, params?.id]);

    return (
        <div>
            {loading && <Loading />}
            <div className='w-full 2xl:px-[240px] xl:px-[40px] px-[10px] my-20 gap-10 flex flex-col'>
                <div className='text-center text-h3 font-semibold'>{blogDetails?.title}</div>
                <div className='bg-white rounded-lg p-4'>
                    <img src={blogDetails?.file} alt={blogDetails?.title} className='w-full rounded-lg overflow-hidden mb-4' />
                    {blogDetails !== null && <div className='text-right text-h5 text-primary1'>By {blogDetails?.author}</div>}
                    {blogDetails !== null ?
                        <div className='my-10' dangerouslySetInnerHTML={{ __html: blogDetails?.description }} />
                        : <div className="text-center h-[50vh] flex justify-center items-center text-red-400">There is no blog available!</div>
                    }
                </div>
            </div>
        </div>
    )
}

export default BlogDetailsScreen