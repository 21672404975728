import React, { useEffect, useRef } from 'react'
import { TEAM_CIRCLE_BG } from '../assets/images/imageData';
import { contentData } from '../assets/data/clientData';

const HomeContentSection = () => {
    const scrollableDivRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const scrollableDiv = scrollableDivRef.current;

        if (scrollableDiv) {
            scrollableDiv.addEventListener('wheel', (event) => {
                // Calculate the maximum scroll position of the scrollable content
                const maxScrollLeft = scrollableDiv.scrollWidth - scrollableDiv.clientWidth;

                // If at the beginning or end of the scrollable content, allow scrolling the body
                if (
                    (scrollableDiv.scrollLeft === 0 && event.deltaY < 0) ||
                    (scrollableDiv.scrollLeft === maxScrollLeft && event.deltaY > 0)
                ) {
                    // Allow body scrolling
                    return;
                }

                // Prevent the default scroll behavior to avoid interference with the body scrolling
                event.preventDefault();

                // Scroll horizontally
                scrollableDiv.scrollLeft += event.deltaY;
            });
        }
    }, []);

    return (
        <div className='overflow-x-auto flex no-scrollbar scroll-smooth' id="scrollable-div" ref={scrollableDivRef}>
            {contentData?.map((item, index) => {
                return (
                    <div key={index} className={`bg-black/10 backdrop-blur-sm rounded-2xl mx-[14PX] p-[16px] min-h-[300px] flex flex-col justify-between relative min-w-[300px] sm:min-w-[400px] lg:min-w-[500px] parent-div`}>
                        <div className='z-10'>
                            <div className='mb-[60px] flex justify-end moveRightToLeft'>
                                <img src={item?.image} alt={'image ' + index} className='w-[300px] h-[150px] rounded-full' />
                            </div>
                            <div className='text-black'>
                                <div className='text-h3 mb-[30px]'>{item?.title}</div>
                                <div className='text-justify text-black/80'>{item?.description}</div>
                            </div>
                        </div>
                        <div className='text-h2 text-black/20 w-full text-right'>0{index + 1}</div>
                        <div className='absolute -top-2 left-0 z-1 overflow-hidden'>
                            <img src={TEAM_CIRCLE_BG} alt='card bg' className='h-[200px] rotate-90' />
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default HomeContentSection