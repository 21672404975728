import React from 'react'
import { Link } from 'react-router-dom'
import { INTERNSHIP_PROGRAM } from '../assets/images/imageData';

const InternshipHeader = () => {
    return (
        <div>
            <div className='text-center text-h2 font-semibold mb-10'>Internship Program</div>
            <div className='w-full grid grid-cols-1 md:grid-cols-2 gap-5 justify-between items-center'>
                <div className='text-justify flex flex-col items-center md:items-start gap-10'>
                    At Wetech Digital, we believe in nurturing the talent of tomorrow. Whether you're a coding genius, a creative designer, a marketing enthusiast, or a business strategist, we have the perfect opportunity waiting for you to explore.
                    <Link to="/career/internship-program" className={`w-fit select-none rounded-lg py-2 px-3 truncate cursor-pointer bg-gradient-to-r from-primary1 to-[#5CE0FF] hover:from-primary2 hover:to-[#fffd98] text-center text-white hover:text-black`}>Internship Program</Link>
                </div>
                <div className='flex justify-center items-center'>
                    <img src={INTERNSHIP_PROGRAM} alt='INTERNSHIP_PROGRAM' className='h-full w-full' />
                </div>
            </div>
        </div>
    )
}

export default InternshipHeader