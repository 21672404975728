import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { BLACK_LOGO } from '../../assets/images/imageData';
import { IServiceExploreData, serviceExploreData } from '../../assets/data/serviceData';
import ContactSection from '../../sections/ContactSection';

const ServiceDetails = () => {
    const params = useParams();
    const [serviceDetails, setServiceDetails] = useState<IServiceExploreData | null>(null);

    useEffect(() => {
        if (params?.url) {
            const newDetails = serviceExploreData?.filter(item => item?.url === params?.url);
            if (newDetails?.length > 0) {
                setServiceDetails(newDetails[0]);
            } else {
                setServiceDetails(null);
            }
        }
    }, [params?.url]);

    return (
        <div>

            <div className='w-full 2xl:px-[240px] xl:px-[40px] px-[10px] flex flex-col justify-center items-center my-20'>
                <div className='text-center text-h2 font-semibold mb-5'>{serviceDetails?.title}</div>
                <div className='text-center w-full lg:w-[60%]'>{serviceDetails?.description}</div>
                <div className='flex flex-col gap-16 my-10 rounded-xl bg-[#F5F5F5]'>
                    {serviceDetails?.contents?.map((item, index) => {
                        return (
                            <div key={index} className={`flex flex-col ${index % 2 === 0 ? "lg:flex-row" : "lg:flex-row-reverse"} gap-10 items-center w-full min-h-[400px]`}>
                                <div className={`w-full relative flex items-center min-h-[400px] ${index % 2 === 0 ? "justify-start" : "justify-end"}`}>
                                    <div className='h-[200px] w-[70%] bg-white overflow-hidden'>
                                        <div className={`w-[70%] h-full flex items-center ${index % 2 === 0 ? "scroll-logo-l2r" : "scroll-logo-r2l"}`}>
                                            <img src={BLACK_LOGO} alt='logo' className='w-32' />
                                        </div>
                                    </div>
                                    <img src={item?.image} alt={item?.title} className={`h-full w-[70%] absolute ${index % 2 === 0 ? "right-0" : "left-0"}`} />
                                </div>
                                <div className='rounded-xl w-full min-h-[400px] px-10 py-5 flex flex-col gap-3 relative'>
                                    <div className='text-h5 md:text-h4 xl:text-h3 font-bold'>{item?.title}</div>
                                    <div className='flex flex-col gap-1'>
                                        {item?.subContents?.map((subItem, ind) => {
                                            return (
                                                <div key={ind} className='flex flex-row gap-3 text-justify'>
                                                    <div>{ind + 1}.</div>
                                                    <div>{subItem}</div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className='mb-10'>
                <ContactSection />
            </div>
        </div>
    )
}

export default ServiceDetails