import React, { useState } from 'react'
import { featuresInternship, jobDescriptionData } from '../../assets/data/internshipData';
import { INTERNSHIP_BANNER, INTERNSHIP_OPEN, INTERNSHIP_PROGRAM, INTERNSHIP_WHO_APPLY } from '../../assets/images/imageData';
import JobDescriptionModal from '../../components/JobDescriptionModal';
import { Link } from 'react-router-dom';

const InternshipProgram = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [data, setData] = useState({});

    return (
        <div>
            <div
                className="w-full min-h-[80vh]"
                style={{
                    backgroundImage: `url(${INTERNSHIP_BANNER})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",
                }}>
                <div
                    className="w-full min-h-[80vh] flex flex-col justify-center items-center text-center"
                    style={{
                        backdropFilter: "blur(2px)",
                        backgroundColor: "rgba(0, 0, 0, 0.6)",
                    }}>
                    <div className="text-white font-bold text-4xl mb-5">
                        Career at Digital Agency - Internship 2.0
                    </div>
                    <div className=" text-white italic text-3xl">
                        <div>“Unleash Your Potential And Kick-Start</div>
                        <div>Your Career With WeTech Digital”</div>
                    </div>
                    <div className='flex justify-center mt-10 mb-5'>
                        <Link to="https://forms.gle/1f1g2u2zxmWMaWR6A" target='_blank' className={`w-fit select-none rounded-lg py-2 px-3 truncate cursor-pointer bg-gradient-to-r from-primary1 to-[#5CE0FF] hover:from-primary2 hover:to-[#fffd98] text-center text-white hover:text-black`}>Apply Now</Link>
                    </div>
                </div>
            </div>
            <div className="w-full flex flex-col items-center justify-center">
                <div className="w-[80%] my-5">
                    <div className="w-full mb-8 flex flex-col-reverse items-center lg:flex-row space-y-5 lg:space-x-5">
                        <div className="w-full lg:w-[50%] mt-5 lg:mt-0 space-y-4">
                            <div className="text-black font-bold text-2xl text-center">
                                About Career at Digital Agency
                            </div>
                            <div className="text-black text-justify">
                                At Wetech Digital, we believe in nurturing the talent of
                                tomorrow. Whether you're a coding genius, a creative designer, a
                                marketing enthusiast, or a business strategist, we have the
                                perfect opportunity waiting for you to explore.
                            </div>
                        </div>
                        <div className="w-full lg:w-[50%] flex flex-row justify-center items-center">
                            <img src={INTERNSHIP_PROGRAM} alt="internship" className="w-full" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full flex flex-col items-center justify-center bg-[#0b5ed7]/10 py-10 backdrop-blur">
                <div className="w-[80%] my-5">
                    <div className="w-full text-center">
                        <div className="w-full text-3xl text-black font-bold mb-2">
                            Features of the Internship
                        </div>
                        <div className="mb-5">
                            Discover a Wide Selection Of Books Suitable For Every Reader's
                            Taste. Shop Now.
                        </div>
                        <div className="flex flex-wrap justify-between">
                            {featuresInternship?.map((feature, index) => {
                                return (
                                    <div
                                        key={index}
                                        className="py-2 px-3 w-full sm:w-1/4 items-center justify-center flex flex-col space-y-4 group hover:scale-105 transition-all">
                                        <div className="bg-[#0b5ed7]/10 flex items-center justify-center rounded-md group-hover:shadow text-[#0099dd]">
                                            <img
                                                src={feature?.icon}
                                                alt={feature?.title}
                                                className="h-20"
                                            />
                                        </div>
                                        <div className="text-[#0099dd] font-semibold">
                                            {feature.title}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full flex flex-col items-center justify-center py-10">
                <div className="w-[80%] my-5">
                    <div className="w-full mb-8 flex flex-col-reverse lg:flex-row space-y-5 lg:space-x-5">
                        <div className="w-full lg:w-[50%]">
                            <img src={INTERNSHIP_WHO_APPLY} alt="internship" className="w-full h-full" />
                        </div>
                        <div className="w-full lg:w-[50%] mt-5 lg:mt-0 space-y-4">
                            <div className="text-black font-bold text-2xl text-center">
                                Who Can Apply ?
                            </div>
                            <div className="text-black text-justify flex flex-col space-y-4">
                                <div>
                                    This internship is designed for passionate students eager to
                                    embark on an exciting journey in the world of technology and
                                    innovation.
                                </div>
                                <div>
                                    Our Internship Program is open to ambitious students from all
                                    disciplines and backgrounds. Whether you're an undergraduate,
                                    graduate, or recent graduate, as long as you have a passion
                                    for technology and a desire to learn, we encourage you to
                                    apply.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full flex flex-col items-center justify-center bg-[#0b5ed7]/10 py-10 backdrop-blur">
                <div className="w-[80%] my-5">
                    <div className="w-full mb-8 flex flex-col-reverse lg:flex-row space-y-5 lg:space-x-5">
                        <div className="w-full lg:w-[50%] mt-5 lg:mt-0 space-y-4">
                            <div className="text-black font-bold text-2xl text-center">
                                Open Positions for Internship
                            </div>
                            <div className="text-black text-justify">
                                We are offering a range of positions you can fit yourself in for
                                the internship. Choose as per your preference (However, we
                                encourage you to go out of your comfort zone and dare learning
                                something new and exciting!)
                            </div>
                        </div>
                        <div className="w-full lg:w-[50%]">
                            <img src={INTERNSHIP_OPEN} alt="internship" className="w-full h-full" />
                        </div>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 justify-start sm:justify-center mt-5">
                        {jobDescriptionData?.map((jobDescription, index) => {
                            return (
                                <div
                                    key={index}
                                    onClick={() => {
                                        setIsOpen(true);
                                        setData(jobDescription);
                                    }}
                                    className="py-2 px-2 m-2 shadow rounded items-center flex flex-row space-x-4 cursor-pointer group hover:scale-105 transition-all">
                                    <div className="bg-[#0b5ed7]/10 flex items-center justify-center rounded-md group-hover:shadow text-[#0099dd]">
                                        <img
                                            src={jobDescription.icon}
                                            alt={jobDescription.title}
                                            className="h-12"
                                        />
                                    </div>
                                    <div className="text-[#0099dd] font-semibold">
                                        {jobDescription?.title}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className='flex justify-center mt-10 mb-5'>
                        <Link to="https://forms.gle/1f1g2u2zxmWMaWR6A" target='_blank' className={`w-fit select-none rounded-lg py-2 px-3 truncate cursor-pointer bg-gradient-to-r from-primary1 to-[#5CE0FF] hover:from-primary2 hover:to-[#fffd98] text-center text-white hover:text-black`}>Apply Now</Link>
                    </div>
                </div>
            </div>
            <JobDescriptionModal isOpen={isOpen} setIsOpen={setIsOpen} data={data} />
        </div>
    )
}

export default InternshipProgram