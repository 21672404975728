import React from 'react'
import Lottie from 'react-lottie';
import LOADING from '../assets/json/LOADING.json'
import BG from '../assets/images/extra/bg.gif';

const Loading = () => {
    return (
        <div style={{
            backgroundImage: `url(${BG})`,
            backgroundRepeat: 'repeat',
            backgroundSize: 'cover'
        }} className='fixed top-0 bottom-0 left-0 right-0 h-[100vh] w-[100vw] flex justify-center items-center z-[100000000]'>
            <div className='h-[100vh] w-[100vw] fixed top-0 bottom-0 left-0 right-0 z-[10000] flex justify-center items-center bg-white/10 backdrop-blur-sm'>
                <div className='h-[200px] md:h-[300px] w-[200px] md:w-[300px]'>
                    <Lottie options={{
                        loop: true,
                        animationData: LOADING
                    }} />
                </div>
            </div>
        </div>
    )
}

export default Loading