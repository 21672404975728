import React, { useEffect, useState } from "react";

import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "../../components/Loading";
import { IMPROVEMENT, INCREASE } from "../../assets/images/imageData";
import SECTION_BG from '../../assets/images/extra/section-bg.svg';

export interface IPortfolioData {
    title: string;
    description: string;
    increase: number;
    improvement: number;
    image: string;
    url: string;
}

export interface IPortfolioItem {
    _id: string;
    customTitle: string;
    section: {
        _id: string;
        customTitle: string;
        name: string;
        description: string;
        link: string;
        metaTags: string[];
        metaDescription: string;
        customUrl: string;
        file: string;
    };
    title: string;
    description: string;
    link: string;
    metaTags: string[];
    metaDescription: string;
    customUrl: string;
    file: string;
    cri: number;
    cpl: number;
}

const PortfolioDetailsScreen = () => {
    const params = useParams();
    const baseUrl = process.env.REACT_APP_API_ENDPOINT;
    const [loading, setLoading] = useState(true);
    const [portfolioData, setPortfolioData] = useState<IPortfolioData[] | null>(
        null
    );
    const [portfolioHeader, setPortfolioHeader] = useState({ title: '', description: '' })
    useEffect(() => {
        if (baseUrl && params?.title) {
            fetch(`${baseUrl}/portfolio/list`)
                .then((response) => {
                    if (!response.ok) {
                        toast.error("Network response was not ok!");
                        throw new Error("Network response was not ok");
                    }
                    return response.json();
                })
                .then((data) => {
                    const sectionData: IPortfolioItem[] = data?.filter((item: IPortfolioItem) => item?.section?.customUrl === params?.title);
                    const newProfileData: IPortfolioData[] = sectionData?.map((item: IPortfolioItem) => {
                        return {
                            title: item?.title,
                            description: item?.description,
                            increase: item?.cri,
                            improvement: item?.cpl,
                            image: item?.file,
                            url: item?.link,
                        }
                    })
                    setPortfolioData(newProfileData);
                    setLoading(false);
                })
                .catch((error) => {
                    toast.error("Something went wrong!");
                    console.error("Error fetching data:", error);
                    setLoading(false);
                });

            fetch(`${baseUrl}/section/list`)
                .then((response) => {
                    if (!response.ok) {
                        toast.error('Network response was not ok!')
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then((data) => {
                    const newData = data?.filter((item: any) => item?.customUrl === params?.title)[0];
                    setPortfolioHeader({
                        title: newData.name,
                        description: newData.description
                    })
                    setLoading(false);
                })
                .catch((error) => {
                    toast.error('Something went wrong!')
                    console.error('Error fetching data:', error);
                    setLoading(false);
                });
        }
    }, [baseUrl, params?.title]);

    return (
        <div>
            {loading && <Loading />}
            <div className="w-full 2xl:px-[240px] xl:px-[40px] px-[10px] my-10">
                <div className="flex flex-col justify-center items-center">
                    <div className="text-center text-h2 font-semibold mb-5">
                        {portfolioHeader?.title}
                    </div>
                    <div className="text-center w-full lg:w-[70%]">
                        {portfolioHeader?.description}
                    </div>
                </div>
                {portfolioData && portfolioData?.length > 0 ? <div className="mt-20 flex flex-col gap-10">
                    {portfolioData?.map((item, index) => {
                        return (
                            <div key={index} className="w-full relative mb-[250px]">
                                <div className="w-full overflow-hidden h-[500px]">
                                    <img src={item?.image} alt={item?.title} className="w-full h-full object-fill" />
                                </div>
                                <div className={`flex absolute -bottom-[250px] w-full ${index % 2 === 0 ? 'justify-end' : 'justify-normal'}`}>
                                    <div style={{
                                        backgroundImage: `url(${SECTION_BG})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                    }} className={`w-full sm:w-[320px] md:w-[380px] lg:w-[500px] ${index % 2 === 0 ? 'mr-0 sm:mr-10 lg:mr-20' : 'ml-0 sm:ml-10 lg:ml-20'} rounded-xl overflow-hidden`}>
                                        <div className={`p-5 bg-white/20 text-white w-full h-full`}>
                                            <div className="text-h2 font-bold">{item?.title}</div>
                                            <div className="text-lg text-justify mt-5">{item?.description}</div>
                                            <div className="flex flex-col gap-5 mt-10">
                                                <div className="flex gap-4 items-center">
                                                    <img src={INCREASE} alt="increase" className="w-[54px] h-[54px]" />
                                                    <div className="h-full flex flex-col justify-between">
                                                        <div className="text-[20px] font-bold">{item?.increase}%</div>
                                                        <div>Conversion Rate Increase</div>
                                                    </div>
                                                </div>
                                                <div className="flex gap-4 items-center">
                                                    <img src={IMPROVEMENT} alt="improvement" className="w-[54px] h-[54px]" />
                                                    <div className="h-full flex flex-col justify-between">
                                                        <div className="text-[20px] font-bold">{item?.improvement}%</div>
                                                        <div>Cost Per Lead Improvement</div>
                                                    </div>
                                                </div>
                                                <div className="flex justify-end">
                                                    <Link to={item?.url} className={`w-fit select-none rounded-lg py-2 px-3 truncate cursor-pointer bg-gradient-to-r from-primary1 to-[#5CE0FF] hover:from-primary2 hover:to-[#fffd98] text-center text-white hover:text-black`}>See Project</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div> : <div className="text-center h-[50vh] flex justify-center items-center text-red-400">There is no portfolio details available!</div>}
            </div>
        </div>
    );
};

export default PortfolioDetailsScreen;
