import React, { useState } from 'react'
import { BsArrowRight } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

export interface IService {
    title: string;
    subTitle?: string;
    image: string;
    textColor: string;
    path: string;
}

export interface IServiceCard {
    index: number;
    service: IService;
    isButton?: boolean;
    imagePosition?: 'top' | 'bottom';
}

const ServiceCard = ({ index, service, isButton = false, imagePosition = 'top' }: IServiceCard) => {
    const [isHovered, setIsHovered] = useState(false);
    const [serviceId, setServiceId] = useState<null | number>(null);

    const handleMouseEnter = (id: number) => {
        setIsHovered(true);
        setServiceId(id);
    };

    const handleMouseLeave = (id: number) => {
        setIsHovered(false);
        setServiceId(id);
    };
    return (
        <Link
            to={service?.path}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={() => handleMouseLeave(index)}
            className="flex flex-col justify-between gap-10 p-[32px] rounded-lg relative overflow-hidden z-10 group backdrop-blur-sm bg-white/20 text-white cursor-pointer hover:bg-white hover:text-black duration-500"
        >
            {imagePosition === 'top' && <div className="h-[60px] w-[60px]">
                <ReactSVG
                    src={service?.image}
                    beforeInjection={(svg) => {
                        const paths = svg.querySelectorAll('path');
                        paths.forEach((path) => {
                            path.setAttribute('fill', isHovered ? index === serviceId ? '#000' : '#fff' : '#fff');
                        });
                    }}
                />
            </div>}
            <div>
                <div className={`text-h3 z-10 duration-500 mb-3`}>{service?.title}</div>
                {service?.subTitle && <div className="text-[#f5f5f5] group-hover:text-[#242424] mb-6 z-10 duration-500">
                    {service?.subTitle}
                </div>}
            </div>
            {isButton && <Link to={service?.path} className="flex gap-5 items-center font-medium cursor-pointer group-hover:text-primary1 hover:underline duration-500">
                Explore <BsArrowRight />
            </Link>}
            {imagePosition === 'bottom' && <div className="h-[60px] w-[60px] mb-5">
                <ReactSVG
                    src={service?.image}
                    beforeInjection={(svg) => {
                        const paths = svg.querySelectorAll('path');
                        paths.forEach((path) => {
                            path.setAttribute('fill', isHovered ? index === serviceId ? '#000' : '#fff' : '#fff');
                        });
                    }}
                />
            </div>}
        </Link>
    )
}

export default ServiceCard