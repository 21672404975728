import { CONTENT_WRITER, CORPORATE_SALES, DIGITAL_MARKETING_EXECUTIVE, EXPERT_MENTORSHIP, HANDS_ON_EXPERIENCE, MOTION_GRAPHICS_DESIGNER, NETWORKING_OPPORTUNITIES, SEO_SPECIALIST, SKILL_DEVELOPMENT } from "../images/imageData";

export const jobDescriptionData = [
    {
        title: "Motion Graphics Designer",
        icon: MOTION_GRAPHICS_DESIGNER,
        description:
            "If you have a passion for creating visually captivating motion graphics and animations, this internship is for you. Join our team and collaborate with us to develop engaging visual content for both local and international projects.",
        toDo: [
            "Create visually appealing motion graphics and animations.",
            "Collaborate with the team to develop engaging and dynamic visual content.",
            "Proficient in motion graphics software and techniques.",
            "Portfolio showcasing previous motion graphics work is required.",
        ],
        qualities: [
            "Basic knowledge of motion graphics software such as Adobe After Effects, Cinema 4D, or similar tools.",
            "Strong interest and passion for motion graphics design.",
            "Ability to work collaboratively in a team and meet project deadlines.",
        ],
    },
    {
        title: "Content Writer",
        icon: CONTENT_WRITER,
        description:
            "Are you a skilled wordsmith with a flair for crafting compelling content? Join us as a Content Writer Intern and unleash your creativity to create engaging and informative content for various platforms, honing your writing skills and gaining valuable experience in digital content creation.",
        toDo: [
            "Create compelling and engaging content for various platforms.",
            "Conduct research and generate ideas for content topics.",
            "Excellent writing skills and attention to detail.",
            "Familiarity with digital content creation and SEO best practices.",
        ],
        qualities: [
            "Excellent writing skills with a good command of grammar and vocabulary.",
            "Basic understanding of digital content creation and writing for online platforms.",
            "Strong research skills to gather information on various topics.",
        ],
    },
    {
        title: "SEO Specialist",
        icon: SEO_SPECIALIST,
        description:
            "Dive into the world of search engine optimization as an SEO Specialist Intern. Work with our team to develop and implement effective strategies to improve organic search rankings, conduct keyword research, and stay updated with the latest SEO trends and techniques.",
        toDo: [
            "Develop and implement SEO strategies to improve organic search rankings.",
            "Conduct keyword research and optimize website content.",
            "Monitor website performance and make data-driven recommendations.",
            "Stay updated with the latest SEO trends and algorithm changes.",
        ],
        qualities: [
            "Basic knowledge of search engine optimization principles and techniques.",
            "Familiarity with keyword research and analysis tools.",
            "Analytical mindset with the ability to interpret website analytics data.",
        ],
    },
    {
        title: "Digital Marketing Executive",
        icon: DIGITAL_MARKETING_EXECUTIVE,
        description:
            "Explore the dynamic field of digital marketing and become a Digital Marketing Executive Intern. Assist in planning and executing digital marketing campaigns, managing social media accounts, and analyzing campaign performance to enhance our online presence and engage our target audience.",
        toDo: [
            "Plan and execute digital marketing campaigns across various channels.",
            "Manage social media accounts and create engaging content.",
            "Monitor campaign performance and analyze metrics.",
            "Familiarity with digital marketing tools and platforms.",
        ],
        qualities: [
            "Basic understanding of digital marketing channels such as social media, email marketing, and paid advertising.",
            "Familiarity with digital marketing tools and platforms, such as Google Ads, Facebook Ads Manager, or similar tools.",
            "Creative mindset to assist in creating content for digital campaigns.",
        ],
    },
    {
        title: "Corporate Sales & Communication",
        icon: CORPORATE_SALES,
        description:
            "If you have excellent communication skills and a passion for sales, this internship is an excellent opportunity for you. As a Communication & Sales Manager Intern, you will assist in developing and executing sales strategies, identifying potential clients, building strong relationships, and contributing to our business growth.",
        toDo: [
            {
                title: "Motion Graphics (International & Local: 2 positions)",
                description: [
                    "Conducting sales calls and engaging with potential clients.",
                    "Building and maintaining client relationships through effective communication.",
                    "Assisting in sales presentations and materials preparation.",
                    "Supporting the sales team in achieving targets.",
                ],
            },
            {
                title: "Digital Marketing (International & Local: 2 positions)",
                description: [
                    "Collaborate with the sales team to create targeted messaging.",
                    "Support digital marketing campaigns to generate leads and drive sales.",
                    "Make outbound calls to prospects and follow up on leads.",
                    "Manage social media presence to engage prospects and drive sales.",
                    "",
                ],
            },
            {
                title: "Software Products (2 positions)",
                description: [
                    "Each position will have four (04) software products.",
                    "Conducting market research.",
                    "Making sales calls and engaging with clients.",
                    "Collaborating with the sales team.",
                ],
            },
            {
                title: "Business Content Marketer (1 position)",
                description: [
                    "Promoting and selling business content for a blog site",
                    "Developing effective communication strategies to attract clients.",
                    "Collaborating with the marketing team to create engaging content.",
                    "Making sales calls and engaging with potential clients.",
                    "Communicate with clients to understand their content needs.",
                ],
            },
        ],
        qualities: [
            "Strong interpersonal and communication skills.",
            "Basic knowledge of corporate sales and business development.",
            "Market research skills to identify potential clients and business opportunities.",
        ],
    },
];

export const featuresInternship = [
    {
        title: "Hands-On Experience",
        icon: HANDS_ON_EXPERIENCE,
    },
    {
        title: "Expert Mentorship",
        icon: EXPERT_MENTORSHIP,
    },
    {
        title: "Networking Opportunities",
        icon: NETWORKING_OPPORTUNITIES,
    },
    {
        title: "Skill Development",
        icon: SKILL_DEVELOPMENT,
    },
];