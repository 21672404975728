import React from "react";
import { HERO, HERO_1, HERO_2 } from "../assets/images/imageData";
import { FaRegChartBar } from 'react-icons/fa';
import { heroPerson } from "../assets/data/teamMemberData";
import { socilaIcons } from "../assets/data/navigationData";
import { Link } from "react-router-dom";
import Lottie from "react-lottie";

const HeroSection = () => {
    return (
        <div className="w-full 2xl:px-[240px] xl:px-[40px] px-[10px] flex justify-between flex-col lg:flex-row gap-6 h-full">
            <div className="w-full lg:w-[50%] justify-end gap-10 flex flex-col">
                <div className="flex flex-col justify-center h-full">
                    <div className="text-h4 sm:text-h3 lg:text-h2 2xl:text-h1 text-center lg:text-left font-kanit">
                        A Trusted{" "}
                        <span
                            className="text-gray-400 typed-text"
                            datatype="Digital"
                        >
                            Digital
                        </span>{" "}
                        <span
                            className="text-gray-400 typed-text"
                            datatype="Marketing"
                        >
                            Marketing
                        </span>{" "}
                        <span
                            className="text-gray-400 typed-text"
                            datatype="Agency"
                        >
                            Agency
                        </span>{" "}
                        <span>for Your Business's Success..</span>
                    </div>
                </div>
                <div className="w-full flex flex-col sm:flex-row gap-4">
                    <div className="w-full flex flex-col justify-between p-3 h-[180px] overflow-hidden rounded-2xl group relative" style={{
                        backgroundImage: `url(${HERO_1})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                    }}>
                        <div className="bg-white/10 backdrop-blur-sm rounded-full py-3 px-4 w-fit border border-white text-[20px] font-semibold flex gap-5 items-center">Follower 2k <FaRegChartBar /></div>
                        <div className="flex flex-row w-full group-hover:justify-between duration-300">
                            {socilaIcons?.slice(0, 5)?.map((social, index) => {
                                const gap = index * 30;
                                return (
                                    <Link to={social?.url} target="_blank" key={index} >
                                        <img src={social?.name} alt={index + ""} className="cursor-pointer backdrop-blur-sm bg-white/20 p-1 group-hover:mx-0 mx-3 duration-300 absolute flex group-hover:static h-[50px] w-[50px] rounded-full object-cover overflow-hidden" style={{
                                            right: gap + 'px',
                                            bottom: '10px',
                                            zIndex: gap
                                        }} />
                                    </Link>
                                )
                            })}
                        </div>
                    </div>
                    <div className="w-full flex flex-col justify-between p-3 h-[180px] overflow-hidden rounded-2xl group relative" style={{
                        backgroundImage: `url(${HERO_2})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                    }}>
                        <div className="bg-white/10 backdrop-blur-sm rounded-full py-3 px-4 w-fit border border-white text-[20px] font-semibold flex gap-5 items-center">Success Story</div>
                        <div className="flex flex-row w-full group-hover:justify-between duration-300">
                            {heroPerson?.map((hero, index) => {
                                const gap = index * 30;
                                return (
                                    <img key={index} src={hero} alt={index + ""} className="group-hover:mx-0 mx-3 duration-300 absolute flex group-hover:static h-[50px] w-[50px] rounded-full object-cover" style={{
                                        right: gap + 'px',
                                        bottom: '10px',
                                        zIndex: gap
                                    }} />
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full lg:w-[45%] flex justify-end">
                <Lottie options={{
                    loop: true,
                    animationData: HERO
                }} />
            </div>
        </div>
    );
};

export default HeroSection;
