import React from 'react'
import HeroSection from '../../sections/HeroSection'
import PartnerSection from '../../sections/PartnerSection'
import HomeContentSection from '../../sections/HomeContentSection'
import WeTechVideoSection from '../../sections/WeTechVideoSection'
import ServicesSection from '../../sections/ServicesSection'
import ClientSection from '../../sections/ClientSection'
import ContactSection from '../../sections/ContactSection'
import { service1List } from '../../assets/data/serviceData'

const HomeScreen = () => {
    return (

        <div className='flex flex-col my-10 w-full overflow-hidden'>
            <div className='w-full'>
                <HeroSection />
            </div>
            <div className='w-full mt-[120px]'>
                <PartnerSection />
            </div>
            <div className='w-full mt-[140px]'>
                <HomeContentSection />
            </div>
            <div className='w-full mt-[120px]'>
                <WeTechVideoSection videoUrl='https://youtu.be/IxJj5OzH8bM' />
            </div>
            <div className='w-full'>
                <ServicesSection data={service1List} title='Services that Connect You to Your Users' />
            </div>
            <div className='w-full mt-[120px]'>
                <ClientSection />
            </div>
            <div className='w-full mt-[120px]'>
                <ContactSection />
            </div>
        </div>
    )
}

export default HomeScreen