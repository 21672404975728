import React, { useEffect } from 'react'
import './index.css'
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Header from './components/Header'
import HomeScreen from './screens/Home/HomeScreen';
import Footer from './components/Footer';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ScrollToTopButton from './components/ScrollToTopButton';
import ContactUsScreen from './screens/Contact/ContactUsScreen';
import BlogScreen from './screens/Blog/BlogScreen';
import BlogDetailsScreen from './screens/Blog/BlogDetailsScreen';
import CareerScreen from './screens/Career/CareerScreen';
import GetInTouchScreen from './screens/Contact/GetInTouchScreen';
import JobDetailsScreen from './screens/Career/JobDetailsScreen';
import PortfolioScreen from './screens/Portfolio/PortfolioScreen';
import PortfolioDetailsScreen from './screens/Portfolio/PortfolioDetailsScreen';
import AboutUsScreen from './screens/About/AboutUsScreen';
import ServicesScreen from './screens/Services/ServicesScreen';
import ServiceSectionScreen from './screens/Services/ServiceSectionScreen';
import ServiceSectionDetailsSecrren from './screens/Services/ServiceSectionDetailsSecrren';
import NotFound from './screens/NotFound/NotFound';
import ServiceDetails from './screens/Services/ServiceDetails';
// @ts-ignore
import MessengerCustomerChat from 'react-messenger-customer-chat';
import GalleryScreen from './screens/Gallery/GalleryScreen';
import InternshipProgram from './screens/Career/InternshipProgram';

const App = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  let lastScrollTop =
    window.pageYOffset || document.documentElement.scrollTop;

  window.addEventListener(
    'scroll',
    function handleScroll() {
      const scrollTopPosition =
        window.pageYOffset || document.documentElement.scrollTop;

      if (scrollTopPosition > lastScrollTop) {
        document.body.classList.remove("scroll-bottom");
        document.body.classList.add("scroll-top");
      } else if (scrollTopPosition < lastScrollTop) {
        document.body.classList.remove("scroll-top");
        document.body.classList.add("scroll-bottom");
      }
      lastScrollTop =
        scrollTopPosition <= 0 ? 0 : scrollTopPosition;
    },
    false,
  );

  return (
    <Router>
      <div className='font-inter'>
        <Header />
        <Routes>
          <Route path="/" element={<HomeScreen />} />
          <Route path="/about-us" element={<AboutUsScreen />} />
          <Route path="/services" element={<ServicesScreen />} />
          <Route path="/service/:path" element={<ServiceSectionScreen />} />
          <Route path="/service/:path/:path" element={<ServiceSectionDetailsSecrren />} />
          <Route path="/service/:path1/:path2/:url/details" element={<ServiceDetails />} />
          <Route path="/contact-us" element={<ContactUsScreen />} />
          <Route path="/blog" element={<BlogScreen />} />
          <Route path="/blog/:id" element={<BlogDetailsScreen />} />
          <Route path="/career" element={<CareerScreen />} />
          <Route path="/career/internship-program" element={<InternshipProgram />} />
          <Route path="/job-details/:id" element={<JobDetailsScreen />} />
          <Route path="/get-a-quote" element={<GetInTouchScreen />} />
          <Route path="/our-portfolio" element={<PortfolioScreen />} />
          <Route path="/our-portfolio/:title" element={<PortfolioDetailsScreen />} />
          <Route path="/gallery" element={<GalleryScreen />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <ScrollToTopButton />
        <MessengerCustomerChat
          pageId="100608275271581"
          appId="640622738269413"
        />
      </div>
    </Router>
  )
}

export default App