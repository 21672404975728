import { BUSINESS_INSIGHT, WETECH_SOFTWARE, WETECH_STUDIO } from "../images/imageData";

export const SISTER_CONCERN = [
    {
        link: "https://wetechsoftware.com/",
        image: WETECH_SOFTWARE,
    },
    {
        link: "https://www.facebook.com/WeTechStudioGlobal/",
        image: WETECH_STUDIO,
    },
    {
        link: "https://businessinsightbd.com/",
        image: BUSINESS_INSIGHT,
    },
];