import React, { useState } from 'react'
import Lottie from 'react-lottie';
import YoutubeVideo from '../components/YoutubeVideo';
import PLAY_BUTTON from '../assets/json/PLAY_BUTTON.json';
import VIDEO_THUMBNAIL from '../assets/images/extra/video-thumbnail.jpg';

const WeTechVideoSection = ({ videoUrl }: { videoUrl: string; }) => {
    const [isVideoPlay, setIsVideoPlay] = useState(false);
    return (
        <div className='w-full'>
            <div className='mt-[280px] h-[640px] bg-gradient-to-b from-primary1 to-[#92deffb8] items-center flex'>
                <div className='2xl:mx-[240px] xl:mx-[40px] mx-[10px] w-full mt-[-500px]'>
                    <div className='w-full flex justify-center items-center h-[600px] overflow-hidden rounded-3xl' style={{
                        backgroundImage: `url(${VIDEO_THUMBNAIL})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                    }}>
                        <div onClick={() => setIsVideoPlay(true)} className='h-[200px] w-[200px]'>
                            <Lottie options={{ autoplay: true, animationData: PLAY_BUTTON, loop: true }} />
                        </div>
                    </div>
                </div>
            </div>
            {isVideoPlay && <YoutubeVideo videoUrl={videoUrl} onClose={() => setIsVideoPlay(false)} />}
        </div>
    )
}

export default WeTechVideoSection