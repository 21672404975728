import React from "react";
import ServiceCard, { IService } from "../components/ServiceCard";
import { SERVICE_TOP_BG } from "../assets/images/imageData";
import SECTION_BG from '../assets/images/extra/section-bg.svg';

interface IServiceSection {
    data: IService[];
    title: string;
    isButton?: boolean;
    imagePosition?: 'top' | 'bottom';
    className?: string;
}

const ServicesSection = ({ data, title, isButton = true, imagePosition = 'top', className }: IServiceSection) => {
    return (
        <div>
            <div className="w-full 2xl:px-[240px] xl:px-[40px] px-[10px] py-[80px] relative overflow-hidden" style={{
                backgroundImage: `url(${SECTION_BG})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
            }}>
                <div className='absolute bg-white/20 h-60 w-60 rounded-full bottom-[-65px] right-[-45px]' />
                <div className='absolute bg-gradient-to-r from-white/30 transition-all animate-spin h-32 w-32 rounded-full bottom-20 right-20' />
                <div className="absolute top-0 right-0">
                    <img src={SERVICE_TOP_BG} alt="servicesTopRightBG" className="w-full h-full" />
                </div>
                <div>
                    <div className="text-white text-center text-h3 md:text-h2 lg:text-h1 mb-[50px]">{title}</div>
                    <div className={`w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 sm:gap-10 md:gap-12 lg:gap-14 xl:gap-16 ${className}`}>
                        {data?.map((service, index) => {
                            return (
                                <ServiceCard index={index} service={service} isButton={isButton} imagePosition={imagePosition} key={index} />
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServicesSection;
