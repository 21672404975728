import React from 'react'
import WeTechVideoSection from './WeTechVideoSection'

const AboutUsVideoSection = () => {
    return (
        <div className='w-full'>
            <div className='w-full 2xl:px-[240px] xl:px-[40px] px-[10px]'>
                <div className='flex flex-col justify-center items-center'>
                    <div className='text-center text-h2 font-semibold mb-5'>About Us</div>
                    <div className='text-center w-full lg:w-[60%]'>WeTech Digital is an all-in-one digital marketing services agency that is dedicated to developing your business's outstanding success with online advertising and marketing facilities.</div>
                </div>
            </div>

            <WeTechVideoSection videoUrl='https://youtu.be/IxJj5OzH8bM' />
        </div>
    )
}

export default AboutUsVideoSection