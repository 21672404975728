import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { VscMenu } from 'react-icons/vsc';
import { AiOutlineClose } from 'react-icons/ai';
import { useOnClickOutside } from 'usehooks-ts';
import { tabs } from '../assets/data/navigationData';
import { BLACK_LOGO } from '../assets/images/imageData';

const Header = () => {
    const [active, setActive] = useState(false);
    const { pathname } = useLocation();
    const [isVisible, setIsVisible] = useState(false);

    const dropDownRef = useRef<any>();

    useOnClickOutside(dropDownRef, () => {
        setActive(false);
        setIsVisible(false);
    });

    useEffect(() => {
        const items = document.querySelectorAll("[data-aos='fade-down']");
        let delay = 0; // Initial delay value
        items.forEach((item) => {
            item.setAttribute("data-aos-delay", delay.toString());
            delay += 100; // Increase delay for each item
        });
    }, [active]);

    useEffect(() => {

    }, []);

    return (
        <>
            <div className='flex w-full h-20 justify-between items-center 2xl:px-[240px] xl:px-[40px] px-[10px]'>
                <div className='flex items-center gap-5'>
                    <div onClick={() => {
                        AOS.init({
                            startEvent: 'click',
                            easing: 'ease-in-out'
                        });
                        setIsVisible(true);
                        setActive(true);
                    }} className='flex xl:hidden rounded-lg p-2 select-none cursor-pointer bg-gradient-to-r from-primary1 to-[#5CE0FF] hover:from-primary2 hover:to-[#fffd98] text-center text-white hover:text-black'>
                        <VscMenu />
                    </div>
                    <Link to="/">
                        <img src={BLACK_LOGO} alt="logo" className='h-[32px] w-[114px] sm:h-[44px] sm:w-[170px] cursor-pointer select-none' />
                    </Link>
                </div>
                <div className={`hidden xl:flex gap-4 overflow-hidden px-5 h-14 items-center`}>
                    {tabs?.map((item, index) => {
                        return (
                            <Link to={item?.path} key={index}>
                                <span data-aos="fade-down" onClick={() => {
                                    AOS.init({
                                        startEvent: 'click',
                                        easing: 'ease-in-out'
                                    });
                                }} className={`truncate text-[#18191F99] cursor-pointer select-none ${pathname === item.path ? '!text-[#3698bf] bg-[#3698bf]/20 rounded-lg' : 'hover:text-[#f0a718]'} py-2 px-3`}>{item?.title}</span>
                            </Link>
                        )
                    })}
                </div>
                <Link to="/get-a-quote" className="text-xs lg:text-sm select-none rounded-lg py-2 px-3 cursor-pointer bg-gradient-to-r from-primary1 to-[#5CE0FF] hover:from-primary2 hover:to-[#fffd98] text-center text-white hover:text-black">GET A QUOTE</Link>
            </div>
            {isVisible && <div className='h-[100vh] fixed w-full top-0 left-0 flex z-[10000000000] bg-black/50 cursor-default'>
                <div ref={dropDownRef} className={`bg-white w-[350px] overflow-hidden flex flex-col justify-between py-10 px-5 `}>
                    <div className={`overflow-scroll`}>
                        <div className='flex justify-between items-center'>
                            <Link to="/">
                                <img src={BLACK_LOGO} alt="logo" className='h-[32px] w-[105px] cursor-pointer select-none' />
                            </Link>

                            {active && <div onClick={() => {
                                setIsVisible(false);
                                setActive(false);
                            }} className='flex rounded-lg p-2 select-none cursor-pointer bg-gradient-to-r from-primary1 to-[#5CE0FF] hover:from-primary2 hover:to-[#fffd98] text-center text-white hover:text-black'>
                                <AiOutlineClose />
                            </div>}
                        </div>
                        <div className={`flex flex-col gap-4 overflow-hidden mt-14`}>
                            {tabs?.map((item, index) => {
                                return (
                                    <Link to={item?.path}>
                                        <span key={index} onClick={() => {
                                            setIsVisible(false);
                                        }} className={`truncate text-[#18191F99] cursor-pointer select-none ${pathname === item.path ? '!text-[#3698bf] bg-[#3698bf]/20 rounded-lg text-center' : 'hover:text-[#f0a718]'} py-2 px-3 duration-1000`}>{item?.title}</span>
                                    </Link>
                                )
                            })}
                        </div>
                    </div>
                    <Link to="/get-a-quote" className={` text-xs lg:text-sm select-none rounded-lg py-2 px-3 truncate cursor-pointer bg-gradient-to-r from-primary1 to-[#5CE0FF] hover:from-primary2 hover:to-[#fffd98] text-center text-white hover:text-black`}>GET A QUOTE</Link>
                </div>
            </div>}
        </>
    );
};

export default Header;
