import { ASAD, ATIKUR, NAYEEM, NITISH, PAVEL, SAMIA, ABRAR, MORSHED, PERSON_1, PERSON_2, PERSON_3, PERSON_4, PERSON_5, RANA, RASEL, REZAUL, SAIMA, SAKIB, SAMI, SHUVO, SINTHIYA, SUSMOY, TURZO, WALIUR } from "../images/imageData";

export const teamMembersInfo = [
    {
        name: "Sharif Mohammad Pavel",
        designation: "Founder & CEO",
        description: "As the CEO and founder of WeTech, Sharif Mohammad Pavel is the visionary leader behind our success. He has a keen ability to identify opportunities, overcome challenges, and steer our company toward sustainable growth.",
        image: PAVEL,
    },
    {
        name: "Samia Sultana",
        designation: "CEO (United Kingdom)",
        description: "As the CEO of WeTech(UK), Samia Sultana . Her strategic leadership has revolutionized digital transformation, driving industry-wide change and grow our market in UK.",
        image: SAMIA,
    },
    {
        name: "Abrar Hasnath",
        designation: "Director",
        description: "As the director of WeTech, With a focus on cutting-edge technology and strategic leadership, he has successfully led teams to create impactful software solutions that shape the future of the industry.",
        image: ABRAR,
    },
    {
        name: "Md Sami",
        designation: "Tech Lead",
        description: "Md Sami is the man behind our technical excellence and innovation as the Tech Lead. With his passion for technology and relentless commitment, he takes our future to delivering cutting-edge solutions.",
        image: SAMI,
    },
    {
        name: "Waliur Rahman Sabit",
        designation: "Senior Software Engineer",
        description: "Waliur Rahman Sabit is our Senior Software Engineer whose passion for coding knows no bounds. With an ability to turn complex problems into elegant solutions, he brings a wealth of experience to our dynamic team.",
        image: WALIUR,
    },
    {
        name: "Md Sakib Ahmed",
        designation: "Senior Software Engineer",
        description: "As a Senior Software Engineer (Back-End), Md Sakib Ahmed leads with precision, crafting robust solutions and optimizing performance. His expertise enhances our technology backbone, driving innovation and efficiency.",
        image: SAKIB,
    },
    {
        name: "Nitish Biswas",
        designation: "Junior Software Engineer",
        description: "Nitish Biswas is our dynamic and resourceful Junior Software Engineer at the forefront of innovation. With a keen eye for detail and a passion for problem-solving, Nitish brings a fresh perspective to our development team.",
        image: NITISH,
    },
    {
        name: "Md Morshed Hossain",
        designation: "Software Engineer",
        description: "Md Morshed Hossain is our skilled and versatile Software Engineer, leading the way in innovation. With sharp attention to detail and a strong passion for problem-solving, Morshed adds a unique and creative approach to our development team.",
        image: MORSHED,
    },
    {
        name: "Md. Atikur Rahman",
        designation: "WordPress Developer",
        description: "As our WordPress Developer, Md. Atikur Rahman has the capability for seamless development and transforming ideas into dynamic websites. With expertise in plugins, themes, and code, he delivers solutions that inspire us. ",
        image: ATIKUR,
    },
    {
        name: "Saima Hossain",
        designation: "HR Executive",
        description: "Saima Hossain is our dedicated HR Executive who plays the role as heart of our organizational culture. With a passion for people and a keen eye for talent, she ensures that our workplace is not just a space for tasks but a community that thrives on collaboration and growth.",
        image: SAIMA,
    },
    {
        name: "Susmoy Das",
        designation: "Junior UI/UX Designer",
        description: "Susmoy Das is our talented Junior UI/UX Designer, who is passionate about weaving magic with pixels and creating seamless user experiences. Dive into the world of design excellence curated by Susmoy for a visual journey like never before.",
        image: SUSMOY,
    },
    {
        name: "Jewel Rana",
        designation: "Motion Graphics Designer",
        description: "As a Motion Graphics Designer, Jewel Rana doesn't just animate; he breathes life into ideas, turning them into dynamic visual stories for customers.",
        image: RANA,
    },
    {
        name: "Asadullah Asad",
        designation: "Creative Graphics Designer",
        description: "Asadullah Asad plays the role of the artistic force behind our visual storytelling. As our Creative Graphics Designer, he brings a unique blend of creativity and precision to every project.",
        image: ASAD,
    },
    {
        name: "Md Rezaul Hasan",
        designation: "Digital Marketing (Sales)",
        description: "Md Rezaul Hasan has 10 years of corporate experience. As a digital marketing specialist, his strategies, and fresh ideas will bring WeTech to the next level.",
        image: REZAUL,
    },
    {
        name: "Mahabuba Sultana",
        designation: "Digital Marketing (Intern)",
        description: "Mahabuba is a rising star in the world of digital marketing. Her passion for the digital landscape and dedication to discovering marketing trends make her an invaluable asset to our company.",
        image: SINTHIYA,
    },
    {
        name: "Tanvir Zaman Turzo",
        designation: "Digital Marketing (Intern)",
        description: "Tanvir Zaman Turzo is our enthusiastic and creative Digital Marketing Intern. With a passion for digital marketing and innovation, Turzo is an integral part of our marketing team.",
        image: TURZO,
    },
    {
        name: "Ariful Hasan Shuvo",
        designation: "Content Writer",
        description: "Ariful Hasan Shuvo is an extraordinaire content writer in our company. With a keen passion for writing, Shuvo plays a pivotal role in transforming our brand's ideas into impactful narratives.",
        image: SHUVO,
    },
    {
        name: "Rasel Uddin",
        designation: "Content Writer Intern",
        description: "Rasel Uddin is our talented and creative content writer who brings words to life with his exceptional writing skills.",
        image: RASEL,
    },
    {
        name: "Nayeem",
        designation: "Happiness Member",
        description: "As our beloved Happiness Member, Nayeem is the heartbeat of our workplace. With his commitment and friendly appearance, he ensures a positive and efficient work environment for all.",
        image: NAYEEM,
    },
];

export const heroPerson = [PERSON_1, PERSON_2, PERSON_3, PERSON_4, PERSON_5];