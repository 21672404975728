import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { BsArrowRight } from 'react-icons/bs';
import { IService, serviceData } from '../../assets/data/serviceData';
import { SERVICE_TOP_BG } from '../../assets/images/imageData';
import NotFound from '../NotFound/NotFound';
import SECTION_BG from '../../assets/images/extra/section-bg.svg';

const ServiceSectionScreen = () => {
    const params = useParams();
    const [serviceSection, setServiceSection] = useState<IService | null>(null);
    const [isHovered, setIsHovered] = useState(false);
    const [serviceId, setServiceId] = useState<null | number>(null);

    const handleMouseEnter = (id: number) => {
        setIsHovered(true);
        setServiceId(id);
    };

    const handleMouseLeave = (id: number) => {
        setIsHovered(false);
        setServiceId(id);
    };

    useEffect(() => {
        if (params?.path) {
            const serviceSection = serviceData?.filter(service => service?.url === params?.path);
            if (serviceSection?.length > 0) {
                setServiceSection(serviceSection[0]);
            } else {
                setServiceSection(null);
            }
        }
    }, [params?.path]);

    if (serviceSection === null) {
        return <NotFound />
    }

    return (
        <div className='w-full'>
            <div className='w-full 2xl:px-[240px] xl:px-[40px] px-[10px] my-10'>
                <div className='flex flex-col justify-center items-center'>
                    <div className='text-center text-h2 font-semibold mb-5'>{serviceSection?.title}</div>
                    <div className='text-center w-full lg:w-[60%]'>{serviceSection?.description}</div>
                </div>
            </div>
            <div className="w-full 2xl:px-[240px] xl:px-[40px] px-[10px] py-[80px] relative overflow-hidden my-10" style={{
                backgroundImage: `url(${SECTION_BG})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
            }}>
                <div className='absolute bg-white/20 h-60 w-60 rounded-full bottom-[-65px] right-[-45px]' />
                <div className='absolute bg-gradient-to-r from-white/30 transition-all animate-spin h-32 w-32 rounded-full bottom-20 right-20' />
                <div className="absolute top-0 right-0">
                    <img src={SERVICE_TOP_BG} alt="servicesTopRightBG" className="w-full h-full" />
                </div>
                <div>
                    <div className="text-white text-center text-h3 md:text-h2 lg:text-h1 mb-[50px]">Services that Connect You to Your Users</div>
                    <div className={`w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 sm:gap-10 md:gap-12 lg:gap-14 xl:gap-16`}>
                        {serviceSection?.section?.map((service, index) => {
                            return (
                                <Link
                                    key={index}
                                    to={`/service/${params?.path}/${service?.url}`}
                                    onMouseEnter={() => handleMouseEnter(index)}
                                    onMouseLeave={() => handleMouseLeave(index)}
                                    className="flex flex-col justify-between gap-10 p-[32px] rounded-lg relative overflow-hidden z-10 group backdrop-blur-sm bg-white/20 text-white cursor-pointer hover:bg-white hover:text-black duration-500"
                                >
                                    <div className="h-[60px] w-[60px]">
                                        <ReactSVG
                                            src={service?.image}
                                            beforeInjection={(svg) => {
                                                const paths = svg.querySelectorAll('path');
                                                paths.forEach((path) => {
                                                    path.setAttribute('fill', isHovered ? index === serviceId ? '#000' : '#fff' : '#fff');
                                                });
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <div className={`text-h3 z-10 duration-500 mb-3`}>{service?.title}</div>
                                        {service?.description && <div className="text-[#f5f5f5] group-hover:text-[#242424] mb-6 z-10 duration-500">
                                            {service?.description}
                                        </div>}
                                    </div>
                                    <Link to={`/service/${params?.path}/${service?.url}`} className="flex gap-5 items-center font-medium cursor-pointer group-hover:text-primary1 hover:underline duration-500">
                                        Explore <BsArrowRight />
                                    </Link>
                                </Link>
                            );
                        })}
                    </div>
                </div>
            </div>
            <div className='w-full 2xl:px-[240px] xl:px-[40px] px-[10px] py-[80px]'>
                <img src={serviceSection?.image} alt={serviceSection?.title} className='w-full' />
            </div>
        </div>
    )
}

export default ServiceSectionScreen