import React from 'react'
import { clientList } from '../assets/data/clientData';

const ClientSection = () => {
    const renderClients = (start: number, end: number) => {
        return clientList?.slice(start, end)?.map((client, index) => (
            <div key={index} className='flex items-center justify-center flex-wrap gap-10'>
                <img src={client} alt={`client ${index}`} className='grayscale hover:grayscale-0' />
            </div>
        ));
    };

    return (
        <div className='w-full 2xl:px-[240px] xl:px-[40px] px-[10px]'>
            <div className='text-center text-h2 font-semibold mb-5'>Our Clients</div>
            <div className='text-center'>We have been working with some Fortune 329+ clients</div>
            <div className='mt-20 flex flex-col gap-10 h-[400px] overflow-hidden'>
                <div className='scroll-infinite flex flex-col gap-10'>
                    <div className='flex flex-wrap justify-around gap-10'>
                        {renderClients(0, 3)}
                    </div>
                    <div className='flex flex-wrap justify-around gap-10'>
                        {renderClients(3, 5)}
                    </div>
                    <div className='flex flex-wrap justify-around gap-10'>
                        {renderClients(5, 8)}
                    </div>
                    <div className='flex flex-wrap justify-around gap-10'>
                        {renderClients(8, 10)}
                    </div>
                    <div className='flex flex-wrap justify-around gap-10'>
                        {renderClients(10, 13)}
                    </div>
                    <div className='flex flex-wrap justify-around gap-10'>
                        {renderClients(13, 15)}
                    </div>
                    <div className='flex flex-wrap justify-around gap-10'>
                        {renderClients(15, 18)}
                    </div>
                    <div className='flex flex-wrap justify-around gap-10'>
                        {renderClients(18, 20)}
                    </div>
                    <div className='flex flex-wrap justify-around gap-10'>
                        {renderClients(20, 23)}
                    </div>
                    <div className='flex flex-wrap justify-around gap-10'>
                        {renderClients(23, 25)}
                    </div>
                    <div className='flex flex-wrap justify-around gap-10'>
                        {renderClients(25, 28)}
                    </div>
                    <div className='flex flex-wrap justify-around gap-10'>
                        {renderClients(28, 30)}
                    </div>
                    <div className='flex flex-wrap justify-around gap-10'>
                        {renderClients(30, 33)}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ClientSection