import React, { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { IServiceDetails, growthProcessKeys, serviceDetailsData } from '../../assets/data/serviceData';
import NotFound from '../NotFound/NotFound';
import ClientSection from '../../sections/ClientSection';

const ServiceSectionDetailsSecrren = () => {
    const params = useParams();
    const [serviceDetails, setServiceDetails] = useState<IServiceDetails | null>(null);
    const location = useLocation();
    const path1 = location?.pathname?.split('/')[2];
    const path2 = location?.pathname?.split('/')[3];

    useEffect(() => {
        if (params?.path) {
            const newServiceDetails = serviceDetailsData?.filter(serviceDetails => serviceDetails?.url === params.path);
            if (newServiceDetails?.length > 0) {
                setServiceDetails(newServiceDetails[0]);
            } else {
                setServiceDetails(null);
            }
        }
    }, [params?.path]);

    if (serviceDetails === null) {
        return <NotFound />
    }

    return (
        <div className='w-full'>
            <div className='w-full 2xl:px-[240px] xl:px-[40px] px-[10px]'>
                <div className='flex flex-col justify-center items-center my-20'>
                    <div className='text-center text-h2 font-semibold mb-5'>{serviceDetails?.title}</div>
                    <div className='text-center w-full lg:w-[60%]'>{serviceDetails?.description}</div>
                </div>
                <div className='flex flex-col gap-14 my-10'>
                    {serviceDetails?.contents?.map((service, index) => {
                        console.log(`/service/${path1}/${path2}/${service?.url}/details`)
                        return (
                            <div key={index} className={`w-full flex flex-col ${index % 2 === 0 ? "lg:flex-row" : "lg:flex-row-reverse"} gap-10`}>
                                <div className='relative w-full h-[380px] lg:h-[500px] rounded-2xl overflow-hidden bg-red-200'>
                                    <img src={service?.image} alt={service?.title} className='w-full h-full object-cover' />
                                    {/* <ReactSVG src="../../assets/images/branding/1.svg" /> */}
                                    <div className='absolute p-5 bottom-0 left-0 right-0 rounded-xl text-white bg-gradient-to-t from-black'>
                                        <div className='text-center text-h4 font-semibold mb-3'>{service?.title}</div>
                                        <div className='text-center'>{service?.description}</div>
                                    </div>
                                    <Link to={`/service/${path1}/${path2}/${service?.url}/details`} className="absolute top-5 right-5 select-none rounded-xl py-2 px-5 cursor-pointer bg-gradient-to-r from-primary1 to-[#5CE0FF] hover:from-primary2 hover:to-[#fffd98] text-center text-white hover:text-black">Explore</Link>
                                </div>
                                <div className='w-full grid grid-cols-1 gap-5 h-auto lg:h-[500px]'>
                                    {service?.data?.map((content, ind) => {
                                        return (
                                            <div key={ind} className='overflow-auto no-scrollbar text-white bg-black/50 backdrop-blur-sm p-5 rounded-xl lg:h-auto h-[250px]'>
                                                <div className='text-h4 font-semibold mb-2'>{content?.title}</div>
                                                <div className='text-justify'>{content?.description}</div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className='mt-20 flex flex-col lg:flex-row gap-10 bg-white 2xl:px-[240px] xl:px-[40px] px-[10px] py-20'>
                <div className='text-center text-h2 font-semibold mb-5 flex items-center justify-center'>Our Growth Process</div>
                <div className=' w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-5'>
                    {growthProcessKeys?.map((growthProcessKey, index) => {
                        return (
                            <div key={index} className='flex gap-5 items-center text-h4 font-semibold w-full'>
                                <ReactSVG
                                    src={growthProcessKey?.icon}
                                    beforeInjection={(svg) => {
                                        const paths = svg.querySelectorAll('path');
                                        paths.forEach((path) => {
                                            path.setAttribute('fill', '#000');
                                        });
                                    }}
                                />
                                {growthProcessKey?.title}
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className='my-10'>
                <ClientSection />
            </div>
        </div>
    )
}

export default ServiceSectionDetailsSecrren